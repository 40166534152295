import PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {cityModalToggle} from "../../actions/ModalActions";

class Events extends Component {
    static propTypes = {
        restaurants: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool.isRequired
    };

    render() {
        const { restaurants, isLoaded, cityModalToggle } = this.props;
        return (
            <Fragment>
                {/*<Helmet>*/}
                    {/*<title>У нас 1000 подписчиков! Спасибо вам!</title>*/}
                {/*</Helmet>*/}
                {/*<MainInfo/>*/}
            </Fragment>
        );
    }
}

const mapStateToProps = store => ({
    restaurants: store.main.restaurants,
    isLoaded: store.main.isLoaded
});

const mapDispatchToProps = dispatch => ({
    cityModalToggle: () => dispatch(cityModalToggle())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Events);
