import PropTypes from "prop-types";
import React, {Component, Fragment, Suspense} from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import styled from "styled-components";
import {getRestaurants} from "../../actions/MainActions";
import Footer from "../../components/Footer";
import NotFound from "../../components/NotFound";
import Pay from "../../components/Pay";
import ScrollReset from "../../components/ScrollReset";
import HeaderMini from "../HeaderMini";
import Header from "../Header";
import Event from "../Events";
import SentencePopup from "../Popup";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import EditModalConfig from "./../../components/Modal/EditModalConfig"
import * as _ from "underscore";
import {
	activateEmailAccount,
	handleReduxFormProfileInfo,
	handleReduxFormReg,
	parseParametersGet,
	saveClientProfile
} from "../../actions/ProfileActions";
import {infoViewToggle} from "../../actions/ModalActions";
import {BOLD_FONT, MAIN_COLOR} from "../../utils/constants";
import {GetAppSettings} from "../../actions/MenuActions";
import Preloader from "../../components/Preloader";
import Fallback from "../../components/Fallback";
import * as moment from "moment";
import haveOrderInfo from "../../images/have-order-info.svg";
import Index from "../../components/MyOrders";
import {ClockLoader} from "react-spinners";
import Landing from "../../promo/Landing";

const Menu = React.lazy(() => import('./../Menu'));
const Promo = React.lazy(() => import('../../components/Promo'));
const Delivery = React.lazy(() => import('../../components/Delivery'));
const About = React.lazy(() => import('../../components/About'));
const Manager = React.lazy(() => import('./../Manager'));
const Personal = React.lazy(() => import('./../Personal'));
const MyOrders = React.lazy(() => import('../../components/MyOrders'));

class App extends Component {
	ForPromo = !window.location.href.includes('/app') && !window.location.href.includes('/promo10') && !window.location.href.includes('/promo11') && !window.location.href.includes('/paid-order') && !window.location.href.includes('/start_saransk')
	static propTypes = {
		getRestaurants: PropTypes.func.isRequired
	};

	state = {
		openWarningCookie: this.getCookie("warningCookie") ? false : true,
		isCartOpen: false,
		periods: [],
		activePeriod: '1',
	};

	componentWillMount() {
		this.props.parseParametersGet(this.props.location.search);
		this.props.GetAppSettings();
	}

	componentDidMount() {
		this.getVersion('/version.json', (err, data) => {
			if (err !== null) {
				console.log('Something went wrong: ' + err)
			} else {
				console.log(data.update_date)
				if (data.update_date !== '2024-06-18') {
					document.location.reload()
				}
			}
		})

		setInterval(() => {
			this.getVersion('/version.json', (err, data) => {
				if (err !== null) {
					console.log('Something went wrong: ' + err)
				} else {
					console.log(data.update_date)
					if (data.update_date !== '2024-06-18') {
						document.location.reload()
					}
				}
			})
		}, 3600000)

		this.props.getRestaurants();

		let elem = document.getElementById("header");
		let headerHeight = elem ? elem.getBoundingClientRect().height : 0;
		this.setState({headerHeight})
	}

	getVersion = (url, cb) => {
		let xhr = new XMLHttpRequest()
		xhr.open('GET', url, true)
		xhr.responseType = 'json'
		xhr.onload = () => {
			let status = xhr.status
			if (status === 200) {
				cb(null, xhr.response)
			} else {
				cb(status, xhr.response)
			}
		}
		xhr.send()
	}

	warningCookieOk() {
		document.cookie = "warningCookie = yes";

		this.setState({
			openWarningCookie: false
		})
	};

	getCookie(name) {
		var value = "; " + document.cookie;
		var parts = value.split("; " + name + "=");
		if (parts.length === 2) return parts.pop().split(";").shift();
	}

	setPeriods = (arr) => {
		this.setState({periods: arr})
	}

	setActivePeriod = (periodId) => {
		this.setState({activePeriod: periodId})
	}

	editMargin = () => {
		let elem = document.getElementById("header");
		let headerHeight = elem ? elem.getBoundingClientRect().height : 0;
		this.setState({headerHeight})
	}

	render() {
		var cookieCheck = this.getCookie("settingAttachment");

		const { hash, profile, company, valueReg, handleReduxFormReg, saveClientProfile, params, activateEmailAccount, isAuth } = this.props;
		const shouldShowBasketFooter = this.props.location.pathname !== '/basket';
		const shouldShowEventFooter = this.props.location.pathname === '/event';
		const isIframe = this.props.location.pathname.includes('PayResult')

		let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
		let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

		return (
			<Fragment>
				{this.props.checkLoading || this.props.orderSending ? (
					<Loader>
						<ClockLoader loading/>
					</Loader>
				) : null}
				{hash && (
					<EditModalConfig
						hash={hash.value !== '' ? hash.value : false}
						onChange={handleReduxFormReg}
						saveClientProfile={saveClientProfile}
						profile={profile}
						company={company}
						valueReg={valueReg}
						params={params}
                        activateEmailAccount={activateEmailAccount}
                        history={this.props.history}
					/>
				)}
				<PreviewActionUser id="previewActionUser">
					{_.map(this.props.tempArrayModal, (item)=>{
						return (
							<CSSTransition key={parseInt(item.actionId)} timeout={300} classNames="invite_users" in={true}>
								{item.isHaveOrder ? (
									<YouHaveAnOrder>
										<div style={{width: 40}}>
											<img src={haveOrderInfo} alt="i"/>
										</div>
										<YouHaveAnOrderText>
											<p>У вас уже есть заказ на этот день</p>
											<p>Если вы хотите сделать новый, сначала, удалите предыдущий</p>
										</YouHaveAnOrderText>
									</YouHaveAnOrder>
								) : (
									<WrapperPreview color={item.color}>
										<PreviewTitle messTitle={item.messTitle}>
											{item.messTitle}
										</PreviewTitle>
										<PreviewText>
											{item.messText}
										</PreviewText>
									</WrapperPreview>
								)}
							</CSSTransition>
						)
					})}
				</PreviewActionUser>
				{/*{*/}
				{/*	cookieCheck === "no" || cookieCheck === undefined*/}
				{/*		?*/}
				{/*			/Android/i.test(navigator.userAgent)*/}
				{/*				// ? <SentencePopup electrStore="google-play" href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice"/>*/}
				{/*				? <SentencePopup electrStore="google-play" href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice&utm_source=my_comp&utm_medium=cpa&utm_campaign=promo&utm_content=landing_page&utm_term=just"/>*/}
				{/*					: /webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)*/}
				{/*						? <SentencePopup electrStore="apple" href="https://itunes.apple.com/ru/app/id1361986950?mt=8"/>*/}
				{/*				: false*/}
				{/*		:   false*/}
				{/*}*/}
				{
					
					!isIframe && this.ForPromo && (
						<>
							{ shouldShowBasketFooter && <Header editMargin={this.editMargin} fixed={null} isCartOpen={this.test} periods={this.state.periods} activePeriod={this.state.activePeriod}/> }
							{ !shouldShowBasketFooter && <HeaderMini fixed={null}/> }
						</>
					)
				}

				<Container>
					<Suspense fallback={<Fallback />}>
						<Switch>
							<Route exact path="/" render={()=><Menu headerHeight={this.state.headerHeight} getSettings={this.props.getSettings} setPeriods={this.setPeriods} setActivePeriod={this.setActivePeriod}/>}/>
							{(!is51obed && !isStraus) && (
								<Route path="/promo" component={Promo} />
							)}

							<Route path="/app" render={()=><Landing/>} />
							<Route path="/promo10" render={()=><Landing cuppon='Promo'/>} />
							<Route path="/promo11" render={()=><Landing cuppon='Promo2'/>} />
							<Route path="/paid-order" render={()=><Landing isPaid />} />
							<Route path="/start_saransk" render={()=><Landing start='saransk'/>} />

							<Route path="/delivery" render={()=><Delivery headerHeight={this.state.headerHeight}/>} />
							<Route path="/pay" render={()=><Pay headerHeight={this.state.headerHeight}/>} />
							<Route path="/about" render={()=><About headerHeight={this.state.headerHeight}/>} />
							{(!is51obed && !isStraus) && (
								<Route path="/manager" render={()=><Manager headerHeight={this.state.headerHeight}/>} />
							)}
							<Route path="/event" component={Event} />
							<Route path="/personal" render={() => {
								if (isAuth) {
									return <Personal headerHeight={this.state.headerHeight}/>
								} else {
									return <Menu headerHeight={this.state.headerHeight} getSettings={this.props.getSettings} setPeriods={this.setPeriods} setActivePeriod={this.setActivePeriod}/>
								}
							}}/>
							<Route path="/my-orders" component={
								() => isAuth
									?  <MyOrders />
									: <Redirect to="/"/>
							}/>
							<Route component={NotFound} />
						</Switch>
					</Suspense>
				</Container>
				{/* { window.location.pathname !== '/app'  && */}
				{this.ForPromo &&
					<CSSTransition timeout={500} classNames='warningCookie' in={this.state.openWarningCookie} unmountOnExit>
						{document.documentElement.clientWidth <= 992 ? (
							<CookieWarning>
								<Text>
									Мы используем куки во всех своих проектах
								</Text>
								<ButtonOk onClick={()=>{this.warningCookieOk()}}>
									Хорошо 👌
								</ButtonOk>
							</CookieWarning>
						) : (
							<CookieWarning>
								<Text>
									Мы используем куки на всех своих сайтах, включая этот, потому что без кук вообще весь интернет работал бы плохо
								</Text>
								<ButtonOk onClick={()=>{this.warningCookieOk()}}>
									Понимаю
								</ButtonOk>
								<ButtonOkMinimal onClick={()=>{this.warningCookieOk()}}>
									<i className="fas fa-times"></i>
								</ButtonOkMinimal>
							</CookieWarning>
						)}
					</CSSTransition>
				}
				{/* } */}
				{(shouldShowBasketFooter && !shouldShowEventFooter && !isIframe && this.ForPromo) && <Footer/>}
				<ScrollReset />
			</Fragment>
		);
	}
}

const mapStateToProps = store => ({
	getSettings: store.menu.getSettings,
    tempArrayModal: store.modal.temporaryStorageFacility,
	profile: store.profile.profile,
    isAuth: store.profile.isAuth,
	companies: store.profile.companies,
    valueReg: store.profile.valueReg,
	initialValues: store.profile.initialValues,
	hash: store.profile.hash,
	params: store.profile.paramsGetRequest,
	viewInfoPerformance: store.modal.viewInfoPerformance,
	checkLoading: store.menu.checkLoading,
	orderSending: store.menu.orderSending,
});

const mapDispatchToProps = dispatch => ({
	getRestaurants: () => dispatch(getRestaurants()),
	GetAppSettings: () => dispatch(GetAppSettings()),
	saveClientProfile: (profile) => dispatch(saveClientProfile(profile)),
	handleReduxFormProfileInfo: (values) => dispatch(handleReduxFormProfileInfo(values)),
	handleReduxFormReg: (values) => dispatch(handleReduxFormReg(values)),
	parseParametersGet: (params) => dispatch(parseParametersGet(params)),
    activateEmailAccount: (valueData) => dispatch(activateEmailAccount(valueData)),
    infoViewToggle: (bool) => dispatch(infoViewToggle(bool)),
});

export default withRouter(
	connect(
        mapStateToProps,
		mapDispatchToProps
	)(App)
);

const Container = styled.main`
	min-height: 80vh;
	background: #FFFBFB;
`;

const CookieWarning = styled.div`
	width: 100%;
	height: 80px;
	position: fixed;
	bottom: 0;
	z-index: 10001;
	background: ${MAIN_COLOR};
	font-family: 'Arsenal', sans-serif;
	line-height: 23px;
	color: #FFFFFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	@media (max-width: 992px) {
		width: calc(100% - 16px);
		height: 83px;
		padding: 0 20px;
		bottom: 8px;
		left: 8px;
		right: 8px;
		background: #ffffff;
		font-family: 'Roboto', sans-serif;
		font-weight: normal;
		font-size: 13px;
		line-height: 15px;
		color: #222222;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
	}
`;

const Text = styled.div`
	padding: 5px 45px;
	font-size: 20px;
	
	@media(max-width:992px){
		width: 144px;
		max-width: calc(100% - 135px);
    	padding: 0;
		font-size: inherit;
    }
`;

const ButtonOk = styled.div`
	background: #fff;
	opacity: 0.7;
	font-family: 'Arsenal', sans-serif;
	font-size: 20px;
	line-height: 23px;
	color: ${MAIN_COLOR};
	border-radius: 6px;
	padding: 10px 45px;
    margin-right: 45px;
    cursor: pointer;
    
    @media (max-width:992px) {
    	width: 125px;
    	height: 42px;
		background: #6A11CB;
		opacity: 1;
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
		color: #ffffff;
		border-radius: 66px;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
    }
`;

const ButtonOkMinimal = styled.div`
	position: absolute;
    top: 0;
    right: 0;
    padding: 8px 10px 0 0;
    cursor: pointer;
    
	@media(min-width:992px){
		display: none;
    }
`;

const WrapperPreview = styled.div`
  width: 240px;
  height: auto;
  margin-bottom: 5px;
  cursor: pointer;
  color: #fff;
  background: ${({color}) => color ? color : 'rgba(0,0,0,0.74)'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  user-select: none;
  text-align: center;
  padding: 7px;
  
  	@media(max-width: 992px){
		width: 100%;
		min-height: 51px;
		margin-bottom: 13px;
		background: ${({color}) => color ? color : 'rgba(38, 50, 56, 0.96)'};
		align-items: flex-start;
		border-radius: 10px;
		text-align: left;
		padding: 12px 19px;
  	}
  
	&.invite_users-enter {
		opacity: 0;
		transform: translate(0, 75px);
		transition: max-height 200ms, opacity 200ms, transform 200ms;
		max-height: 0;
		
		@media(max-width: 992px){
			transform: translate(0, 51px);
		}
	}
	
	&.invite_users-enter.invite_users-enter-active {
		opacity: 1;
		transform: translateX(0);
		max-height: 75px;
		transition: max-height 200ms, opacity 200ms, transform 200ms;
		
		@media(max-width: 992px){
			max-height: auto;
		}
	}
	
	&.invite_users-exit {
		opacity: 1;
	}
	
	&.invite_users-exit.invite_users-exit-active {
		opacity: 0;
		transform: translate(0, -75px);
		transition: all 200ms linear;
		margin-top: -75px;
		margin-bottom: 0;
		
		@media(max-width: 992px){
			margin-top: -51px;
			transform: translate(0, -51px);
		}
	}
`;

const PreviewActionUser = styled(TransitionGroup)`
	position: fixed;
	bottom: 40px;
	left: 40px;
	display: flex;
	flex-direction: column-reverse;
	z-index: 9999;
	user-select: none;
	
	@media(max-width: 992px){
		top: 13px;
    	left: 19px;
    	right: 19px;
    	bottom: unset;
	}
`;

const PreviewTitle = styled.div`
	font-size: 17px;
	${BOLD_FONT};
	
	@media(max-width: 992px){
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 18px;
		margin-bottom: ${({messTitle}) => messTitle ? '8px' : '0'};
	}
`;

const PreviewText = styled.div`
	font-size: 14px;
	font-family: 'Arsenal', sans-serif;
	
	@media(max-width: 992px){
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 15px;
	}
`;

const YouHaveAnOrder = styled.div`
    display: flex;
    align-items: center;
    width: calc(100vw - 24px);
    margin: 0 -7px 13px;
    padding: 25px;
    background: linear-gradient(179.18deg, #0B2E40 0.75%, #434D57 99.34%), #1E1026;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 15px; 
    color: #FFFFFF;
    
    &.invite_users-enter {
		opacity: 0;
		transform: translate(0, 75px);
		transition: max-height 200ms, opacity 200ms, transform 200ms;
		max-height: 0;
		
		@media(max-width: 992px){
			transform: translate(0, 51px);
		}
	}
	
	&.invite_users-enter.invite_users-enter-active {
		opacity: 1;
		transform: translateX(0);
		max-height: 75px;
		transition: max-height 200ms, opacity 200ms, transform 200ms;
		
		@media(max-width: 992px){
			max-height: auto;
		}
	}
	
	&.invite_users-exit {
		opacity: 1;
	}
	
	&.invite_users-exit.invite_users-exit-active {
		opacity: 0;
		transform: translate(0, -75px);
		transition: all 200ms linear;
		margin-top: -75px;
		margin-bottom: 0;
		
		@media(max-width: 992px){
			margin-top: -51px;
			transform: translate(0, -51px);
		}
	}
`;

const YouHaveAnOrderText = styled.div`
    width: calc(100% - 59px);
    margin-left: 19px;
    
    & p {
        margin: 0;
        
        &:first-child {
            margin-bottom: 5px;
            font-weight: bold;
        }
    }
`;

const Loader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
`;
