import React, {useEffect, useState} from 'react';
import PlacesAutocomplete, {getLatLng} from 'react-places-autocomplete';
import { nanoid } from 'nanoid'
import {
  geocodeByAddress,
} from 'react-places-autocomplete';

const s = {
  autocompleteWrapper: {
    position: 'relative'
  },
  autocomplete: {
    width: '100%',
    background: 'rgb(255,255,255)',
    height: 50,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: '1px solid silver',
    padding: 10,
    marginBottom: 10,
    outline: 'none',
  },
  autocompleteBox: {
    boxSizing: 'border-box',
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    padding: 10,
    background: '#ffffff',
    top: 55,
    left: 0,
    borderRadius: 8
  },
  elemWrapper: {
    padding: 5,
  },
  elemDefault: {
    cursor: 'pointer',
    padding: 10,
  }
}
const Autocomplete = ({
                        autocompleteRef,
                        defaultCoords,
                        setSelectedAddressData,
                        addressString,
                        setAddressString,
                        showAutocompleteBox,
                        setShowAutocompleteBox,
                        setCoords
}) => {
  const [address, setAddress] = useState('')

  useEffect(() => {
    if(addressString) {
      setAddress(addressString)
    }
  }, [addressString])

  useEffect(() => {
    setAddressString('')
    if(address) {
      setShowAutocompleteBox(true)
    }
  }, [address])

  const grabResult = (res) => {
    setSelectedAddressData(res[0])
    return res
  }

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(result => grabResult(result))
      .then(result => getLatLng(result[0]))
      .then(result => setCoords(result))
      .catch(error => console.error('Error', error));
  };

  const searchOptions = {
    location: new window.google.maps.LatLng(defaultCoords),
    radius: 500,
    types: ['address']
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
      debounce={10}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={s.autocompleteWrapper}>
          <input {...getInputProps({
            placeholder: 'Адрес ...',
            className: 'location-search-input',
          })}
          ref={autocompleteRef}
          style={s.autocomplete}
          />
          {
            showAutocompleteBox && !!suggestions.length && (
              <div style={s.autocompleteBox} className="autocomplete-dropdown-container">
                {
                  suggestions.map(suggestion => {
                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                    const style = suggestion.active ? { backgroundColor: '#c7c7c7', ...s.elemDefault } : s.elemDefault;
                    const key = nanoid()
                    const element = getSuggestionItemProps(suggestion, {className, style, key})
                    return (
                      <div {...element}>
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default Autocomplete;
