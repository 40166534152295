import React, {Fragment, PureComponent} from "react";
import InputMask from "react-input-mask";
import Modal from "react-modal";
import styled, {css} from "styled-components";
import profileIcon from "../../../images/profile.svg";
import {Field, reduxForm} from 'redux-form'
import {CSSTransition} from "react-transition-group";
import {MAIN_COLOR, TXT_COLOR} from "../../../utils/constants";
import AcceptImg from '../../../images/accept.svg';

Modal.setAppElement("#root");

class AuthModal extends PureComponent {
    state = {
        openModal: false,
        phone: '',
        code: '',
        time: 299,
        codeSentAnalog: false,
        toggleTransform: 1,
        stateAuthModal: 'auth',
        phoneEmail: 'phone',
        termsOfUse: false,
    };

    authModalToggle = () => {
        this.setState({
            openModal: !this.state.openModal
        });
    };

    toggleAuthReg = () => {
        if (this.state.stateAuthModal === 'reg') {
            this.setState({
                toggleTransform: 0,
                stateAuthModal: 'auth'
            });

            setTimeout(()=>{
                this.setState({
                    toggleTransform: 1,
                });
            }, 500)
        } else {
            this.setState({
                toggleTransform: 0,
                stateAuthModal: 'reg'
            });

            setTimeout(()=>{
                this.setState({
                    toggleTransform: 1,
                });
            }, 500)
        }
    };

    togglePhoneEmail = (val) => {
        this.setState({
            phoneEmail: val
        });
    };

    onChangeInput = (e) => {
        if(e.target.name === 'phone'){
            this.setState({
                phone: e.target.value
            });
        } else {
            this.setState({
                code: e.target.value
            }, ()=>{
                let reg = new RegExp('^\\d+$');
                reg.test(this.state.code) && this.createTokenBySession(this.state.code);
            });
        }
    };

    sendCodeToPhone = () => {
        this.props.sendCodeToPhone(this.state.phone);

        if(this.props.errorMessage === null || !this.props.errorMessage){
            this.setState({
                codeSentAnalog: true
            }, ()=>{
                if(this.state.time > 0 && this.state.codeSentAnalog) {
                    var interval = setInterval(()=>{
                        this.setState({
                            time: this.state.time - 1
                        }, ()=>{
                            if (this.state.time === 0) {
                                this.setState({
                                    codeSentAnalog: false,
                                    time: 299
                                });

                                clearInterval(interval);
                            }
                        })
                    }, 1000)
                }
            });
        }
    };

    sendActivationMail = () => {
        this.props.sendActivationMail();
        this.authModalToggle();
        // this.props.closeModalModifier();
    };

    createTokenBySession = () => {
        this.props.createTokenBySession(this.state.code);

        if (this.props.isAuth) {
            this.authModalToggle();
            // this.props.closeModalModifier();
        }
    };

    closeAllModal = () => {
        this.authModalToggle();
        this.setState({phone: ''})
        this.setState({codeSentAnalog: false})
    };

    handleKeyPress = (event) => {
        if (event.key === "Enter") {
            return this.sendCodeToPhone();
        }
    };

    handlePressAuthEmail = (event) => {
        if (event.key === "Enter") {
            return this.props.createTokenByPassword();
        }
    };

    handleKeyPressCode = (event) => {
        if (event.key === "Enter") {
            return this.createTokenBySession();
        }
    };

    render() {
        const {
            codeSent,
            phoneError,
            errorMessage,
            buttonFront,
            fromNav,
        } = this.props;

        const {
            openModal,
            phone,
            code,
            codeSentAnalog,
            stateAuthModal,
            toggleTransform,
            phoneEmail,
            time
        } = this.state;

        return (
            <Fragment>
                {!buttonFront ? (
                    <Fragment>
                        <AuthToggleText onClick={this.authModalToggle}>Войти</AuthToggleText>
                        {/*<AuthToggleIcon onClick={this.authModalToggle} />*/}
                        {fromNav ? (
                            <AuthButtonText
                                fromNav={true}
                                onClick={() => {
                                    this.props.closeNav();
                                    setTimeout(() => {
                                        this.authModalToggle()
                                    }, 500)
                                }}
                            >
                                Авторизоваться
                            </AuthButtonText>
                        ) : (
                            <AuthToggleButton onClick={this.authModalToggle} openSelect={this.state.openModal}>
                                <PanelActiveCompany>
                                    <AuthButtonText>Авторизоваться</AuthButtonText>
                                </PanelActiveCompany>
                            </AuthToggleButton>
                        )}
                    </Fragment>
                ) : (
                    <div onClick={this.authModalToggle}>{buttonFront}</div>
                )}
                <Modal
                    closeTimeoutMS={100}
                    isOpen={openModal}
                    style={customStyles}
                    overlayClassName={"Modifiers__Overlay"}
                    onRequestClose={this.closeAllModal}>
                    <ModalWrapper>
                        <CSSTransition in={stateAuthModal === 'auth'} timeout={300} classNames="left">
                            {()=><LeftBlockWrapper>
                                <CSSTransition timeout={300} in={stateAuthModal === 'auth'} classNames="auth">
                                    {()=><LeftBlock action={stateAuthModal}>
                                        <AuthTitle>Вход на сайт</AuthTitle>
                                        {/*<AuthVariantBlock>*/}
                                        {/*    <Variant active={phoneEmail} onClick={()=>{this.togglePhoneEmail('phone')}}>По номеру телефона</Variant>*/}
                                        {/*    <Variant active={phoneEmail} onClick={()=>{this.togglePhoneEmail('mail')}}>По e-mail</Variant>*/}
                                        {/*</AuthVariantBlock>*/}
                                        <AuthForm>
                                            <AuthPhone active={phoneEmail}>
                                                <AuthRow>
                                                    <StringAuth disable={codeSentAnalog}>
                                                        <LabelAuth>Номер телефона</LabelAuth>
                                                        <AuthInput
                                                            placeholder="Номер телефона"
                                                            mask="+7\ (999) 999-9999"
                                                            onKeyPress={this.handleKeyPress}
                                                            onChange={this.onChangeInput.bind(this)}
                                                            name="phone"
                                                            value={phone}
                                                            disabled={codeSentAnalog}
                                                            type="tel"
                                                        />
                                                    </StringAuth>
                                                    <StringAuth disable={codeSentAnalog}>
                                                        <LabelAuth>Код из смс</LabelAuth>
                                                        <AuthInput
                                                            placeholder="Код из смс"
                                                            mask="9999"
                                                            onKeyPress={this.handleKeyPressCode}
                                                            onChange={this.onChangeInput.bind(this)}
                                                            disabled={!codeSentAnalog}
                                                            name="code"
                                                            value={code}
                                                            ref={input => input && input.getInputDOMNode().focus()}
                                                            type="tel"
                                                        />
                                                        {time > 0 && codeSentAnalog && (
                                                            <MessageTimer>Отправить ещё раз <br/> через {time} сек.</MessageTimer>
                                                        )}
                                                    </StringAuth>
                                                    {!codeSentAnalog && (
                                                        <>
                                                            <TermsOfUse>
                                                                <TermsOfUseCheckBox
                                                                    active={this.state.termsOfUse}
                                                                    onClick={() => this.setState({
                                                                        termsOfUse: !this.state.termsOfUse
                                                                    })}
                                                                >
                                                                    {this.state.termsOfUse && (
                                                                        <img src={AcceptImg} alt="🗸" width={14} height={11} />
                                                                    )}
                                                                </TermsOfUseCheckBox>
                                                                <TermsOfUseText>
                                                                    Согласен с обработкой персональных данных и подтверждаю,
                                                                    что ознакомлен с&nbsp;
                                                                    <a
                                                                        target="_blank"
                                                                        href="https://api.menuforme.online/files/146923/static/userpolicy.html?time=164396005052412"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <span style={{
                                                                            color: '#54933F',
                                                                            textDecoration: 'underline',
                                                                            cursor: 'pointer',
                                                                        }}>
                                                                            “Пользовательским соглашением”
                                                                        </span>
                                                                    </a>
                                                                </TermsOfUseText>
                                                            </TermsOfUse>
                                                            <AuthButton enabled={this.state.termsOfUse} onClick={() => {
                                                                if (this.state.termsOfUse) {
                                                                    this.sendCodeToPhone()
                                                                }
                                                            }}>
                                                                Получить код
                                                            </AuthButton>
                                                        </>
                                                    )}
                                                </AuthRow>
                                            </AuthPhone>
                                            <AuthEmail active={phoneEmail}>
                                                <AuthRowEmail onSubmit={this.props.handleReduxFormAuth}>
                                                    <StringAuthEmail>
                                                        <LabelAuth>Электронная почта</LabelAuth>
                                                        <AuthInputEmail
                                                            name="email"
                                                            component="input"
                                                            type="email"
                                                            placeholder="mail@mail.com"
                                                        />
                                                    </StringAuthEmail>
                                                    <StringAuthEmail>
                                                        <LabelAuth>Пароль</LabelAuth>
                                                        <AuthInputEmail
                                                            name="pass"
                                                            component="input"
                                                            type="password"
                                                            onKeyPress={this.handlePressAuthEmail}
                                                        />
                                                    </StringAuthEmail>
                                                    <AuthButton onClick={()=>{this.props.createTokenByPassword()}}>
                                                        Войти
                                                    </AuthButton>
                                                </AuthRowEmail>
                                            </AuthEmail>
                                        </AuthForm>
                                        <MessError error={errorMessage !== null ? 1 : 0}>
                                            Произошла ошибка: {errorMessage}
                                        </MessError>
                                    </LeftBlock>}
                                </CSSTransition>
                                <CSSTransition timeout={500} in={stateAuthModal === 'reg'} classNames="reg">
                                    {()=><LeftBlockRegistration action={stateAuthModal}>
                                        <AuthTitle>Регистрация по e-mail</AuthTitle>
                                        <AuthForm>
                                            <RegEmail>
                                                <AuthRowEmail onSubmit={this.props.handleReduxFormAuth}>
                                                    <StringAuthEmail>
                                                        <LabelAuth>Электронная почта</LabelAuth>
                                                        <AuthInputEmail
                                                            name="email_reg"
                                                            component="input"
                                                            type="email"
                                                            placeholder="mail@mail.com"
                                                        />
                                                    </StringAuthEmail>
                                                    <RegButton onClick={()=>{this.sendActivationMail()}}>
                                                        Отправить
                                                    </RegButton>
                                                </AuthRowEmail>
                                            </RegEmail>
                                        </AuthForm>
                                    </LeftBlockRegistration>}
                                </CSSTransition>
                            </LeftBlockWrapper>}
                        </CSSTransition>
                        {/*<CSSTransition in={stateAuthModal !== 'auth'} timeout={300} classNames="right" >*/}
                        {/*    {()=><RightBlock>*/}
                        {/*        <ImageFone src={caleydoscop} toggle={toggleTransform}/>*/}
                        {/*        <ButtonToggle onClick={()=>{this.toggleAuthReg()}}>*/}
                        {/*            <TextSpan toggle={toggleTransform}>*/}
                        {/*                {this.state.stateAuthModal === "auth" ? 'Регистрация по e-mail' : 'Вход на сайт'}*/}
                        {/*            </TextSpan>*/}
                        {/*        </ButtonToggle>*/}
                        {/*    </RightBlock>}*/}
                        {/*</CSSTransition>*/}
                    </ModalWrapper>
                </Modal>
            </Fragment>
        );
    }
}

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "15px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "550px",    // 1200px
    }
};

export default reduxForm({
    form: 'simple'
})(AuthModal)

const ModalWrapper = styled.div`
	background: #fff;
	border-radius: 16px;
	display: flex;
	height: 348px;
	overflow: hidden;
	
	@media (max-width: 992px) {
	    /*height: ${({codeSent}) => codeSent ? '400px' : '364px'};*/
	    height: 380px;
	}
`;

const AuthForm = styled.div`
    width: 100%;
    //height: 78%;
    position: relative;
    //overflow: hidden;
    padding-top: 25px;
`;

const AuthPhone = styled.div`
    position: absolute;
    top: ${({active}) => active === 'phone' ? '0' : '150px'};
    left: 0;
    width: 100%;
    transition: opacity .2s linear, top .4s linear;
    opacity: ${({active}) => active === 'phone' ? '1' : '0'};
    visibility: ${({active}) => active === 'phone' ? 'visible' : 'hidden'};
`;

const AuthEmail = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    transition: opacity .2s linear, top .4s linear;
    top: ${({active}) => active === 'mail' ? '0' : '150px'};
    opacity: ${({active}) => active === 'mail' ? '1' : '0'};
    visibility: ${({active}) => active === 'mail' ? 'visible' : 'hidden'};
`;

const RegEmail = styled.div`
    width: 100%;
`;

const AuthToggleText = styled.span`
	box-sizing: border-box;
	color: ${TXT_COLOR};
	font-size: 16px;
	line-height: 19px;
	padding: 5px 20px;
	cursor: pointer;
	
	@media(max-width: 992px){
		display: none;
	}

	&:hover {
		text-decoration: none;
		color: #E0D5D2;
	}
`;

const AuthToggleIcon = styled.span`
	background: url(${profileIcon});
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	height: 25px;
	width: 25px;
	order: 3;

	@media (min-width: 992.01px) {
		display: none;
	}
	
	@media (max-width: 768px){
		margin-left: 20px;
		margin-right: 50px;
	}
`;

const AuthToggleButton = styled.div`
    width: auto;
    min-width: 320px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 0 15px;
    position: relative;
    color: ${TXT_COLOR};
    background: transparent;
    z-index: ${({openSelect}) => openSelect ? "5001" : "350"};
    transition: 100ms linear;
    
    @media (min-width: 992.01px) {
		display: none;
	}
    
    @media (max-width: 992px) {
      min-width: auto;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      border: 1px solid #E6E9F0;
      border-radius: 66px;
    }
`

const PanelActiveCompany = styled.div`
    display: flex;
    align-items: center;
    
    @media(max-width:992px){
        max-width: 100%;
        margin: 0 auto;
        padding: 0 10px;
    }
`;

const AuthButtonText = styled.p`
    @media (min-width: 992.01px) {
        display: none;
    }

    @media(max-width: 992px){
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        margin: 0;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        font-size: ${({fromNav}) => fromNav ? '15px' : '13px'};
        line-height: ${({fromNav}) => fromNav ? '44px' : '15px'};
        color: ${({fromNav}) => fromNav ? '#222222' : 'inherit'};
    }
`

const AuthTitle = styled.p`
	text-align: left;
	font-size: 28px;
	font-weight: 500;
	margin-bottom: 24px;
	
	@media(max-width: 992px){
	    margin-bottom: 16px;
	}
`;

const AuthInput = styled(InputMask)`
	/*width: 60%;*/
	width: calc(100% - 173px) !important;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-right: 24px;
	padding: 8px 16px;
	text-align: left;
	transition: 0.15s ease all;
	outline: none;

	&:focus {
		outline: none;
		border-color: #E0D5D2;
	}
	
    @media(max-width: 992px){
	    width: 100% !important;
	    
	    &::-webkit-input-placeholder {
            color: transparent !important;
        }
	}

	${props =>
    props.hasError &&
    css`
			border-color: #ff4800;

			&:focus {
				border-color: #ff4800;
			}
		`}
`;

const AuthInputEmail = styled(Field)`
    width: 60%;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 8px 16px;
	text-align: left;
	transition: 0.15s ease all;
	outline: none;
	
	@media(max-width: 992px){
	    width: 100%;
	    
	    &::-webkit-input-placeholder {
            color: transparent;
        }
	}
`;

const MessageTimer = styled.div`
    font-size: 12px;
    color: #223;
    padding-left: 15px;
`;

const StringAuth = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
    
    @media(max-width:992px){
        flex-direction: column;
        align-items: flex-start;
    }
    
    &:nth-child(2){
        opacity: ${({disable}) => !disable ? '0.4' : '1'};
    }
    
    &:nth-child(2) ${AuthInput} {
        width: auto;
        padding: 8px 16px;
    }
    
    &:nth-child(1){
        opacity: ${({disable}) => disable ? '0.4' : '1'};
    }
`;

const StringAuthEmail = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    
    @media(max-width:992px){
        flex-direction: column;
        align-items: flex-start;
    }
`;

const LabelAuth = styled.div`
    width: 30%;
    
    @media(max-width:992px){
        width: 100%;
    }
`;

const AuthRow = styled.div`
	margin-bottom: 15px;
    display: flex;
    flex-direction: column;

	&:last-child {
		margin-bottom: 0;
	}
	
	@media(max-width:992px){
        width: 100%;
    }
`;

const AuthRowEmail = styled.form`
	margin-bottom: 15px;
    display: flex;
    flex-direction: column;

	&:last-child {
		margin-bottom: 0;
	}
`;

const AuthButton = styled.div`
	display: block;
	margin: 24px auto 0;
	background: ${({enabled}) => enabled ? MAIN_COLOR : 'rgba(255, 23, 68, 0.5)'};
	border: none;
	border-radius: 6px;
	color: #fff;
	padding: 11px 28px;
	cursor: ${({enabled}) => enabled ? 'pointer' : 'not-allowed'};
	font-size: 17px;
	line-height: 22px;

	&:focus {
		outline: none;
	}
`;

const RegButton = styled(AuthButton)`
	margin: 20px auto 0;
`;

const LeftBlock = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 32px;
    transition: .3s linear;
    opacity: ${({action}) => action === "auth" ? '1' : '0'};
    z-index: ${({action}) => action === "auth" ? '1' : '-1'};
    
    &.auth-enter {
        transform: translateX(350px);
        opacity: 0;
        transition: transform .3s cubic-bezier(0, 0.45, 0, 0.59), opacity .3s linear .5s;
    }
    
    &.auth-enter-active {
        transform: translateX(0);
        opacity: 1;
        transition: transform .3s cubic-bezier(0, 0.59, 0, 0.45), opacity .3s linear .5s;
    }
    
    &.auth-exit {
        opacity: 1;
    }
    
    &.auth-exit-active {
        transform: translateX(350px);
        opacity: 0;
        transition: transform .3s cubic-bezier(0, 0.45, 0, 0.59), opacity .3s linear;
    }
    
    @media(max-width: 992px){
      padding: 24px;
    }
`;

const LeftBlockWrapper = styled.div`
    //width: 50%;
    width: 100%;
    padding: 32px 56px 32px 32px;
    transition: .3s linear;
    position: relative;
    
    @media(max-width: 992px){
      padding: 24px;
    }
    
    &.left-enter{
        transform: translateX(0);
        transition: .5s linear;
    }
    &.left-enter-active{
        transform: translateX(100%);
        transition: .5s linear;
    }
    &.left-enter-done{
        transform: translateX(0);
        transition: .5s linear;
    }
    &.left-exit{
        transform: translateX(100%);
    }
    &.left-exit-active{
        transform: translateX(0);
        transition: .5s linear;
    }
    &.left-exit-done{
        transform: translateX(100%);
        transition: .5s linear;
    }
`;

const LeftBlockRegistration = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
    transition: .3s linear;
    opacity: ${({action}) => action === "reg" ? '1' : '0'};
    z-index: ${({action}) => action === "reg" ? '1' : '-1'};
    
    &.reg-enter {
        opacity: 0;
        transform: translateX(-350px);
        transition: transform .3s cubic-bezier(0, 0.45, 0, 0.59), opacity .3s linear .5s;
    }
    
    &.reg-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: transform .3s cubic-bezier(0, 0.59, 0, 0.45), opacity .3s linear .5s;
    }
    
    &.reg-exit {
        opacity: 1;
        transition: .3s linear;
    }
    
    &.reg-exit-active {
        opacity: 0;
        transform: translateX(-350px);
        transition: transform .3s cubic-bezier(0, 0.45, 0, 0.59), opacity .3s linear;
    }
    
`;

const ImageFone = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: 2s cubic-bezier(0.58, 0.27, 0.31, 0.85);
    padding: 40px;
    filter: blur(10px);
`;

const ButtonToggle = styled.div`
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 66px;
    color: #fff;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: 334px;
    padding: 20px 0;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
    transition: 1s cubic-bezier(0.58, 0.27, 0.31, 0.85);
`;

const RightBlock = styled.div`
    width: 50%;
    background: linear-gradient(48.18deg,${MAIN_COLOR} 1.4%,${MAIN_COLOR} 98.82%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s linear;
    position: relative;
    z-index: 10;
    
    &.right-enter{
        transform: translateX(0);
        transition: .5s linear;
    }
    &.right-enter-active{
        transform: translateX(-100%);
        transition: .5s linear;
    }
    &.right-enter-done{
        transform: translateX(-100%);
        transition: .5s linear;
    }
    &.right-exit{
        transform: translateX(-100%);
    }
    &.right-exit-active{
        transform: translateX(0);
        transition: .5s linear;
    }
    
    &:hover ${ImageFone}{
      transform: scale(1.2) rotate(65deg);
    }
    
    &:hover ${ButtonToggle}{
      transform: scale(1.1);
    }
    
    @media(max-width: 768px){
      display: none;
    }
`;

const TextSpan = styled.span`
    opacity: ${({toggle}) => toggle};
`;

const Variant = styled.div`
    border-bottom: 1px dashed #999999;
    margin-right: 30px;
    cursor: pointer;
    user-select: none;
    
    &:first-child{
        border-bottom: ${({active}) => active === 'phone' ? '1px dashed #223' : '1px dashed #999999'};
        color: ${({active}) => active === 'phone' ? '#223' : '#999999'};
    }
    
    &:last-child{
        border-bottom: ${({active}) => active === 'mail' ? '1px dashed #223' : '1px dashed #999999'};
        color: ${({active}) => active === 'mail' ? '#223' : '#999999'};
    }
    
    @media(max-width: 992px){
        width: 50%;
        border-bottom: none !important;
        display: flex;
        align-items: flex-end;
    }
`;

const AuthVariantBlock = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 40px;
`;

const MessError = styled.div`
    background: rgba(160,0,0,0.81);
    width: 100%;
    height: 40px;
    position: absolute;
    transition: .2s linear;
    bottom: ${({error}) => error === 1 ? '0' : '-40px'};
    left: 0;
    overflow: hidden;
    padding-left: 25px;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
`;

const TermsOfUse = styled.div`
    display: flex;
    /*margin-bottom: 24px;*/
`;

const TermsOfUseCheckBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin: 3px 16px 0 0;
    border-radius: 4px;
    border: ${({active}) => active ? '0' : '1px solid #BCBCBC'};
    background: ${({active}) => active ? MAIN_COLOR : 'transparent'};
    cursor: pointer;
`;

const TermsOfUseText = styled.p`
    width: calc(100% - 62px);
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #2C2E32;
    
    @media(max-width: 992px){
        width: calc(100% - 38px);
        font-size: 11px;
        line-height: 14px;
    }
`;
