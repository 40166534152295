import {
	GET_PROFILE_REQUEST,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_ERROR,
	GET_COMPANIES_REQUEST,
	GET_COMPANIES_SUCCESS,
	GET_COMPANIES_ERROR,
	SEND_CODE_REQUEST,
	SEND_CODE_SUCCESS,
	SEND_CODE_ERROR,
	AUTH_EMAIL_REQUEST,
	AUTH_EMAIL_SUCCESS,
	AUTH_EMAIL_ERROR,
	DELETE_TOKEN_SUCCESS,
	HANDLE_REDUX_FORM_AUTH,
	HANDLE_REDUX_FORM_PROFILE_INFO,
	HANDLE_REDUX_FORM_COMPANY_INFO_INITIAL_VALUE,
	HANDLE_REDUX_FORM_REG,
	HANDLE_REDUX_FORM_EDIT_COMPANY,
	CREATE_TOKEN_ERROR,
	PARAMS_GET_REQUEST,
	PARAMS_GET_SUCCESS,
	CHANGE_ACTIVE_COMPANY,
	GET_WALLETS_SUCCESS,
	GET_WALLETS_ERROR,
	GET_WALLET_LIMIT_SUCCESS,
	GET_WALLET_LIMIT_ERROR,
} from "../actions/ProfileActions";

const initialState = {
	profile: {},
	companies: [],
	activeCompany: null,
	isAuth: false,
	codeSent: false,
	phoneError: "",
	emailAuthError: "",
	city: [] || null,
	errorMessage: null,
	valueAuthEmail: {},
	valueInfoProfile: {},
	valueReg: {},
	valueEditCompany: {},
	initialValues: {},
	paramsGetRequest: [],
	hash: '',
	wallets: [],
};

export function profileReducer(state = initialState, action) {
	switch (action.type) {
		case GET_PROFILE_REQUEST:
			return {
				...state,
				profile: {},
				isAuth: false
			};

		case GET_PROFILE_SUCCESS:
			return {
				...state,
				profile: action.payload,
				isAuth: true
			};

		case GET_PROFILE_ERROR:
			return {
				...state,
				isAuth: false
			};

		case GET_COMPANIES_REQUEST:
			return {
				...state,
				companies: [],
				activeCompany: '',
				errorGetCompanies: false
			};

		case GET_COMPANIES_SUCCESS:
			return {
				...state,
				companies: action.payload.companies,
				activeCompany: action.payload.activeCompany,
				errorGetCompanies: false
			};

		case GET_COMPANIES_ERROR:
			return {
				...state,
				errorGetCompanies: action.payload
			};

		case SEND_CODE_REQUEST:
			return {
				...state,
				codeSent: false,
				phoneError: false,
				errorMessage: null
			};

		case SEND_CODE_SUCCESS:
			return {
				...state,
				codeSent: true
			};

		case SEND_CODE_ERROR:
			return {
				...state,
				codeSent: false,
				phoneError: true,
				errorMessage: action.payload
			};

		case AUTH_EMAIL_REQUEST:
			return {
				...state,
				errorMessage: null
			};

		case AUTH_EMAIL_SUCCESS:
			return {
				...state,
				isAuth: true
			};

		case AUTH_EMAIL_ERROR:
			return {
				...state,
				emailAuthError: true,
				errorMessage: action.payload
			};

		case DELETE_TOKEN_SUCCESS:
			return {
				...state,
				codeSent: false,
				isAuth: false
			};

		case HANDLE_REDUX_FORM_AUTH:
			return {
				...state,
				valueAuthEmail: action.payload,
			};

		case HANDLE_REDUX_FORM_REG:
			return {
				...state,
				valueReg: action.payload,
			};

		case HANDLE_REDUX_FORM_EDIT_COMPANY:
			return {
				...state,
				valueEditCompany: action.payload,
			};

		case HANDLE_REDUX_FORM_PROFILE_INFO:
			return {
				...state,
				valueInfoProfile: action.payload,
			};

		// case HANDLE_REDUX_FORM_PROFILE_INFO_INITIAL_VALUE:
		// 	return {
		// 		...state,
		// 		initialValues: action.payload,
		// 	};

		case HANDLE_REDUX_FORM_COMPANY_INFO_INITIAL_VALUE:
			return {
				...state,
				initialValuesEditCompany: action.payload,
			};

		case CREATE_TOKEN_ERROR:
			return {
				...state,
				errorMessage: action.payload
			};

		case PARAMS_GET_REQUEST:
			return {
				...state,
				paramsGetRequest: [],
				hash: ''
			};

		case PARAMS_GET_SUCCESS:
			return {
				...state,
				paramsGetRequest: action.payload.params,
				hash: action.payload.hash
			};

		case CHANGE_ACTIVE_COMPANY:
			return {
				...state,
				activeCompany: action.payload
			};

		case GET_WALLETS_SUCCESS:
			return {
				...state,
				wallets: action.payload
			};

		case GET_WALLETS_ERROR:
			return {
				...state,
				wallets: []
			};

		case GET_WALLET_LIMIT_SUCCESS:
			return {
				...state,
				wallet_paid: action.payload
			};

		case GET_WALLET_LIMIT_ERROR:
			return {
				...state,
				wallet_paid: ''
			};

		default:
			return state;
	}
}
