import React, {Component, Fragment} from "react";
import styled from "styled-components";
import smart from "../../images/logo_new.svg";
import close from "../../images/close-nav-cross.svg";
import ym from "react-yandex-metrika";
import ReactPixel from 'react-facebook-pixel';

class SentencePopup extends Component {
    constructor(props){
        super(props);

        this.getCookie = (name) => {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length === 2) return parts.pop().split(";").shift();
        };

        this.state = {
            isOpen: false,
            cookieAttach: this.getCookie("settingAttachment")
        };

        this.writeCookie = this.writeCookie.bind(this);
    }

    writeCookie(getApp = false){
        document.cookie = "settingAttachment = yes";

        setTimeout(()=>{
            this.setState({
                isOpen: !this.state.isOpen,
                cookieAttach: "yes"
            }, () => {
                this.props.editMargin();
            });
        }, 300)

        if (
            getApp
            && !window.location.href.includes('51obed.ru')
            && !window.location.href.includes('51.menu4me-test.ru')
            && !window.location.href.includes('stolovka.su')
            && !window.location.href.includes('straus.menu4me-test.ru')
        ) {
            ym('reachGoal','app_store');
            ReactPixel.trackCustom('Переход к странице приложения');
        }
    }

    render() {
        return(
            // <Fragment>
            //     <Popup onTouchStart={this.writeCookie} style={{
            //         "opacity": this.state.isOpen ? "0" : "0.3",
            //         "zIndex": this.state.isOpen ? "-1" : "10000",
            //         "display": this.state.isOpen ? "none" : "flex",
            //     }}/>
            //     <Message style={{
            //         "bottom": this.state.isOpen ? "-80%" : "0",
            //         "zIndex": this.state.isOpen ? "-1" : "10001",
            //         "display": this.state.isOpen ? "none" : "flex",
            //     }}>
            //         <ImgItem>
            //             <Img src={smart}/>
            //         </ImgItem>
            //         <Title>
            //             У нас есть удобное мобильное приложение для заказа обедов
            //         </Title>
            //         <Buttons>
            //             <ButtonDownload onClick={this.writeCookie} href={this.props.href}><FontAwesomeIcon className={"fab fa-" + this.props.electrStore}></FontAwesomeIcon>&#8195;<span>Скачать приложение</span></ButtonDownload>
            //             <ButtonRemove onClick={this.writeCookie}>Продолжить пользоваться сайтом</ButtonRemove>
            //         </Buttons>
            //     </Message>
            // </Fragment>
            <Message style={{display: this.state.isOpen ? "none" : "flex"}}>
                <LeftBlock>
                    <ButtonRemove onClick={() => this.writeCookie()}>
                        <img src={close} alt="x" width={12}/>
                    </ButtonRemove>
                    <ImgItem>
                        <Img src={smart}/>
                    </ImgItem>
                    <Title>
                        Приложение<br/><span style={{fontWeight: 'bold'}}>Сытый</span>
                    </Title>
                </LeftBlock>
                <ButtonDownload
                    onClick={() => this.writeCookie(true)}
                    href={this.props.href}
                >
                    Установить
                </ButtonDownload>
            </Message>
        );
    }
}

export default SentencePopup;

const Popup = styled.div`
    z-index: 10000;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    transition: .3s linear;
`;

const Message = styled.div`
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #F6F6F6;
    margin: auto;
    padding: 16px 9px 16px 0;
    transition: .3s linear;
    height: 74px;
`;

const Title = styled.p`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin: 0 11px 0 0;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ButtonDownload = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: none;
    color: #111 !important;
    background: #CDDC39;
    text-decoration: none !important;
    font-size: 12px;
    line-height: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    width: 117px;
    height: 42px;
`;

const ButtonRemove = styled.div`
    width: 36px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
`;

const ImgItem = styled.div`
    width: 42px;
    height: 42px;
    margin-right: 11px;
`;

const Img = styled.img`
    width: 42px;
    height: 42px;
`;

const FontAwesomeIcon = styled.i`
    font-size: 28px;
`;

const LeftBlock = styled.div`
    display: flex;
    align-items: center;
`