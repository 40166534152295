import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Basket from "../../Widget/Basket";
import Edit from "../../Widget/Edit";
import Check from "../../Widget/CheckList";
import * as _ from "underscore";

var scrollHeight = Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
);

const Widget = (props) => {
    const[coordStart, changeCoordStart] = useState(0);   // Координата первого щелчка мыши
    const[coordY, changeCoordY] = useState(54);   // Координата положения мыши по оси Y
    const[coordPageScroll, changePageScroll] = useState(0);   // Пикселей отскроллено до разворачивания окна виджета
    let coordSectionMenuWrapper = document.getElementById("sectionMenuWrapper") ? document.getElementById("sectionMenuWrapper").getBoundingClientRect() : false;

    // Правая кнопка мыши нажата
    const getTouchStart = (e) => {
        changeCoordStart(document.documentElement.clientHeight - e.targetTouches[0].clientY);
        document.body.style.overflow = "hidden";
    };

    // Правая кнопка мыши нажата, курсор движется
    const getTouchMove = (e) => {
        let heightActiveWindow = document.documentElement.clientHeight;   //  Высота экрана без учёта скроллинга
        let changedTouch = e.targetTouches[0].clientY;   //  Координата курсора

        if (coordStart <= 100 && coordStart !== 0) {   //  Щёлкнули внизу
            if (changedTouch + coordStart <= heightActiveWindow) {
                changeCoordY((heightActiveWindow - changedTouch) + coordStart);
            } else {
                return false
            }
        } else if (coordStart >= (heightActiveWindow - 100)) {   //  Щёлкнули вверху
            if (changedTouch + (heightActiveWindow - coordStart) <= heightActiveWindow) {
                changeCoordY(coordStart - (changedTouch - 54));
            } else {
                return false
            }
        }
    };

    // Правая кнопка мыши отжата
    const getTouchEnd = (e) => {
        let heightActiveWindow = document.documentElement.clientHeight;
        let changedTouch = e.changedTouches[0].clientY;

        if (changedTouch === coordY) {
            return false;
        }

        if (coordStart <= 100) {   // Щёлкнули внизу
            if ((heightActiveWindow - changedTouch) >= 175) {
                changeCoordY(heightActiveWindow);
                props.changeOpenBasket(true);
                changePageScroll(window.pageYOffset);
                document.body.style.top = "-" + window.pageYOffset + "px";
                document.body.classList.add("no-scroll");
            } else {
                changeCoordY(54);
            }
        } else if ((heightActiveWindow - coordStart) < 100) {   //Щёлкнули вверху
            if (changedTouch <= 75) {
                changeCoordY(heightActiveWindow);
            } else {
                changeCoordY(54);
                props.changeOpenBasket(false);
                document.body.classList.remove("no-scroll");
                window.scrollBy(0, coordPageScroll);
                document.body.style.top = coordPageScroll + "px";
            }
        }
        document.body.style.overflow = "auto";
    };

    useEffect(()=>{
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    return (
        <SectionBasket
            toggle={props.toggle}   // Параметр переворачивания виджета на 180 градусов
            openBasket={props.openBasket}  // Параметр разворачивания / сворачивания виджета
            fixed={props.basketFixed}   // Параметр фиксирования виджета
            width={coordSectionMenuWrapper.width}   // Ширина виджета
            rightCoord={parseFloat(coordSectionMenuWrapper.right) - parseInt(coordSectionMenuWrapper.width) + 20}   // Параметр диффиренциала между виджетом и правым краем экрана
            basket={props.basket}
            id="sectionBasket"
            height={coordY}
            basketBottom={props.basketBottom}
            isMobile={props.isMobile}
        >
            <Basket
                changeWidget={props.changeWidget}   // Функция изменения состояния виджета, которая при вызове ставит 2 состояния из 3 в true
                basket={props.basket}   // Идентификационный параметр экрана "Корзины" на виджете (если true - показать, иначе скрыть)
                toggle={props.toggle}   // Параметр переворачивания виджета (основного род. блока) на 180 градусов
                changeOpenBasket={props.changeOpenBasket}   // Функция разворачивания / сворачивания виджета
                openBasket={props.openBasket}   // Параметр разворачивания / сворачивания виджета
                orders={props.orders}   // Массив товаров в корзине
                addToCart={props.addToCart}   // Функция добавления в корзину
                removeFromCart={props.removeFromCart}   // Функция удаления из корзины
                deleteToCart={props.deleteToCart}   // Функция удаления товара (независимо от его кол-ва)
                saveOrder={props.saveOrder}   // Функция сохранения заказа
                saveOrders={props.saveOrders}
                getOrders={props.getOrders}   // Метод получения меню
                day={props.day}   // Текущий выбранный пользователем день
                deleteBasket={props.deleteBasket}   // ФУнкция удаления всех товаров в корзине
                infoMessCreate={props.deliveryCycleInfoMessage}   // Функция для вызова всплывающего окна информирующего о создании заказа
                sum={props.sum}   // Сумма набранных в корзине товаров
                restaurants={props.restaurants}   // Массив всех ресторанов по городу
                companyOrder={_.size(props.companyOrder) !== 0 && props.companyOrder}
                // getTouchEnd={getTouchEnd}
                // getTouchMove={getTouchMove}
                // getTouchStart={getTouchStart}
                restarauntsInfo={props.restarauntsInfo}
                activeCompany={props.activeCompany}
                activePeriod={props.activePeriod}
                periods={props.periods}
                closeCart={props.closeCart}
                isMobile={props.isMobile}
                profile={props.profile}
                getCheck={props.getCheck}
                checkData={props.checkData}
                checkLoaded={props.checkLoaded}
                clearCheck={props.clearCheck}
            />
            <Edit
                changeWidget={props.changeWidget}   // Функция изменения состояния виджета, которая при вызове ставит 2 состояния из 3 в true
                edit={props.edit}   // Идентификационный параметр экрана "Корзины" на виджете (если true - показать, иначе скрыть)
                toggle={props.toggle}   // Параметр переворачивания виджета (основного род. блока) на 180 градусов
                changeOpenBasket={props.changeOpenBasket}   // Функция разворачивания / сворачивания виджета
                openBasket={props.openBasket}   // Параметр разворачивания / сворачивания виджета
                orders={props.orders}   // Массив товаров в корзине
                addToCart={props.addToCart}   // Функция добавления в корзину
                removeFromCart={props.removeFromCart}   // Функция удаления из корзины
                deleteToCart={props.deleteToCart}   // Функция удаления товара (независимо от его кол-ва)
                saveOrder={props.saveOrder}   // Функция сохранения заказа
                saveOrders={props.saveOrders}
                getSum={props.getSum}   // Метод сохранения общщей суммы товаров в корзине
                getCheckEdit={props.getCheckEdit}   // Параметр прелоадера, сигнализирующий о том, что меню загружено
                getOrders={props.getOrders}   // Метод получения меню
                payStatus={props.payStatus}   // Параметр вида оплаты
                regOrder={_.reduceRight(_.map(props.regOrder, item => {return item.products}), function(a, b) { return a.concat(b); }, [])}
                regOrders={props.regOrder}
                day={props.day}   // Текущий выбранный пользователем день
                infoMessEdit={props.deliveryCycleInfoMessage}   // Функция для вызова всплывающего окна информирующего о редактировании заказа
                restaurants={props.restaurants}   // Массив всех ресторанов по городу
                sum={props.sum}   // Сумма набранных в корзине товаров
                companyOrder={_.size(props.companyOrder) !== 0 && props.companyOrder}   // Данные о зарегистрированном заказе
                // getTouchEnd={getTouchEnd}
                // getTouchMove={getTouchMove}
                // getTouchStart={getTouchStart}
                restarauntsInfo={props.restarauntsInfo}
                getAccountsForDate={props.getAccountsForDate}
                activePeriod={props.activePeriod}
            />
            <Check
                changeWidget={props.changeWidget}
                check={props.check}
                toggle={props.toggle}
                changeOpenBasket={props.changeOpenBasket}
                openBasket={props.openBasket}
                orders={props.orders}
                addToCart={props.addToCart}
                removeFromCart={props.removeFromCart}
                regOrder={props.regOrder}
                payTypeChange={props.payTypeChange}
                changeOpenPayTypeModal={props.changeOpenPayTypeModal}
                openPayType={props.openPayType}
                payType={props.payType}
                saveOrder={props.saveOrder}
                getSum={props.getSum}
                getCheckEdit={props.getCheckEdit}
                getOrders={props.getOrders}
                payStatus={props.payStatus}
                day={props.day}
                editToCart={props.editToCart}
                companyOrder={_.size(props.companyOrder) !== 0 && props.companyOrder}
                infoMessRemove={props.deliveryCycleInfoMessage}   // Функция для вызова всплывающего окна информирующего об удалении заказа
                restaurants={props.restaurants}   // Массив всех ресторанов по городу
                restarauntsInfo={props.restarauntsInfo}
                widgetPreloader={props.widgetPreloader}
                // getTouchEnd={getTouchEnd}
                // getTouchMove={getTouchMove}
                // getTouchStart={getTouchStart}
                timeStatus={props.timeStatus}
                payLoading={props.payLoading}
                getAccountsForDate={props.getAccountsForDate}
                activePeriod={props.activePeriod}
                periods={props.periods}
                closeCart={props.closeCart}
                isMobile={props.isMobile}
            />
        </SectionBasket>
    );
};

export default Widget;

const SectionBasket = styled.div`
    min-height: 515px;
    height: ${({fixed}) => fixed ? 'calc(100vh - 172px)' : 'calc(100vh - 328px)'};
    width: 300px;
    min-width: 300px;
    transition: height .2s ease-in-out, transform .3s linear, bottom .3s ease-out;
    perspective: 1000px;
    transform: ${({toggle})=> toggle ? 'rotateY(180deg)' : 'rotateY(0deg)'};
    border-radius: 6px;
    position: ${({fixed, basketBottom}) => fixed && !basketBottom ? 'fixed' : fixed && basketBottom ? 'absolute' : 'relative'};
    right: ${({fixed, width, rightCoord, basketBottom}) => fixed ? width >= 1280 ? basketBottom ? '20px' : rightCoord + "px" : '0' : '0'};
    top: ${({fixed, basketBottom}) => fixed && !basketBottom ? '82px' : 'inherit'};
    bottom: ${({fixed, basketBottom}) => fixed && basketBottom ? '0' : 'inherit'};
    
    @media (max-width: 992px) {
        min-height: auto;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100); /* Фикс для динамической высоты viewport в Google Chrome */
        min-width: auto;
        width: 100vw;
        display: ${({isMobile}) => isMobile ? 'block' : 'none'};
    }
`;
