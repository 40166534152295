import {
	GET_NEW_MENU_REQUEST,
	GET_NEW_MENU_SUCCESS,
	GET_NEW_MENU_ERROR,
	GET_NEW_ORDERS,
	GET_NEW_ORDERS_SUCCESS,
	FILTER_NEW_ORDERS,
	EDIT_TIME,
	EDIT_TIME_REQUEST,
	EDIT_TIME_ORDER,
	GET_SUM_COUNT,
	EDIT_DAY,
	EDIT_PERIOD,
	SAVE_ORDER_REQUEST,
	SAVE_ORDER,
	SAVE_ORDER_ERROR,
	CLEAR_ORDER,
	GET_CHECK_EDIT,
	GET_CHECK_EDIT_CATEGORY,
	DOWNLOAD_ORDER,
	DOWNLOAD_ORDER_REQUEST,
	GET_ACCOUNTS,
	GET_GENERAL_SETTINGS,
	GET_DELIVERY_PRICE,
	GET_DELIVERY_TIME,
	GET_ACTIVE_PRODUCT,
	GET_SETTINGS_TIME,
	GET_MAIN_TIME,
	GET_DIALOG_ADD_GOOD,
	GET_CHANGE_PAY_TYPE,
	GET_CHANGE_WIDGET_ACTION,
	GET_RESTARAUNTS_INFO,
	GET_SETTINGS,
	GET_CHECK_REQUEST,
	GET_CHECK_SUCCESS,
	GET_CHECK_ERROR,
	GET_CHECK_CLEAR,
	SET_PAY_URL,
	CLEAR_PAY_URL,
} from "../actions/MenuActions";
import * as moment from 'moment';
import 'moment/locale/ru';

const arrayDaysPlusMinusSeven = () => {
    var currentDate = moment();
    var weekStart = currentDate.startOf('day');

    var days = [];
    for (var i = -1; i <= 7; i++) {
        let momentDay = moment(weekStart).add(i, 'days').format("dddd, D MMMM");
        let upperOneSymbol = momentDay.charAt(0).toUpperCase() + momentDay.slice(1);

        let day = [moment(weekStart).add(i, 'days').format("YYYY-MM-DD"), upperOneSymbol];
        days.push(day);
    };

    return days;
};

const initialState = {
	menu: [],
	orders: [],
	activeIndex: 0,
	activeProduct: "1",
	menuLoaded: false,
	menuLoadedCategory: false,
	menuError: false,
	ordersLoaded: false,
	daysSeven: arrayDaysPlusMinusSeven(),
	time: -1,
	timeStatus: -2,
    timeOrder: 0,
	serverTimeUTC: '',
	sum: 0,
	day: moment().utcOffset(240).format("YYYY-MM-DD"),
	response: {},
	checkCount: false,
	payType: 1,
    registrationOrders: [],
	companyOrder: [],
	deliveryPrice: 0 || null,
	deliveryTime: 0,
	dialogAddGoods: {
		bool: false,
		good: []
	},
	basketWidget: true,
	checkWidget: false,
	editWidget: false,
	widgetPreloader: false,
	restarauntsInfo: {},
	checkData: {},
	checkError: "",
	checkLoaded: false,
	checkLoading: false,
	orderSending: false,
	createdOrder: null,
	payUrl: '',
};

export function menuReducer(state = initialState, action) {
	switch (action.type) {
		case GET_NEW_MENU_REQUEST:
            return {
                ...state,
                menu: action.payload.menu,
                menuLoaded: false,
                menuError: action.payload.menu.elements.length ? false : "На текущий день меню отсутствует",
            };
		case GET_NEW_MENU_SUCCESS:
            return {
				...state,
				menu: [],
				menuLoaded: true
			};

		case GET_NEW_MENU_ERROR:
			return {
				...state,
				menuLoaded: !state.menuLoaded,
			};

		case EDIT_TIME:
			return {
				...state,
				timeStatus: action.payload,
			};

		case EDIT_TIME_REQUEST:
			return {
				...state,
				timeStatus: -2
			};

        case EDIT_TIME_ORDER:
            return {
                ...state,
                timeOrder: action.payload
            };

        case EDIT_DAY:
            return {
                ...state,
                day: action.payload
            };

		case EDIT_PERIOD:
			return {
				...state,
				period: action.payload
			};

		case GET_SUM_COUNT:
			return {
				...state,
				sum: action.payload
			};

		case GET_NEW_ORDERS_SUCCESS:
			return {
				...state,
				orders: [],
			};
		case GET_NEW_ORDERS:
			if(action.payload !== []){
				return {
					...state,
					...state.orders,
					orders: action.payload,
				};
			}else{
				return {
					...state,
					orders: [],
				};
			}

		case FILTER_NEW_ORDERS:
			if (action.payload !== '') {
				console.log(state.orders.filter(item => item.restId !== action.payload), 'скрррр')
				return {
					...state,
					orders: state.orders.filter(item => item.restId !== action.payload)
				};
			}

		case SAVE_ORDER_REQUEST:
			return{
				...state,
				widgetPreloader: true,
				orderSending: true,
			};

		case SAVE_ORDER:
			return{
				...state,
				widgetPreloader: false,
				orderSending: false,
				createdOrder: action.payload
			};

		case SAVE_ORDER_ERROR:
			return{
				...state,
				widgetPreloader: false,
				orderSending: false
			};

		case CLEAR_ORDER:
			return{
				...state,
				createdOrder: null
			};

		case DOWNLOAD_ORDER_REQUEST:
			return{
				...state,
				registrationOrders: [],
			};

        case DOWNLOAD_ORDER:
            return{
                ...state,
                registrationOrders: action.payload.regOrders,
				companyOrder: action.payload.companyOrder
            };

        case GET_CHECK_EDIT:
            return{
                ...state,
                menuLoaded: !state.menuLoaded
            };

		case GET_CHECK_EDIT_CATEGORY:
			return{
				...state,
				menuLoadedCategory: !state.menuLoadedCategory
			};

		case GET_RESTARAUNTS_INFO:
			return{
				...state,
				restarauntsInfo: action.payload
			};

		// Список ресторанов и их настройки
		case GET_ACCOUNTS:
			return{
				...state,
				accounts: action.payload
			}

		// Общие настройки для ресторанов
		case GET_GENERAL_SETTINGS:
			return{
				...state,
				generalSettings: action.payload
			};

		case GET_DELIVERY_PRICE:
			return{
				...state,
				deliveryPrice: action.payload
			};

		case GET_DELIVERY_TIME:
			return{
				...state,
				deliveryTime: action.payload
			};

		case GET_ACTIVE_PRODUCT:
			return{
				...state,
                activeProduct: action.payload
			};

        case GET_SETTINGS_TIME:
            return{
                ...state,
                serverTimeUTC: action.payload
            };

		case GET_SETTINGS:
			return{
				...state,
				getSettings: action.payload
			};

		case GET_MAIN_TIME:
            return{
                ...state,
                time: action.payload
            };

		case GET_CHANGE_PAY_TYPE:
            return{
                ...state,
                payType: action.payload
            };

		case GET_CHANGE_WIDGET_ACTION:
            return{
                ...state,
                ...action.payload
            };

		case GET_DIALOG_ADD_GOOD:
			let data = action.payload;
            return{
                ...state,
				dialogAddGoods: {
                	bool: data.bool,
					good: data.good
				}
            };

		case GET_CHECK_REQUEST:
			return {
				...state,
				//checkData: {},
				//checkLoaded: false
				checkLoading: true,
			};

		case GET_CHECK_SUCCESS:
			return {
				...state,
				checkData: action.payload,
				checkLoaded: true,
				checkLoading: false,
			};

		case GET_CHECK_ERROR:
			return {
				...state,
				checkError: action.payload,
				checkLoaded: false,
				checkLoading: false,
			};

		case GET_CHECK_CLEAR:
			return {
				...state,
				checkData: {},
				checkLoaded: false
			};

		case SET_PAY_URL:
			return {
				...state,
				payUrl: action.payload,
			};

		case CLEAR_PAY_URL:
			return {
				...state,
				payUrl: '',
			};

		default:
			return state;
	}
}
