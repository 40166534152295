import axios from "axios";
import platform from "platform";
import { nanoid } from 'nanoid'
import { sha256 } from "./sha256";

export async function request(service, method, data = {}) {
	const getCookie = (name) => {
		let value = "; " + document.cookie;
		let parts = value.split("; " + name + "=");
		if (parts.length === 2) return parts.pop().split(";").shift();
	};

	const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    };

	let cookieCheck = getCookie("server");

	const API_URL = "https://api.menuforme.online/api/";
	//const API_URL = "http://85.112.51.9:8080/api/";
	//const API_URL = "https://dev.menu4me.ru/api/";

	function runOnKeys(func) {
		var codes = [].slice.call(arguments, 1);
		var pressed = {};

		document.onkeydown = function(e) {
			e = e || window.event;
			pressed[e.keyCode] = true;
			for (var i = 0; i < codes.length; i++) { // проверить, все ли клавиши нажаты
				if (!pressed[codes[i]]) {
					return;
				}
			}
			pressed = {};
			func();
		};

		document.onkeyup = function(e) {
			e = e || window.event;
			delete pressed[e.keyCode];
		};
	}

	runOnKeys(
		function() {
			if(cookieCheck === "main" || !cookieCheck) {
				document.cookie = "server=dev";
				document.location.reload();
			} else {
				document.cookie = "server=main";
				document.location.reload();
			}
		},
		"M".charCodeAt(0),
		"4".charCodeAt(0),
	);



	let localData = {
		token: localStorage.getItem("token") || "",
		session: localStorage.getItem("session") || "",
		loginHash: localStorage.getItem("loginHash") || "",
		codeHash: localStorage.getItem("codeHash") || "",
		deviceId: localStorage.getItem("deviceId") || genDeviceId()
	};

	let reqData = {
		service,
		method,
		data,
		// isSigned: Boolean(localData.token)
	};

	reqData.data.account = 146923;
	reqData.data.holder = {id: 146923};
	reqData.data.isSigned = true;
	reqData.data.lang = "ru_RU";
	reqData.data.token = localData.token;
	reqData.data.session = localData.session;
	reqData.data.timeZoneOffset = Date.now() + "." + getRandomInt(1000, 9999);
	reqData.data.time = sha256(Date.now() + Math.random());
	reqData.data.signature = calcSignature(
		reqData,
		localData.loginHash,
		localData.codeHash
	);
	reqData.data.clientInfo = JSON.stringify({
		os: platform.os.family,
		osVersion: platform.os.version,
		model: platform.description,
		brand: platform.product,
		deviceId: localData.deviceId,
		appVersion: "1.21.0", // 1.20.0 без тестового сбора
		appId: "com.menu4me.dinner.site.sitiyoffice"
	});

	try {
		let response = await axios.post(API_URL, JSON.stringify(reqData));
		if (response.data.result === 0) {
			let error = {
				service: reqData.service,
				method: reqData.method,
				code: response.data.resultCode,
				message: response.data.data.message,
				result: response.data.result,
				errors: response.data.data.errors,
			};
			throw error;
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return error;
	}
}

axios.interceptors.response.use(async response => {
	if (response.data.resultCode === "s152") {
		let refreshResponse = await request("ClientAuthService", "RefreshToken", {
			refreshToken: localStorage.getItem("refreshToken")
		});
		localStorage.setItem("token", refreshResponse.data.token);
		localStorage.setItem("refreshToken", refreshResponse.data.refreshToken);
		let newData = JSON.parse(response.config.data);
		newData.data.token = refreshResponse.data.token;
		response.config.data = JSON.stringify(newData);
		return axios(response.config);
	}
	return response;
});

function calcSignature(reqData, loginHash, codeHash) {


	return sha256(
		reqData.service +
		reqData.method +
		reqData.data.time +
		reqData.data.token +
		loginHash +
		reqData.data.session +
		codeHash
	);
}

function genDeviceId() {
	let deviceId = nanoid();
	localStorage.setItem("deviceId", deviceId);
	return deviceId;
}
