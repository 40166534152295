import PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import Modal from "react-modal";
import styled from "styled-components";
import {connect} from "react-redux";
import {MAIN_COLOR} from "../../../utils/constants";
import cityPin from "../../../images/city-pin.svg";

Modal.setAppElement("#root");

class ChooseCity extends Component {
	static contextTypes = {
		router: PropTypes.object.isRequired
	};

	static propTypes = {
		cities: PropTypes.array.isRequired,
		getRestaurants: PropTypes.func.isRequired
	};

	state = {
		currentCity: "Тольятти",
		activeCity: true,
		openModal: false
	};

    handleOpenModal () {
        this.setState({ openModal: !this.state.openModal });
    }

	componentDidMount() {
		this.getCurrentCity();
	}

	getCurrentCity = () => {
		let currentCity = JSON.parse(localStorage.getItem("currentCity"));
		currentCity = currentCity ? currentCity.name : "Выберите город";
		this.setState({ currentCity });
	};

	setCurrentCity = city => () => {
		this.props.cityModalToggle();
		let { restaurants, ...currentCity } = city;
		localStorage.setItem("currentCity", JSON.stringify(currentCity));
		this.context.router.history.push("/");
		this.getCurrentCity();
		this.props.getRestaurants();

		window.location.reload();
	};

	render() {
		const { cities } = this.props;
		return (
			<Fragment>
				<CityToggle onClick={()=>{this.handleOpenModal()}}>
					{this.props.isMobile && <img src={cityPin} alt="Выбор города" width={8.69} height={12} style={{marginRight: 6.31, marginBottom: 3}}/>}
					{this.props.isMobile ? window.cityNameFormatted()[3] : window.cityNameFormatted()[2]}
				</CityToggle>
				<Modal
					closeTimeoutMS={100}
					isOpen={window.dataCity() ? this.state.openModal : true}
					onRequestClose={()=>{this.handleOpenModal()}}
					overlayClassName={'Overlay'}
					style={window.dataCity() ? customStyles : customStylesOneStart}>
					<ModalWrapper>
						<CityTitle>Выберите город</CityTitle>
						{cities.map(city => (
							<CityBtn key={city.id} onClick={this.setCurrentCity(city)}>
								{city.name}
							</CityBtn>
						))}
					</ModalWrapper>
				</Modal>
			</Fragment>
		);
	}
}

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		transform: "translate(-50%, -50%)",
		padding: "15px",
		backgroundColor: "transparent",
		border: "none",
		cursor: "auto",
		width: "100%",
		maxWidth: "250px"
	}
};

const customStylesOneStart = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "15px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "250px"
    }
};

const mapStateToProps = store => ({
	time: store.menu.time,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCity);

const ModalWrapper = styled.div`
	padding: 25px;
	background: #fff;
	border-radius: 4px;
`;

const CityToggle = styled.span`
	border-bottom: 1px dotted #000;
	transition: all 0.15s ease;
	cursor: pointer;
	white-space: nowrap;
	color: ${MAIN_COLOR};

	@media (min-width: 992.01px) {
		&:hover {
			text-decoration: none;
			color: ${MAIN_COLOR};
		}
	}
	
	@media (max-width: 992px) {
		width: 80px;
		border: none;
		height: 44px;
		margin: 6px 0 0 38px;
		font-family: "Roboto",sans-serif;
		font-weight: 500;
		font-size: 15px;
		line-height: 44px;
		color: #222222 !important;
		text-decoration: none !important;
	}
`;

const CityTitle = styled.p`
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 20px;
	line-height: 1.2;
	
	@media (max-width: 992px) {
		font-family: "Roboto", sans-serif;
	}
`;

const CityBtn = styled.button`
	display: block;
	color: #222;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 18px;
	margin: 0 auto 12px;
	transition: all 0.15s ease;
	line-height: 1.2;

	&:hover {
		color: #E0D5D2;
	}

	&:focus {
		outline: none;
	}

	&:last-child {
		margin-bottom: 0;
	}
	
	@media (max-width: 992px) {
		font-family: "Roboto", sans-serif;
	}
`;
