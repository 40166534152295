import React, {PureComponent} from "react";
import Modal from "react-modal";
import {Link} from "react-router-dom";
import styled from "styled-components";
import backIcon from "../../images/back.svg";
import infoIcon from "../../images/info.svg";

class PageHeader extends PureComponent {
	state = {
		modalIsOpen: false
	};

	toggleModal = () => {
		this.setState({ modalIsOpen: !this.state.modalIsOpen });
	};

	render() {
		const { modalIsOpen } = this.state;
		const {
			backIcon,
			backText,
			backTo,
			infoIcon,
			infoText,
			children
		} = this.props;
		return (
			<Container>
				<InfoWrapper>
					{infoText && <InfoText>{infoText}</InfoText>}
					{infoIcon && <InfoIcon icon={infoIcon} />}
					<Modal
						closeTimeoutMS={100}
						isOpen={modalIsOpen}
						style={customStyles}
						overlayClassName={"Modifiers__Overlay"}
						onRequestClose={this.toggleModal}
					/>
				</InfoWrapper>
			</Container>
		);
	}
}

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		transform: "translate(-50%, -50%)",
		cursor: "auto",
		width: "100%",
		maxWidth: "375px"
	}
};

export default PageHeader;

const Container = styled.div`
	position: relative;
	max-width: 1280px;
	padding: 0 15px;
	margin: 0 auto 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const BackWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
`;

const BackText = styled(Link)`
	font-size: 22px;
	font-family: "Lato", sans-serif;
	color: #cecece;
	transition: all 0.15s ease;

	&:hover {
		text-decoration: none;
		color: #E0D5D2;
	}

	@media (max-width: 768px) {
		display: none;
	}
`;

const BackIcon = styled(Link)`
	background: url(${backIcon});
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	height: 30px;
	width: 30px;

	@media (min-width: 769px) {
		display: none;
	}
`;

const TitleWrapper = styled.div`
	flex: 2;

	@media (max-width: 576px) {
		flex: 6;
	}
`;

const Title = styled.h1`
	font-size: 36px;
	font-family: "Lato", sans-serif;
	color: #2d2d2d;
	line-height: 1.15;
	font-weight: 400;
	margin-bottom: 0;
	text-align: center;
`;

const InfoWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const InfoText = styled.div`
	font-size: 22px;
	font-family: Neucha;
	color: #cecece;
	transition: all 0.15s ease;
	cursor: pointer;

	&:hover {
		text-decoration: none;
		color: #E0D5D2;
	}

	@media (max-width: 768px) {
		display: none;
	}
`;

const InfoIcon = styled.div`
	background: url(${infoIcon});
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	height: 30px;
	width: 30px;

	@media (min-width: 769px) {
		display: none;
	}
`;
