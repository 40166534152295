/* eslint-disable */

Date.prototype._isValidDate = function() {
	return this instanceof Date && !isNaN(this);
};

Date.prototype._addDays = function(days) {
	this.setDate(this.getDate() + days);
	return this;
};

Date.prototype._getFormatted = function() {
	let day = this.getDate();
	day = day < 10 ? `0${day}` : day;
	let month = this.getMonth() + 1;
	month = month < 10 ? `0${month}` : month;
	let year = this.getFullYear();
	return `${year}-${month}-${day}`;
};

