import React from "react";
import styled, {keyframes} from "styled-components";
import {MAIN_COLOR} from "../../utils/constants";

export const Preloader = ({message, paddingLeft, paddingTop}) => {
	return (
		<PreloaderWrapper style={{paddingLeft: paddingLeft+"rem", paddingTop: paddingTop+"px"}}>
			{message && (
				<PreloaderText>Загрузка</PreloaderText>
			)}
			<PreloaderDots>
				<PreloaderDot />
				<PreloaderDot />
				<PreloaderDot />
				<PreloaderDot />
				<PreloaderDot />
			</PreloaderDots>
		</PreloaderWrapper>
	);
};

export default Preloader;

const PreloaderWrapper = styled.div`
	text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all .3s linear;
	margin: 0 auto;
`;

const PreloaderText = styled.p`
	font-family: Lato, sans-serif;
	font-size: 24px;
	color: #2d2d2d;
`;

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
`;

const PreloaderDots = styled.div`
	display: inline-flex;
`;

const PreloaderDot = styled.span`
	background: ${MAIN_COLOR};
	height: 12px;
	width: 12px;
	margin: 0 5px;
	border-radius: 50%;
	&:nth-child(1) {
		animation: ${pulse} 0.5s infinite 0.2s;
	}
	&:nth-child(2) {
		animation: ${pulse} 0.5s infinite 0.4s;
	}
	&:nth-child(3) {
		animation: ${pulse} 0.5s infinite 0.6s;
	}
	&:nth-child(4) {
		animation: ${pulse} 0.5s infinite 0.8s;
	}
	&:nth-child(5) {
		animation: ${pulse} 0.5s infinite 1s;
	}
`;
