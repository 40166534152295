import React, {useEffect, useState} from "react";
import {Header, Wrapper} from "./ModalRegStageFirst"
import styled from "styled-components";
import FormField, {Label} from "./FormField"
import {MAIN_COLOR} from "../../../../utils/constants"
import {useDispatch, useSelector} from "react-redux"
import {setAddressAdditional} from "../../../../actions/AddCompanyActions"

const ModalRegStageFirst = ({children, setRegStage, requiredFieldsCheck}) => {
  const dispatch = useDispatch()

  const additionalComponents = useSelector(store => store.addCompany.addressAdditional)

  const [addressType, setAddressType] = useState(additionalComponents.addressType || 'office')
  const [companyName, setCompanyName] = useState(additionalComponents.companyName || '')
  const [office, setOffice] = useState(additionalComponents.office || '')
  const [apartment, setApartment] = useState(additionalComponents.apartment || '')
  const [floor, setFloor] = useState(additionalComponents.floor || '')
  const [entrance, setEntrance] = useState(additionalComponents.entrance || '')
  const [comment, setComment] = useState(additionalComponents.comment || '')

  useEffect(() => {
    const newAddition = {
      addressType,
      companyName,
      office,
      apartment,
      floor,
      flat: entrance || office,
      comment,
    }

    dispatch(setAddressAdditional(newAddition))
  }, [addressType, companyName, office, apartment, entrance, floor, comment]);

  const addressStr = useSelector(store => store.addCompany.addressStr)

  return (
    <div>
      <Wrapper>
        <Header>
          {children}
        </Header>
        <SubHeader>
          {
            addressStr
          }
          <BackLink onClick={() => setRegStage(0)}>Изменить</BackLink>
        </SubHeader>
        <ButtonWrapper>
          <div style={{display: 'flex', justifyContent: 'flex-start', gap: 10, width: '100%', marginTop: -15}}>
            <SwitchButton onClick={ () => setAddressType('office') } active={ addressType === 'office' } >Работа</SwitchButton>
            <SwitchButton onClick={ () => setAddressType('home') } active={ addressType === 'home' } >Дом</SwitchButton>
          </div>
        </ButtonWrapper>
        <Form>
          {
            addressType === 'office' && (
              <FormField
                placeholder="Название вашей компании*"
                val={companyName}
                setVal={setCompanyName}
                showError={requiredFieldsCheck.companyName.errStatus}
                error={requiredFieldsCheck.companyName.message}
              />
            )
          }
          {
            addressType === 'office' && (
              <FormField
                placeholder="Номер кабинета или офиса"
                val={office}
                setVal={setOffice}
              />
            )
          }
          {
            addressType === 'home' && (
              <FormField
                placeholder="Номер квартиры"
                val={apartment}
                setVal={setApartment}
              />
            )
          }
          <FormField
            placeholder="Номер этажа"
            val={floor}
            setVal={setFloor}
          />
          <FormField
            placeholder="Номер подезда"
            val={entrance}
            setVal={setEntrance}
          />
          <FormField
            placeholder="Можете уточнить как вас лучше найти"
            type='textarea'
            val={comment}
            setVal={setComment}
          />
        </Form>
      </Wrapper>
    </div>
  )
}

export default ModalRegStageFirst;

const Form = styled.form``

const SubHeader = styled.h3`
  padding-bottom: 15px;
  display: flex;
  align-items: baseline;
  gap: 10px;

  @media (max-width: 992px) {
    gap: 5px;
    padding-bottom: 5px;
    line-height: 1em;
  }
`

const BackLink = styled.p`
  text-decoration: underline;
  color: #59a8d4;
  font-size: 1em;
  cursor: pointer;

  :hover {
    color: #8bc0e6;
  }
`

const ButtonWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;

  @media(max-width: 996px) {
    flex-direction: column;
  }
`

export const Button = styled.button`
  width: 40%;
  padding: 5px 10px;
  border: none;
  background: ${MAIN_COLOR};
  align-self: center;
  color: #FFF;
  margin-top: 10px;
  border-radius: 8px;
`

const SwitchButton = styled(Button)`
  width: 20%;
  background: ${({active}) => active ? MAIN_COLOR : '#646464'};

  @media(max-width: 996px) {
    width: 40%;
  }
`

