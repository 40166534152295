import {request} from "../utils/request";
import {deliveryCycleInfoMessage} from "./ModalActions";
import {getCompanyProfile} from "./ProfileActions";
import _ from "underscore";
import {DOWNLOAD_ORDER, getAccountsForDate} from "./MenuActions";

export const GET_INVITE_CLIENT_REQUEST = "GET_INVITE_CLIENT_REQUEST";
export const GET_INVITE_CLIENT_SUCCESS = "GET_INVITE_CLIENT_SUCCESS";
export const GET_INVITE_CLIENT_ERROR = "GET_INVITE_CLIENT_ERROR";

export const SHOW_MY_INVITES_REQUEST = "SHOW_MY_INVITES_REQUEST";
export const SHOW_MY_INVITES_SUCCESS = "SHOW_MY_INVITES_SUCCESS";
export const SHOW_MY_INVITES_ERROR = "SHOW_MY_INVITES_ERROR";

export const GET_MY_CARDS_REQUEST = "GET_MY_CARDS_REQUEST";
export const GET_MY_CARDS_SUCCESS = "GET_MY_CARDS_SUCCESS";
export const GET_MY_CARDS_ERROR = "GET_MY_CARDS_ERROR";

export const CHANGE_ACTIVE_COMPANY = "CHANGE_ACTIVE_COMPANY";
export const LOADER_PAY = "LOADER_PAY";

export const getInviteClient = (essence, companyId) => async (dispatch) => {
    let phoneValid = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    let emailValid = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
    let nameParam = '';

    if(emailValid.test(essence)){
        nameParam = 'email';
    }else if(phoneValid.test(essence)){
        nameParam = 'phone';
    }

    let response = await request("AppDinnerController", "InviteUserToCompany", {
        companyId : companyId,
        [nameParam]: essence
    });

    if (response.result === 0) {
        let errorObject = {
            service: response.service,
            method: response.method,
            code: response.data.resultCode,
            message: response.data.data.message
        };
        throw errorObject;
    } else {
        dispatch(getCompanyProfile());
        dispatch(deliveryCycleInfoMessage('send_invite'));
        dispatch({
            type: GET_INVITE_CLIENT_SUCCESS,
        });
    }
};

export const confirmInviteToCompany = (companyId) => async (dispatch) => {
    try {
        let response = await request("AppDinnerController", "ConfirmInviteToCompany", {companyId: companyId});

        if (response.result === 0) {
            let errorObject = {
                service: response.service,
                method: response.method,
                code: response.data.resultCode,
                message: response.data.data.message
            };
            throw errorObject;
        } else {
            dispatch({
                type: SHOW_MY_INVITES_SUCCESS,
                payload: response.data.invites
            });

            dispatch(getCompanyProfile());
            dispatch(deliveryCycleInfoMessage('accept_invite'));
        }
    } catch (error) {

    }
};

export const getRemoveInviteToCompany = (companyId, essence = '') => async dispatch => {
    let phoneValid = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    let emailValid = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
    let nameParam = {};
    let textInfo = '';

    if (essence !== '') {
        if(emailValid.test(essence)){
            nameParam = {
                companyId,
                email: essence
            };
        }else if(phoneValid.test(essence)){
            nameParam = {
                companyId,
                phone: essence
            };
        }

        textInfo = 'remove_invite';
    } else {
        nameParam = {
            companyId,
        };

        textInfo = 'remove_invite_from_user';
    }

    let response = await request("AppDinnerController", "RemoveInviteToCompany", {
        ...nameParam
    });

    if(response){
        dispatch(getCompanyProfile(textInfo));
        dispatch(deliveryCycleInfoMessage('remove_invite'));
    }
};

export const removeUserFromCompany = (clientId, companyId, roles) => async dispatch => {
    let message = '';

    let response = await request("AppDinnerController", "RemoveUserFromCompany", {
        clientId,
        companyId
    });

    if (response.result === 0) {
        dispatch(deliveryCycleInfoMessage(response.code + "_remove_company"));
    } else {
        switch (roles) {
            case "manager": message = 'manager_out_user';
                break;
            case "other": message = 'you_left_the_company';
                break;
        }

        dispatch(getCompanyProfile());
        dispatch(deliveryCycleInfoMessage(message));
    }
};

export const sendDetailToEmail = (companyId, dateFrom, dateTo, mailTo) => async dispatch => {
    let emailValid = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;

    if(!dateFrom){
        dispatch(deliveryCycleInfoMessage('not_date_from'));
    } else if (!dateTo) {
        dispatch(deliveryCycleInfoMessage('not_date_to'));
    } else if (!mailTo || !emailValid.test(mailTo)) {
        dispatch(deliveryCycleInfoMessage('not_valid_mail'));
    } else {
        let response = await request("Reports", "excelOrdersSumByPersonsByDates", {
            companyId,
            dateFrom,
            dateTo,
            mailTo
        });

        if (response.code === "s151") {
            dispatch(deliveryCycleInfoMessage('access_denied'));
        } else if (response.code === 0) {
            dispatch(deliveryCycleInfoMessage('not_data_in_period'));
        } else {
            dispatch(deliveryCycleInfoMessage('send_details_to_mail'));
        }
    }
};

export const removeFromCard = (cardId, accountId, paysystemId) => async dispatch => {
    await request("PaySystemService", "DeleteCard", { cardId, account: accountId, paysystem: {id: paysystemId} });

    dispatch(deliveryCycleInfoMessage('remove_from_card'));
};