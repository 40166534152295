import React, {useEffect, useRef, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import Autocomplete from "./components/Autocomplete"
import CustomMarker from "./components/CustomMarker"
import {useDispatch} from "react-redux"
import {setAddress, setAddressStr} from "../../actions/AddCompanyActions"

// имутабельный центр по дефолту
const city = JSON.parse(localStorage.getItem('currentCity'))
let defaultCoords;
if(city) {
  defaultCoords = (() => {
    switch (city.name) {
      case 'Тольятти':
        return {lat: 53.52674554361884, lng: 49.36352365938614}
      case 'Самара':
        return {lat: 53.19968310387711, lng: 50.1605702653398}
      case 'Димитровград':
        return {lat: 54.218887, lng: 49.595939}
      case 'Балаково':
        return {lat: 52.02782, lng: 47.8007}
      case 'Новокуйбышевск':
        return {lat: 53.0959, lng: 49.9462}
      case 'Сызрань':
        return {lat: 53.1585, lng: 48.4681}
      default:
        return {lat: 53.52674554361884, lng: 49.36352365938614}
    }
  })()
}




const s = {
  mainWrapper: {
    borderRadius: 8,
    background: '#fff',
  },
  autocompleteWrapper: {
    padding: 5
  },
  wrapper: {
    width: '100%',
    height: 500,
    position: 'relative',
    borderRadius: 8,
    overflow: 'hidden'
  },
  map: {
    width: "100%",
    borderRadius: 8
  },
}
const GoogleMaps = ({style, isMobile, defaultData}) => {
  const dispatch = useDispatch()
  // проверка что апи загружено в глобальный контекст
  const [googleApiIsLoad, setGoogleApiIsLoad] = useState(false)
  // изменяемые координаты центра карты
  const [coords, setCoords] = useState(defaultData.location || defaultCoords)
  // настройка зума (изменяемая)
  const [zoom, setZoom] = useState(11)
  // чекер драга карты
  const [mapDragged, setMapDragged] = useState(null)
  // устанавливается если объект места пришел с поля ввода (устанавливает начальное значение позиции нового маркера)
  const [selectedLatLng, setSelectedLatLng] = useState(null)
  // инстанс найденного объекта. Может быть установлен как с поля ввода, либо с карты путем перемещения маркера
  const [selectedAddressData, setSelectedAddressData] = useState(null)
  // сборка финального инстанса объека из парсинга найденной позиции (то что летит на срвак)
  const [addressObject, setAddressObject] = useState({
    addressComponents: defaultData.addressComponents,
    location: defaultData.location,
  })
  // строковое предстовление вышеописанного финального объекта (кинем эхту строку в поле ввода)
  const [addressString, setAddressString] = useState(null)
  // показываем бокс автокомплито только при наборе адреса вручную и скипаем если проводим манипуляции с картой
  const [showAutocompleteBox, setShowAutocompleteBox] = useState(false)

  const autocompleteRef = useRef()

  useEffect(() => {
    if(defaultData && defaultData.latLng && Object.values(defaultData.latLng).length !== 0) {
      setCoords(defaultData.latLng)
    }
    if(defaultData && defaultData.addressComponents && Object.keys(defaultData.addressComponents).length !== 0) {
      setZoom(17)
    }
  }, [defaultData])

  useEffect(() => {
    if(addressObject.addressComponents) {
      const localArea = addressObject.addressComponents.localArea
      const streetName = addressObject.addressComponents.streetName
      const houseNum = addressObject.addressComponents.houseNum

      const buildStr = `${(localArea || "")}${(streetName ? ", " + streetName + ", " : "")}${(houseNum || "")}`
      setAddressString(buildStr)
      dispatch(setAddressStr(buildStr))
    }
    dispatch(setAddress({...addressObject}))
  }, [addressObject])

  useEffect(() => {
    if(selectedAddressData) {
      setCoords(selectedAddressData.geometry.location)
      const tempObject = {}
      setAddressObject(prevState => ({...prevState, addressComponents: null, location: null}))
      tempObject.location = {
        lat: selectedAddressData.geometry.location.lat(),
        lng: selectedAddressData.geometry.location.lng()
      }
      tempObject.addressComponents = selectedAddressData.address_components.reduce((acc, el) => {
        if(el.types.includes("street_number")) {
          acc.houseNum = el.long_name || ''
        }
        if(el.types.includes("route")) {
          acc.streetName = el.long_name === 'Unnamed Road' ? '' : el.long_name
        }
        if(el.types.includes("locality")) {
          acc.localArea = el.long_name || ''
        }
        if(el.types.includes("administrative_area_level_2")) {
          acc.city = el.long_name || ''
        }
        if(el.types.includes("administrative_area_level_1")) {
          acc.macroArea = el.long_name || ''
        }
        if(el.types.includes("country")) {
          acc.country = el.long_name || ''
        }
        return acc
      }, {})
      setAddressObject(prevState => ({...prevState, ...tempObject}))
    }
  }, [selectedAddressData])

  function geocodePosition(pos)
  {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({location: pos}).then(res =>{
      setSelectedAddressData(res.results[0])
    });
  }
  // тут ясно
  const clearHandler = () => {
    setSelectedLatLng(null)
  }
  //центруем катру на точке что указал нам юзак
  useEffect(() => {
    if(selectedLatLng) {
      geocodePosition(selectedLatLng)
    }
  }, [selectedLatLng])

  //чекер апи
  useEffect(() => {
    if(window.google) {
      setGoogleApiIsLoad(true)
    }
  }, [])

  return (
    <div style={s.mainWrapper}>
      {
        googleApiIsLoad && (
          <>
            <div style={{...s.wrapper, ...style}}>
              <div style={{position: 'absolute', zIndex: 5, width: '100%', padding: 5, boxSizing: 'border-box'}}>
                <Autocomplete
                  autocompleteRef={autocompleteRef}
                  style={s.autocompleteWrapper}
                  defaultCoords={defaultCoords}
                  addressString={addressString}
                  setAddressString={setAddressString}
                  showAutocompleteBox={showAutocompleteBox}
                  setShowAutocompleteBox={setShowAutocompleteBox}
                  setSelectedAddressData={setSelectedAddressData}
                  setCoords={setCoords}
                />
              </div>
              <CustomMarker mapDragged={mapDragged}/>
              <GoogleMapReact
                options={
                  {
                    disableDefaultUI: isMobile,
                    gestureHandling: "greedy",
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                  }
                }
                style={s.map}
                defaultCenter={defaultCoords}
                defaultZoom={11}
                yesIWantToUseGoogleMapApiInternals
                center={coords}
                zoom={zoom}
                onDrag={() => {
                  clearHandler()
                  autocompleteRef.current.blur()
                  setShowAutocompleteBox(false)
                  setMapDragged(true)
                }}
                onDragEnd={(map) => {
                  setMapDragged(false)
                  setSelectedLatLng(map.getCenter())
                }}
              >
              </GoogleMapReact>
            </div>
          </>
        )
      }
    </div>
  );
};

export default GoogleMaps
