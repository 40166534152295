export const SET_ADDRESS_COMPONENTS = 'SET_ADDRESS_COMPONENTS'
export const SET_ADDRESS_POINT = 'SET_ADDRESS_POINT'
export const SET_ADDRESS_STRING = 'SET_ADDRESS_STRING'
export const SET_ADDRESS_ADDITIONAL_COMPONENTS = 'SET_ADDRESS_ADDITIONAL_COMPONENTS'
export const RESET_ADD_COMPANY = 'RESET_ADD_COMPANY'

export const setAddress = payload => ({type: SET_ADDRESS_COMPONENTS, payload})
export const setAddressPoint = payload => ({type: SET_ADDRESS_POINT, payload})
export const setAddressStr = payload => ({type: SET_ADDRESS_STRING, payload})
export const setAddressAdditional = payload => ({type: SET_ADDRESS_ADDITIONAL_COMPONENTS, payload})
export const resetAddCompany = () => ({type: RESET_ADD_COMPANY})
