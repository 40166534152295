import React, {useEffect} from "react";
import styled from "styled-components";
import ClockImg from '../../../../images/MyOrdersImg/delivery_status_in_process.png'
import DoneImg from '../../../../images/MyOrdersImg/delivery_status_done.png'

const resolveOrderStatus = (status) => {

    switch (status) {
        case '2':
            return 'Принят в работу'
        case '8388608':
            return 'В пути'
        case '67108864':
            return 'Доставлено'
        default:
            return 'Уточняется'
    }
}

const OrderHeader = ({companyOrderId, priceResult, selectOrderHandler, status, num = 0, sum = 0}) => {

    useEffect(() => {

    }, [])

    return (
        // <OrderHeaderWrapper onClick={selectOrderHandler}>
        <OrderHeaderWrapper>
            <OrderNum>
                Заказ #{companyOrderId}
            </OrderNum>
            <Status {...{status}}>
                <p>
                    {
                        resolveOrderStatus(status)
                    }
                </p>
                {status === '67108864' ? (
                    <img src={DoneImg} alt="✔" width={18} height={18} />
                ) : (
                    <img src={ClockImg} alt="⌛" width={18} height={18} />
                )}
            </Status>
            <OrderSum>
                {priceResult}р
            </OrderSum>
        </OrderHeaderWrapper>
    )
}

export default OrderHeader;

const OrderHeaderWrapper = styled.div`
    background: #F2F3F5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px 8px 0 0;
    transition: background-color .2s ease;
    /*cursor: pointer;*/
`
const Status = styled.div`
    /*background: ${({status}) => status !== '67108864' ? '#B9B3C7' : '#67B8EE'};*/
    /*border-radius: 30px;*/
    display: flex;
    align-items: center;

    > p {
        /*padding: 4px 16px;*/
        margin: 0 8px 0 0;
        font-family: "SF Display", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        /*letter-spacing: -0.442px;*/
        /*color: #FFFFFF;*/
        color: ${({status}) => status === '67108864' ? '#54933F' : '#A4A4A4'};
        
        @media(max-width: 768px) {
          font-size: 14px;
          line-height: 18px;
        }
    }
`
const OrderNum = styled.p`
    /*padding: 24px;*/
    padding: 28px 16px 28px 32px;
    margin: 0;
    font-family: "SF Display", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
    
    @media(max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
      padding: 24px 16px;
    }
`
const OrderSum = styled(OrderNum)`
    margin: 0 0 0 auto;
    font-weight: 500;
`
