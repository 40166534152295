import {
	GET_CITIES_REQUEST,
	GET_CITIES_SUCCESS,
	GET_CITIES_ERROR,
	GET_RESTAURANTS_REQUEST,
	GET_RESTAURANTS_SUCCESS,
	GET_RESTAURANTS_ERROR,
    GET_STATISTICS_REQUEST,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_ERROR,
} from "../actions/MainActions";

const initialState = {
	cities: [],
	restaurants: [],
	isLoaded: false,
	error: "",
	statistic: []
};

export function mainReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CITIES_REQUEST:
			return {
				...state,
				cities: [],
				isLoaded: false
			};

		case GET_CITIES_SUCCESS:
			return {
				...state,
				cities: action.payload,
				isLoaded: true
			};

		case GET_CITIES_ERROR:
			return {
				...state,
				error: action.payload,
				isLoaded: true
			};

		case GET_RESTAURANTS_REQUEST:
			return {
				...state,
				restaurants: [],
				error: "",
				isLoaded: false
			};

		case GET_RESTAURANTS_SUCCESS:
			return {
				...state,
				restaurants: action.payload,
				isLoaded: true
			};

		case GET_RESTAURANTS_ERROR:
			return {
				...state,
				error: action.payload,
				isLoaded: true
			};

        case GET_STATISTICS_REQUEST:
            return {
                ...state,
                statistic: [],
                error: "",
                isLoaded: false
            };

        case GET_STATISTICS_SUCCESS:
            return {
                ...state,
                statistic: action.payload,
                isLoaded: true
            };

        case GET_STATISTICS_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoaded: true
            };

		default:
			return state;
	}
}
