import {
    ERROR_CANCEL_ORDER, RESET_MODAL,
    START_CANCEL_ORDER,
    SUCCESS_CANCEL_ORDER,
    TOGGLE_CANCEL_MODAL
} from "../actions/MyOrdersActions";

const initialState = {
    cancelOrderModalIsOpen: false,
    cancelOrder: null,
    startCanceling: false,
    orderDeleteSuccess: false,
    orderDeleteError: '',
}

export function myOrdersReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_CANCEL_MODAL:
            return {
                ...state,
                cancelOrderModalIsOpen: !state.cancelOrderModalIsOpen,
                cancelOrder: state.cancelOrderModalIsOpen ? null : action.payload,
            };
        case START_CANCEL_ORDER:
            return {
                ...state,
                startCanceling: true
            }
        case SUCCESS_CANCEL_ORDER:
            return {
                ...state,
                orderDeleteSuccess: true,
                startCanceling: false,
            }
        case ERROR_CANCEL_ORDER:
            return {
                ...state,
                orderDeleteSuccess: false,
                startCanceling: false,
                orderDeleteError: action.payload,
            }
        case RESET_MODAL:
            return {
                ...state,
                orderDeleteSuccess: false,
                startCanceling: false,
                orderDeleteError: false,
            }
        default:
            return state
    }
}