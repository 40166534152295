import React, {useState} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import styled from "styled-components";
import _ from "underscore";
import {MAIN_COLOR, IS_51_OBED} from "../../../utils/constants";
import removeCard from "../../../images/order-trash.svg";

const parsingNumberCard = (numCard, newDesign = false) => {
    let arrString = [];

    for (var i = 0; i < numCard.length; i+=4) {
        arrString.push(numCard.slice(i, i + 4));
    }

    if (newDesign) {
        arrString[1] = arrString[2] = "****"
    }

    return arrString;
};

export const RadioButtonsGroup = (props) => {
    const [value, setValue] = useState(props.isMobile ? '' : 'newCard');

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleRadio = (event, v) => {
        setValue(v.id);
    }

    const submitButton = (e) => {
        e.preventDefault();

        if (value === "newCard") {
            props.payCards(props.orderId)
        } else {
            props.payCards(props.orderId, value)
        }

        props.toggleModal();
    };

    const cancelButton = (e) => {
        e.preventDefault();

        props.toggleModal()
    };

    console.log(props.cards, 'cards')

    return (
        props.isMobile ? (
                <CardsForm>
                    <CardsContainer>
                        {props.cards && props.cards.map((v, i) => {
                            return (
                                <CardItem key={v.id} onClick={(e) => handleRadio(e, v)}>
                                    <CustomInputBox>
                                        <CustomInput style={{backgroundColor: value === v.id ? MAIN_COLOR : '#FFF'}} />
                                    </CustomInputBox>
                                    <CardInfo>
                                        {parsingNumberCard(v.name, true).join(' ')}
                                    </CardInfo>
                                    <RemoveButton onClick={() => props.removeFromCard(v.id, props.restId, v.paysystemId)}>
                                        <img src={removeCard} alt="Удалить карту"/>
                                    </RemoveButton>
                                </CardItem>
                            )
                        })}
                        <CardItem key={'newCard'} onClick={() => setValue("newCard")}>
                            <CustomInputBox>
                                <CustomInput style={{backgroundColor: value === "newCard" ? MAIN_COLOR : '#FFF'}} />
                            </CustomInputBox>
                            <CardInfo>
                                Новая карта
                            </CardInfo>
                        </CardItem>
                    </CardsContainer>
                    <ModalFooter>
                        <SubmitCard
                            onClick={(e)=>{submitButton(e); props.setOpenPopover(false)}}
                            disabled={!value}
                            style={{backgroundColor: !value ? '#ddd' : `${MAIN_COLOR}`}}
                        >
                            <p style={{margin: 0, color: !value ? '#999999' : `#ffffff`}}>
                                {value ? `Оплатить ${parseInt(props.priceResult)} р.` : `Выберите карту`}
                            </p>
                        </SubmitCard>
                    </ModalFooter>
                </CardsForm>
            ) : (
                <FormControl
                    component="fieldset"
                    classes={{root: 'fieldset-classes'}}
                    style={{width: '100%'}}
                >
                    <Form component="legend">Оплата</Form>
                    <RadioGroup aria-label="pay" name="pay1" value={value} onChange={handleChange}>
                        {_.map(props.cards, (card, key) => (
                            <CardItem key={key}>
                                <FormControlLabel
                                    value={card.id}
                                    control={<Radio />}
                                    //label={parsingNumberCard(card.name).join(' ')}
                                    label={card.name}
                                />
                                <RemoveCard onClick={() => props.removeFromCard(card.id, props.restId, card.paysystemId)}>Удалить карту</RemoveCard>
                            </CardItem>
                        ))}
                        <FormControlLabel value="newCard" control={<Radio />} label="Новая карта..." />
                    </RadioGroup>
                    <ControlButton>
                        <Cancel onClick={(e)=>{cancelButton(e); props.setOpenPopover(false)}}>Назад</Cancel>
                        <Pay onClick={(e)=>{submitButton(e); props.setOpenPopover(false)}}>{`Оплатить ${parseInt(props.priceResult)} р.`}</Pay>
                    </ControlButton>
                </FormControl>
            )
    );
};

const Form = styled(FormLabel)`
    font-family: 'Lato' !important;
    font-weight: normal !important;
    font-size: 28px !important;
    color: #263238 !important;
    margin-bottom: 28px !important;
`;

const ControlButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    
    @media(max-width: 992px){
        flex-direction: column;
    }
`;

const Cancel = styled.button`
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 6px;
    background: #fff;
    font-family: Lato;
    font-size: 16px;
    color: #333333;
    padding: 8px 42px;
    
    @media(max-width: 992px){
        margin-bottom: 12px;
    }
`;

const Pay = styled(Cancel)`
    border: ${IS_51_OBED ? '1px solid #32CD32' : '1px solid #FF1744'};
    background: ${IS_51_OBED ? '#32CD32' : '#FF1744'};
    color: #fff;
    padding: 8px 62px;
`;

const CardItem = styled.div`
    display: flex;
    
    @media (max-width: 992px) {
        position: relative;
        align-items: center;
        margin-bottom: 20px;
    }
`;

const RemoveCard = styled.p`
    font-family: "Roboto", sans-serif;
    color: #ff1744;
    margin: 9px 0 0 10px;
    cursor: pointer;
    
    &:hover {
        text-decoration: underline;
    }
`;

const CustomInputBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin-right: 12px;
    border-radius: 66px;
    border: 1px solid ${MAIN_COLOR};
`;

const CustomInput = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 66px;
    background: #fff;
    cursor: pointer;
`;

const CardInfo = styled.p`
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    color: #333;
    cursor: pointer;
    
    @media (max-width: 992px) {
        width: calc(100% - 34px);
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: #333333;
        margin: 0;
    }
`;

const CardsForm = styled.div`
    position: static;
    height: calc(100% - 50px);
    padding-bottom: 60px;
    overflow-y: scroll;
`;

const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 42px 23px 0;
`;

const RemoveButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
`;

const ModalFooter = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 9px 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
`;

const SubmitCard = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 390px;
    height: 42px;
    background: ${MAIN_COLOR};
    border-radius: 666px;
    border: 0;
    outline: 0;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
`;
