import React, {Fragment, useEffect, useState} from 'react';
import Modal from "react-modal";
import noimage from "../../../images/noimage.jpg";
import pagination from "../../../images/drawer-navigation-confidence.svg";
import styled from "styled-components";
import RestoranInfoModal from "../RestoranInfoModal";
import {_} from "underscore";
import AddModalCompany from "../AddModalCompany";
import AuthModal from "../AuthModal";
import closeModal from "../../../images/close-nav-cross.svg";
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";
import {useDispatch} from "react-redux";
import {IS_51_OBED} from '../../../utils/constants';

const { detect } = require('detect-browser');
const browser = detect();

const SetPropertyVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export const DetailViewProduct = ({
                                      image,
                                      rest,
                                      order,
                                      remainCountProduct,
                                      isPeriodOver,
                                      time,
                                      isMobile,
                                      regOrders,
                                      modified,
                                      objectProduct,
                                      removeFromCart,
                                      addToCart,
                                      isAuth,
                                      companies,
                                      saveCompany,
                                      handleReduxFormEditCompany,
                                      valueEditCompany,
                                      cities,
                                      handleReduxFormAuth,
                                      profile,
                                      codeSent,
                                      phoneError,
                                      sendCodeToPhone,
                                      createTokenBySession,
                                      errorMessage,
                                      valueAuthEmail,
                                      isToken,
                                      createTokenByPassword,
                                      sendActivationMail,
                                      products,
                                      arrayProduct,
                                      item_order,
                                      isShowDetail,
                                      toHideDetail,
}) => {
    const[modalIsOpen, toggleModal] = useState(false);
    const[modalRestInfoIsOpen, toggleModalRestInfo] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = "hidden";
            if (_.size(regOrders) !== 0 && isMobile) {
                dispatch(deliveryCycleInfoMessage('you_have_an_order', null, 2500, true));
            }
        } else {
            document.body.style.overflowX = "visible";
            document.body.style.overflowY = "scroll";
            toHideDetail();
        }
    }, [modalIsOpen]);

    useEffect(() => {
        if (isShowDetail) {
            toggleModal(true)
        }
    }, [isShowDetail]);

    useEffect(() => {
        if (modalIsOpen) {
            SetPropertyVh()
            window.addEventListener('resize', SetPropertyVh);
        } else {
            window.removeEventListener('resize', SetPropertyVh);
        }
    }, [modalIsOpen]);

    useEffect(() => {
        return () => {
            window.removeEventListener('resize', SetPropertyVh);
        };
    }, [])

    const notPropagationRestInfo = (e) => {
        e.stopPropagation();

        toggleModalRestInfo(!modalRestInfoIsOpen);
    };

    const notPropagationModalIsOpen = (e) => {
        e.stopPropagation();

        toggleModal(!modalIsOpen)
    };

    return (
        <Fragment>
            <WrapperImage onClick={()=>{toggleModal(!modalIsOpen)}} style={{width: '100%'}}>
                <Image src={image ? image.source : noimage} />
                <RestoranInfoModal
                    logo={rest ? "https://api.menuforme.online/" + rest.logo : ""}
                    rest={rest}
                    modalRestInfoIsOpen={modalRestInfoIsOpen}
                    toggleModalRestInfo={notPropagationRestInfo}
                />
                {(order.amountForOrder !== null && remainCountProduct <= 5 && !isPeriodOver || (isPeriodOver && time !== -1)) && (
                    <RemainCountProduct
                        status={
                            (isPeriodOver && time !== -1) ? '-1' :
                                remainCountProduct === 0 || remainCountProduct < 0 ? '0' : '1'
                        }
                    >
                        {
                            isMobile ? (
                                (isPeriodOver && time !== -1) ? "Заказы завершены" :
                                    remainCountProduct === 0 || remainCountProduct < 0 ? "закончилось" : "осталось: " + remainCountProduct + " шт."
                            ) : (
                                (isPeriodOver && time !== -1) ? "Заказ на это блюдо уже не принимается" :
                                    remainCountProduct === 0 || remainCountProduct < 0 ? "Закончилось" : "Осталось: " + remainCountProduct + " шт."
                            )
                        }
                    </RemainCountProduct>
                )}
            </WrapperImage>
            <Modal
                closeTimeoutMS={100}
                style={isMobile ? customStylesMobile : customStyles}
                overlayClassName={"Modifiers__Overlay"}
                isOpen={modalIsOpen}
                onRequestClose={(e)=>{notPropagationModalIsOpen(e)}}
            >
                <ModalWrapper>
                    <WrapperImage modal>
                        <ModalImage src={image ? image.source : noimage}/>
                        {isMobile ? (
                            <CloseDetailView onClick={(e) => notPropagationModalIsOpen(e)}>
                                <img src={closeModal} alt="x"/>
                            </CloseDetailView>
                        ) : (
                            <LogoRest src={rest ? "https://api.menuforme.online/" + rest.logo : ""}/>
                        )}
                    </WrapperImage>
                    <WrapperInfo browserName={browser.name}>
                        <NameProd modified={_.size(modified) !== 0}>
                            <Name>{order.name}</Name>
                            {isMobile ? (
                                <Fragment>
                                    {
                                        ((time !== -1 && !isPeriodOver && _.size(regOrders) === 0)) && (
                                            (_.size(modified) === 0 && !objectProduct) ? (
                                                isAuth && _.size(companies) === 0 ? (
                                                    <AddModalCompany
                                                        companyData={companies}
                                                        saveCompany={saveCompany}
                                                        onChange={handleReduxFormEditCompany}
                                                        valueEditCompany={valueEditCompany}
                                                        cities={cities}
                                                        button={<AddButtonPrice>{parseFloat(order.price)} &#8381;</AddButtonPrice>}
                                                    />
                                                ) : (
                                                    isAuth && _.size(companies) !== 0 ? (
                                                        order.amountForOrder !== null && remainCountProduct <= 5 ? (
                                                            remainCountProduct !== 0 ? (
                                                                <AddButtonPrice onClick={()=>{addToCart(order)}}>{parseFloat(order.price)} &#8381;</AddButtonPrice>
                                                            ) : (
                                                                <AddButtonPrice disabled>{parseFloat(order.price)} &#8381;</AddButtonPrice>
                                                            )
                                                        ) : (
                                                            <AddButtonPrice onClick={()=>{addToCart(order)}}>{parseFloat(order.price)} &#8381;</AddButtonPrice>
                                                        )
                                                    ) : (
                                                        <AuthModal
                                                            onChange={handleReduxFormAuth}
                                                            profile={profile}
                                                            codeSent={codeSent}
                                                            phoneError={phoneError}
                                                            sendCodeToPhone={sendCodeToPhone}
                                                            createTokenBySession={createTokenBySession}
                                                            errorMessage={errorMessage}
                                                            valueAuthEmail={valueAuthEmail}
                                                            buttonFront={<AddButtonPrice>{parseFloat(order.price)} &#8381;</AddButtonPrice>}
                                                            isToken={isToken}
                                                            createTokenByPassword={createTokenByPassword}
                                                            sendActivationMail={sendActivationMail}
                                                        />
                                                    )
                                                )
                                            ) :
                                            (_.size(modified) === 0 && objectProduct) ? (
                                                <ItemListCount>
                                                    <Fragment>
                                                        <ItemListClickArea onClick={()=>{removeFromCart(order, objectProduct.amount === 1)}}>
                                                            <ItemListBtn>
                                                                -
                                                            </ItemListBtn>
                                                        </ItemListClickArea>
                                                        <ItemListCounter>{objectProduct ? objectProduct.amount : 1}</ItemListCounter>
                                                    </Fragment>
                                                    {order.amountForOrder !== null && remainCountProduct <= 5
                                                        ? remainCountProduct !== 0
                                                            ? (
                                                            <ItemListClickArea onClick={()=>{addToCart(order, false)}}>
                                                                <ItemListBtn>+</ItemListBtn>
                                                            </ItemListClickArea>
                                                        ) : (
                                                            <ItemListClickArea>
                                                                <ItemListBtnDisabled>+</ItemListBtnDisabled>
                                                            </ItemListClickArea>
                                                        ) : (
                                                        <ItemListClickArea onClick={()=>{addToCart(order, false)}}>
                                                            <ItemListBtn>+</ItemListBtn>
                                                        </ItemListClickArea>
                                                    )}
                                                </ItemListCount>
                                            ) : (
                                                !products && (
                                                    _.map(_.sortBy(_.uniq(modified), 'price'), (item, key) => {
                                                        let modificated = arrayProduct ? _.find(arrayProduct, (order)=>{return order.modifiers[0].id == item.id}) : false;
                                                        var modifiersProductCount = _.find(item_order, (obj_item)=>{
                                                            if(obj_item.modifiers.length){
                                                                return obj_item.modifiers[0].id === item.id && obj_item.id === order.id
                                                            }
                                                        });

                                                        let newArrayProduct = {...order};
                                                        newArrayProduct['price'] = String(parseFloat(order.price));
                                                        newArrayProduct['image'] = image ? image.source : null;
                                                        newArrayProduct['modifiers'] = [{
                                                            id: item.id,
                                                            name: item.name,
                                                            amount: 1,
                                                            price: item.price,
                                                        }];
                                                        newArrayProduct['amount'] = modificated ? modificated.amount : null;

                                                        return (
                                                            <WrapperControl key={key}>
                                                                <Modified>
                                                                    <NameModified>
                                                                        {item.name}
                                                                    </NameModified>
                                                                </Modified>

                                                                {!modificated ? (
                                                                    isAuth && _.size(companies) === 0 ? (
                                                                        <AddModalCompany
                                                                            companyData={companies}
                                                                            saveCompany={saveCompany}
                                                                            onChange={handleReduxFormEditCompany}
                                                                            valueEditCompany={valueEditCompany}
                                                                            cities={cities}
                                                                            button={<AddButtonPrice products={!products}>{parseFloat(item.price) + parseFloat(order.price)} &#8381;</AddButtonPrice>}
                                                                        />
                                                                    ) : (
                                                                        isAuth && _.size(companies) !== 0 ? (
                                                                            order.amountForOrder !== null && remainCountProduct <= 5 ? (
                                                                                remainCountProduct !== 0 ? (
                                                                                    <AddButtonPrice products={!products} onClick={()=>{addToCart(newArrayProduct)}}>{parseFloat(item.price) + parseFloat(order.price)} &#8381;</AddButtonPrice>
                                                                                ) : (
                                                                                    <AddButtonPrice products={!products} disabled>{parseFloat(item.price) + parseFloat(order.price)} &#8381;</AddButtonPrice>
                                                                                )
                                                                            ) : (
                                                                                <AddButtonPrice products={!products} onClick={()=>{addToCart(newArrayProduct)}}>{parseFloat(item.price) + parseFloat(order.price)} &#8381;</AddButtonPrice>
                                                                            )
                                                                        ) : (
                                                                            <AuthModal
                                                                                onChange={handleReduxFormAuth}
                                                                                profile={profile}
                                                                                codeSent={codeSent}
                                                                                phoneError={phoneError}
                                                                                sendCodeToPhone={sendCodeToPhone}
                                                                                createTokenBySession={createTokenBySession}
                                                                                errorMessage={errorMessage}
                                                                                valueAuthEmail={valueAuthEmail}
                                                                                buttonFront={<AddButtonPrice products={!products}>{parseFloat(item.price) + parseFloat(order.price)} &#8381;</AddButtonPrice>}
                                                                                isToken={isToken}
                                                                                createTokenByPassword={createTokenByPassword}
                                                                                sendActivationMail={sendActivationMail}
                                                                            />
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <ItemListCount>
                                                                        <Fragment>
                                                                            <ItemListClickArea onClick={()=>{removeFromCart(newArrayProduct, modifiersProductCount.amount === 1)}}>
                                                                                <ItemListBtn>
                                                                                    -
                                                                                </ItemListBtn>
                                                                            </ItemListClickArea>
                                                                            <ItemListCounter>{modifiersProductCount && modifiersProductCount.amount}</ItemListCounter>
                                                                        </Fragment>
                                                                        {order.amountForOrder !== null && remainCountProduct <= 5
                                                                            ? remainCountProduct !== 0
                                                                                ? (
                                                                                <ItemListClickArea onClick={()=>{addToCart(newArrayProduct, false)}}>
                                                                                    <ItemListBtn>+</ItemListBtn>
                                                                                </ItemListClickArea>
                                                                            ) : (
                                                                                <ItemListClickArea>
                                                                                    <ItemListBtnDisabled>+</ItemListBtnDisabled>
                                                                                </ItemListClickArea>
                                                                            ) : (
                                                                            <ItemListClickArea onClick={()=>{addToCart(newArrayProduct, false)}}>
                                                                                <ItemListBtn>+</ItemListBtn>
                                                                            </ItemListClickArea>
                                                                        )}
                                                                    </ItemListCount>
                                                                )}
                                                            </WrapperControl>
                                                        )
                                                    })
                                                )
                                            )
                                        )
                                    }
                                </Fragment>
                            ) : (
                                <Weight>
                                    {parseInt(order.weight) !== 0 ? parseInt(order.weight) + " гр." : ""}<Pagination src={pagination}/>
                                </Weight>
                            )}
                        </NameProd>
                        {isMobile ? (
                            <Fragment>
                                <Br/>
                                <DescProd>{order.description}</DescProd>
                                <Br/>
                                <ProdStructure>
                                    {+order.energy ? <ProdStructureText>Калории ⋅ {+order.energy}</ProdStructureText> : false}
                                    {+order.fibers ? <ProdStructureText>Белки ⋅ {+order.fibers}</ProdStructureText> : false}
                                    {+order.fats ? <ProdStructureText>Жиры ⋅ {+order.fats}</ProdStructureText> : false}
                                    {+order.carbs ? <ProdStructureText>Углеводы ⋅ {+order.carbs}</ProdStructureText> : false}
                                </ProdStructure>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <DescProd>{order.description}</DescProd>
                                <PriceModified>{parseFloat(order.price)} &#8381;</PriceModified>
                            </Fragment>
                        )}
                    </WrapperInfo>
                </ModalWrapper>
            </Modal>
        </Fragment>
    );
};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "15px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "585px"
    }
};

const customStylesMobile = {
    content: {
        top: "auto",
        left: 0,
        right: 0,
        bottom: 0,
        transform: "initial",
        padding: 0,
        backgroundColor: "transparent",
        border: "none",
        borderRadius: 0,
        cursor: "auto",
        width: "100%",
    }
};

const ModalWrapper = styled.div`
	padding: 25px;
	background: #fff;
	border-radius: 4px;
	
	@media (max-width: 992px) {
	    padding: 0;
        border-radius: 22px 22px 0 0;
        max-height: calc(var(--vh, 1vh) * 100);
	}
`;

const WrapperImage = styled.div`
	position: relative;
	cursor: pointer;
	transition: 2s linear;
	
	@media(max-width: 992px){
      width: 100%;
      height: ${({modal}) => modal ? '63.33vw' : 'auto'};
    }
`;

const Image = styled.img`
	width: 100%;
	border-radius: 3px;
    object-fit: cover;
    height: 272px;
    
    @media(max-width: 992px){
      height: calc((100vw - 36px) / 2);
      border-radius: 10px;
    }
`;

const RemainCountProduct = styled.span`
    height: 20px;
    min-width: 100px;
    background: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    border-top-left-radius: 6px;
    
    color: ${({status}) => status === '-1' ? '#9c9c9c' : status === '0' ? 'red' : '#9c9c9c'};
    
    @media (max-width: 992px) {
        height: 17px;
        min-width: auto;
        right: 4px;
        bottom: 4px;
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #ffffff;
        padding: 0 8px;
        border-radius: 66px;
        
        background: ${({status}) => status === '-1' ? '#bbbbbb' : status === '0' ? '#bbbbbb' : '#222222'};
        color: ${({status}) => status === '-1' ? '#222222' : status === '0' ? '#222222' : '#ffffff'};
    }
`;

const WrapperInfo = styled.div`
	width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 25px;
    
    @media(max-width: 992px){
        display: block;
        padding: 20px 16px 0 16px;
        margin-bottom: 0px;
        max-height: ${({browserName}) => browserName === 'ios' ? 'calc(var(--vh, 1vh) * 100 - 63.33vw)' : 'calc(100vh - 63.33vw)'};
        overflow: scroll;
    }
`;

const NameProd = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	margin-top: 15px;
	flex-direction: ${({modified}) => modified ? "column" : "row"};
	
	@media(max-width: 992px){
	    justify-content: space-between;
        margin-top: 0;
        margin-bottom: 10px;
    }
`;

const Name = styled.span`
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: left;
	margin-right: 10px;
	
	@media(max-width: 992px){
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #333333;
        
        width: calc(100vw - 123px);
        margin-right: 10px;
    }
`;

const PriceModified = styled.div`
    font-family: "SF Display";
	font-weight: 600;
	font-size: 20px;
	line-height: 19px;
	color: #263238;
	white-space: nowrap;
`;

const LogoRest = styled.img`
	position: absolute;
	left: 5px;
	bottom: 5px;
	width: 40px;
`;

const ModalImage = styled.img`
	width: 100%;
	object-fit: cover;
	border-radius: 3px;
	
	@media(max-width: 992px){
        object-position: center;
        border-radius: 22px 22px 0 0;
        height: 100%;
    }
`;

const Weight = styled.span`
	font-weight: normal;
	font-size: 12px;
	color: #CFD8DC;
	white-space: nowrap;
	flex-grow: 1;
`;

const Pagination = styled.img`
	cursor: pointer;
	margin-top: -4px;
`;

const DescProd = styled.p`
	width: 100%;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #999999;
	text-align: left;
	overflow: hidden;
	height: 35px;
	
	@media (max-width: 992px) {
        font-family: "Roboto", sans-serif;
        font-size: 13px;
        line-height: 15px;
        color: #333333;
        height: auto;
        margin: 20px 0;
	}
`;

const Br = styled.div`
    width: 100%;
    height: 1px;
    background: #eeeeee;
`

const ProdStructure = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
`;

const ProdStructureText = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    margin-right: 16px;
`;

const AddButtonPrice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 28px;
    margin-top: 8px;
    margin-bottom: 8px;
    background: ${({disabled}) => disabled ? "#E0D5D2" : IS_51_OBED ? '#32CD32' : "linear-gradient(0deg, #FF1744, #FF1744), linear-gradient(180deg, #E57373 0%, #EF5350 100%)"};
    border-radius: 66px;
    
    font-family: "SF Display", sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
`;

const ItemListCount = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: -10px;
`;

const ItemListBtn = styled.span`
	height: 24px;
	width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-size: 25px;
    background: #F1EDEC;
	color: #263238;;
	transition: all 0.15s ease;
	user-select: none;
`;

const ItemListClickArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
`;

const ItemListCounter = styled.span`
	width: 23px;
	text-align: center;
	color: #263238;
	font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    margin-left: -5px;
    margin-right: -5px;
`;

const ItemListBtnDisabled = styled(ItemListBtn)`
    color: #bebebe;
    border: 1px solid #bebebe;
    opacity: 0.5;
`;

const WrapperControl = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
	margin-top: 10px;
`;

const Modified = styled.div`
    width: calc(100% - 90px);
`;

const NameModified = styled.div`
	font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #263238;
`;

const CloseDetailView = styled.div`
    position: absolute;
    top: 14px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
`
