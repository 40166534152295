import React, {useState} from "react";
import {_} from "underscore";
import AuthModal from "../AuthModal";
import styled from "styled-components";
import Modal from "react-modal";
import noimage from "../../../images/noimage.jpg";
import {CSSTransition} from "react-transition-group";
import {BOLD_FONT, MAIN_COLOR, NORMAL_FONT, TXT_COLOR} from "../../../utils/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";
import AddModalCompany from "../AddModalCompany";

Modal.setAppElement("#root");

export const ModifiedProduct = ({
                                    key,
                                    removeFromCart,
                                    isAuth,
                                    order,
                                    handleReduxFormAuth,
                                    profile,
                                    codeSent,
                                    phoneError,
                                    sendCodeToPhone,
                                    createTokenBySession,
                                    errorMessage,
                                    valueAuthEmail,
                                    addToCart,
                                    image,
                                    rest,
                                    modified,
                                    arrayProduct,
                                    item_order,
                                    time,
                                    products,
                                    editWidget,
                                    basketPosition,
                                    isToken,
                                    createTokenByPassword,
                                    sendActivationMail,
                                    companies,
                                    saveCompany,
                                    handleReduxFormEditCompany,
                                    valueEditCompany,
                                    cities,
                                    isPeriodOver,
                                }) => {
    const [openModal, closeModalModifier] = useState(false);
    const [openNutritional, toggleOpenNutritional] = useState(false);

    let countRemainProduct = 0;

    _.map(item_order, (item_id)=>{
        if (item_id.id === order.id) {
            countRemainProduct += item_id.amount;
        }
    });

    let remainCountProduct = parseInt(order.amountPerOrder) - parseInt(countRemainProduct || 0);

    return (
        <Container key={key}>
            <PriceCount>{parseInt(order.price)} р.</PriceCount>
            {isAuth && _.size(companies) === 0 ? (
                <AddModalCompany
                    companyData={companies}
                    saveCompany={saveCompany}
                    onChange={handleReduxFormEditCompany}
                    valueEditCompany={valueEditCompany}
                    cities={cities}
                    button={<ButtonAdd>Выбрать</ButtonAdd>}
                />
            ) : !isAuth ? (
                <AuthModal
                    isAuth={isAuth}
                    onChange={handleReduxFormAuth}
                    profile={profile}
                    codeSent={codeSent}
                    phoneError={phoneError}
                    sendCodeToPhone={sendCodeToPhone}
                    createTokenBySession={createTokenBySession}
                    errorMessage={errorMessage}
                    valueAuthEmail={valueAuthEmail}
                    buttonFront={<ButtonAdd>Выбрать</ButtonAdd>}
                    isToken={isToken}
                    createTokenByPassword={createTokenByPassword}
                    sendActivationMail={sendActivationMail}
                    closeModalModifier={closeModalModifier}
                />
            ) : (
                <ButtonAdd onClick={()=>{closeModalModifier(!openModal)}}>{basketPosition ? 'Добавить ещё' : 'Выбрать'}</ButtonAdd>
            )}
            <Modal
                closeTimeoutMS={100}
                isOpen={openModal}
                className={"Modifiers__Modal"}
                overlayClassName={"Modifiers__Overlay"}
                onRequestClose={()=>{closeModalModifier(!openModal)}}>
                <ModalWrapper>
                    <CloseX className="fas fa-times" onClick={()=>{closeModalModifier(!openModal)}}/>
                    <Wrapper>
                        <WrapperImage>
                            <Image src={image ? image.source : noimage} />
                            <LogoRest src={rest ? "https://api.menuforme.online/" + rest.logo : ""}/>
                            {order.amountForOrder !== null && remainCountProduct <= 5 && (
                                <RemainCountProduct style={remainCountProduct === 0 || remainCountProduct < 0 ? {color: 'red'} : {color: '#9c9c9c'} }>
                                    {remainCountProduct === 0 || remainCountProduct < 0 ? "Закончилось" : "Осталось: " + remainCountProduct + " шт."}
                                </RemainCountProduct>
                            )}
                        </WrapperImage>
                        <WrapperInfo>
                            <DescProd>{order.description}</DescProd>
                        </WrapperInfo>
                        <NutritionalValue>
                            <NutritionalValueTitle onClick={()=>{toggleOpenNutritional(!openNutritional)}}>
                                <NutritionalTitle>
                                    Пищевая ценность на 100 г
                                </NutritionalTitle>
                                <IconDirection icon={faAngleDown} directionnutr={`${openNutritional}`}/>
                            </NutritionalValueTitle>
                            <CSSTransition in={openNutritional} timeout={300} classNames="nitritional" unmountOnExit>
                                {()=><Nutritionals>
                                    <CellNitritionalValue>
                                        <NameNutritionalValue>Энерг. ценность:</NameNutritionalValue>
                                        <CountNutritionalValue>{parseInt(order.energy)} ккал.</CountNutritionalValue>
                                    </CellNitritionalValue>
                                    <CellNitritionalValue>
                                        <NameNutritionalValue>Белки:</NameNutritionalValue>
                                        <CountNutritionalValue>{parseInt(order.fibers)} гр.</CountNutritionalValue>
                                    </CellNitritionalValue>
                                    <CellNitritionalValue>
                                        <NameNutritionalValue>Жиры:</NameNutritionalValue>
                                        <CountNutritionalValue>{parseInt(order.fats)} гр.</CountNutritionalValue>
                                    </CellNitritionalValue>
                                    <CellNitritionalValue>
                                        <NameNutritionalValue>Углеводы:</NameNutritionalValue>
                                        <CountNutritionalValue>{parseInt(order.carbs)} гр.</CountNutritionalValue>
                                    </CellNitritionalValue>
                                    <CellNitritionalValue>
                                        <NameNutritionalValue>Вес:</NameNutritionalValue>
                                        <CountNutritionalValue>{parseInt(order.weight)} гр.</CountNutritionalValue>
                                    </CellNitritionalValue>
                                </Nutritionals>}
                            </CSSTransition>
                        </NutritionalValue>
                    </Wrapper>
                    <WrapperModified>
                        {((time !== -1 && !isPeriodOver && !products &&  _.size(modified) !== 0) || editWidget &&  _.size(modified) !== 0) && (
                            _.map(_.sortBy(_.uniq(modified), 'price'), (item, key) => {
                                let modificated = arrayProduct ? _.find(arrayProduct, (order)=>{return order.modifiers[0].id == item.id}) : false;
                                var modifiersProductCount = _.find(item_order, (obj_item)=>{
                                    if(obj_item.modifiers.length){
                                        return obj_item.modifiers[0].id === item.id && obj_item.id === order.id
                                    }
                                });

                                let newArrayProduct = {...order};
                                newArrayProduct['price'] = String(parseFloat(order.price));
                                newArrayProduct['image'] = image ? image.source : null;
                                newArrayProduct['modifiers'] = [{
                                    id: item.id,
                                    name: item.name,
                                    amount: 1,
                                    price: item.price,
                                }];
                                newArrayProduct['amount'] = modificated ? modificated.amount : null;

                                let isAvailableToOrder = !!+item.amountForOrder ? !!item.amountPerOrder : true

                                return(
                                    <WrapperControl key={key}>
                                        <Modified>
                                            <NameModified>
                                                {item.name.indexOf('гарнира') !== -1 ? order.name : order.name + " " + item.name}
                                            </NameModified>
                                        </Modified>
                                        <ItemListCount>
                                            <PriceModified>{parseFloat(item.price) + parseFloat(order.price)} &#8381;</PriceModified>
                                            <PanelModified>
                                                <CSSTransition timeout={1000} classNames='addMod' key={item.id + key} in={modificated ? true : false} unmountOnExit>
                                                    {()=> <PanelRemoveCountProduct>
                                                        <ItemListBtn onClick={()=>{removeFromCart(newArrayProduct, modifiersProductCount.amount === 1)}}>
                                                            -
                                                        </ItemListBtn>
                                                        <ItemListCounter>{modifiersProductCount && modifiersProductCount.amount}</ItemListCounter>
                                                    </PanelRemoveCountProduct>}
                                                </CSSTransition>
                                                {isAuth
                                                    ? order.amountForOrder !== null && remainCountProduct <= 5
                                                        ? remainCountProduct === 0 || remainCountProduct < 0 || !isAvailableToOrder
                                                            ? <ItemListBtnDisabled>+</ItemListBtnDisabled>
                                                            : <ItemListBtn onClick={()=>{addToCart(newArrayProduct, false)}}>+</ItemListBtn>
                                                        : !isAvailableToOrder
                                                            ? <ItemListBtnDisabled>+</ItemListBtnDisabled>
                                                            : <ItemListBtn onClick={()=>{addToCart(newArrayProduct, false)}}>+</ItemListBtn>
                                                    : <AuthModal
                                                        isAuth={isAuth}
                                                        onChange={handleReduxFormAuth}
                                                        profile={profile}
                                                        codeSent={codeSent}
                                                        phoneError={phoneError}
                                                        sendCodeToPhone={sendCodeToPhone}
                                                        createTokenBySession={createTokenBySession}
                                                        errorMessage={errorMessage}
                                                        valueAuthEmail={valueAuthEmail}
                                                        buttonFront={<ItemListBtn>+</ItemListBtn>}
                                                        isToken={isToken}
                                                        createTokenByPassword={createTokenByPassword}
                                                        sendActivationMail={sendActivationMail}
                                                        closeModalModifier={closeModalModifier}
                                                    />
                                                }
                                            </PanelModified>
                                        </ItemListCount>
                                    </WrapperControl>
                                )
                            })
                        )}
                    </WrapperModified>
                </ModalWrapper>
            </Modal>
        </Container>
    )
};

export default ModifiedProduct;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

const ModalWrapper = styled.div`
	background: #fff;
	border-radius: 12px;
	display: flex;
	/*height: auto;*/
	/*overflow: hidden;*/
	padding: 25px;
	width: 100%;
	position: relative;
	
	max-height: 100vh;
	overflow-y: scroll;
	
    @media(max-width: 992px){
	    flex-direction: column;
	    padding: 0;
	    height: 100%;
	}
`;

const ItemListCount = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ItemListCounter = styled.span`
	margin: 0 12px;
	font-size: 18px;
	line-height: 1.2;
	color: ${MAIN_COLOR};
`;

const RemainCountProduct = styled.span`
    height: 20px;
    min-width: 100px;
    background: #fff;
    position: absolute;
    right: 0;
    bottom: -1px;
    font-size: 12px;
    color: #9c9c9c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    border-top-left-radius: 6px;
`;

const ItemListBtn = styled.span`
	height: 35px;
	width: 35px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	font-size: 25px;
	transition: all 0.15s ease;
	user-select: none;
	z-index: 2;
    border: 1px solid ${MAIN_COLOR};
    color: ${MAIN_COLOR};

	&:hover {
        color: #fff;
        background: ${MAIN_COLOR};
	}
`;

const ItemListBtnDisabled = styled(ItemListBtn)`
    color: #bebebe;
    border: 1px solid #bebebe;
    opacity: 0.5;
    background: transparent;
    
    &:hover {
        cursor: not-allowed;
		background-color: #bebebe;
		color: #fff;
		border-color: #bebebe;
	}
`;

const WrapperControl = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
    margin-bottom: 1rem;
    
    @media(max-width: 992px){
	    margin-bottom: 8px;
	}
`;

const Modified = styled.div`
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-bottom: 8px;
	
	@media(max-width: 992px){
	    margin-bottom: 0px;
	}
`;

const WrapperInfo = styled.div`
	width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
    @media(max-width:992px){
        padding: 0 15px;
    }
`;

const WrapperImage = styled.div`
	position: relative;
	cursor: pointer;
	transition: 2s linear;
`;

const LogoRest = styled.img`
	position: absolute;
	left: 5px;
	bottom: 5px;
	width: 40px;
`;

const Image = styled.img`
	width: 100%;
	border-radius: 3px;
    object-fit: cover;
	height: 258px;
`;

const PanelModified = styled.div`
    margin-left: auto;
    display: flex;
`;

const DescProd = styled.p`
    ${NORMAL_FONT};
	width: 100%;
	font-size: 16px;
	color: ${TXT_COLOR};
	text-align: left;
	margin: 0;
`;

const Wrapper = styled.div`
    height: 100%;
	padding: 0 15px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 50%;
	
	@media(max-width: 992px){
	    width: 100%;
	    height: auto;  
        padding: 0;
	}
`;

const WrapperModified = styled.div`
	width: 50%;
	padding-left: 25px;
	
    @media(max-width: 992px){
	    width: 100%;  
	    padding: 0 15px;
        margin-top: 15px;
        overflow-y: scroll;
	}
`;

const NameModified = styled.div`
	${NORMAL_FONT};
	font-size: 18px;
	color: ${TXT_COLOR};
`;

const PriceModified = styled.div`
	${NORMAL_FONT};
	font-size: 16px;
	color: ${TXT_COLOR};
	white-space: nowrap;
`;

const ButtonAdd = styled.div`
    padding: 7px 27px;
    border: ${({disable}) => disable ? "1px solid rgb(183,183,183)" : `1px solid ${MAIN_COLOR}`};
    background: ${({disable}) => disable ? "rgb(183,183,183)" : MAIN_COLOR};
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    position: absolute;
    overflow: hidden;
    cursor: ${({disable}) => disable ? "not-allowed" : "pointer"};
    opacity: ${({disable}) => disable ? ".7" : "1"};
    right: 0;
    white-space: nowrap;
    ${BOLD_FONT};
    
    &:hover{
        background: ${({disable}) => disable ? "rgb(183,183,183)" : "#fff"};
        color: ${({disable}) => disable ? "#fff" : MAIN_COLOR}
    }
`;

const PriceCount = styled.div`
    font-size: 20px;
    color: #333333;
`;

const NutritionalValue = styled.div`
    width: 100%;
    margin-top: 16px;
    
    @media(max-width:992px){
        padding: 0 15px;
    }
`;

const NutritionalValueTitle = styled.div`
    display: flex;
    align-items: center;
`;

const NutritionalTitle = styled.div`
    ${BOLD_FONT};
    font-size: 14px;
    line-height: 17px;
    color: ${TXT_COLOR};
    text-align: left;
    margin: 10px 0;
    cursor: pointer;
`;

const IconDirection = styled(FontAwesomeIcon)`
    margin-left: 10px;
    transform: ${({directionnutr}) => directionnutr === 'true' ? "rotateX(180deg)" : "rotateX(0deg)"};
`;

const Nutritionals = styled.div`
    transition: all 180ms linear;
    max-height: 110px;
    
    &.nitritional-enter{
        transition: max-height 180ms linear, opacity 180ms linear 100ms;
        max-height: 0;
        opacity: 0;
    }
    
    &.nitritional-enter-active{
        transition: max-height 180ms linear, opacity 180ms linear 100ms;
        max-height: 110px;
        opacity: 1;
    }
    
    &.nitritional-exit{
        transition: max-height 180ms linear 100ms, opacity 180ms linear;
        max-height: 110px;
        opacity: 1;
    }
    
    &.nitritional-exit-active{
        transition: max-height 180ms linear 100ms, opacity 180ms linear;
        max-height: 0;
        opacity: 0;
    }
`;

const CellNitritionalValue = styled.div`
    display: flex;
    flex-wrap: nowrap;
    
    &:last-child{
      margin-top: 15px;
    }
`;

const NameNutritionalValue = styled.span`
    ${NORMAL_FONT};
    width: 35%;
    font-size: 14px;
    line-height: 17px;
    color: #263238;
`;

const CountNutritionalValue = styled(NameNutritionalValue)`
    width: auto;
`;

const PanelRemoveCountProduct = styled.div`
    &.addMod-enter {
        opacity: 0;
        transform: translateX(70px);
        transition: all 500ms ease-in-out;
    }
    
    &.addMod-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
        margin-right: 0;
    }
    
    &.addMod-exit {
        opacity: 1;
    }
    
    &.addMod-exit-active {
        opacity: 0;
        transform: translateX(70px);
        transition: all 500ms ease-in-out;
        margin-right: -30px;
    }
`;

const CloseX = styled.i`
    position: absolute;
    top: 0px;
    right: 5px;
    padding: 10px;
    cursor: pointer;
    z-index: 100;
    font-size: 24px;
`;
