import {request} from "../utils/request";
import {_} from "underscore";
import {deliveryCycleInfoMessage} from "./ModalActions";
import moment from "moment";
import ym from "react-yandex-metrika";
import ReactPixel from 'react-facebook-pixel';

export const GET_NEW_MENU_REQUEST = "GET_NEW_MENU_REQUEST";
export const GET_NEW_MENU_SUCCESS = "GET_NEW_MENU_SUCCESS";
export const GET_NEW_MENU_ERROR = "GET_NEW_MENU_ERROR";

export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_ERROR = "SAVE_ORDER_ERROR";

export const GET_NEW_ORDERS_SUCCESS = "GET_NEW_ORDERS_SUCCESS";
export const GET_NEW_ORDERS = "GET_NEW_ORDERS";
export const GET_NEW_ORDERS_ERROR = "GET_NEW_ORDERS_ERROR";
export const FILTER_NEW_ORDERS = "FILTER_NEW_ORDERS";

export const EDIT_DAY = "EDIT_DAY";
export const EDIT_PERIOD = "EDIT_PERIOD";
export const EDIT_TIME_REQUEST = "EDIT_TIME_REQUEST";
export const EDIT_TIME = "EDIT_TIME";
export const EDIT_TIME_ORDER = "EDIT_TIME_ORDER";

export const GET_SUM_COUNT = "GET_SUM_COUNT";

export const SAVE_ORDER = "SAVE_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";

export const DOWNLOAD_ORDER = "DOWNLOAD_ORDER";
export const DOWNLOAD_ORDER_REQUEST = "DOWNLOAD_ORDER_REQUEST";

export const GET_CHECK_EDIT = "GET_CHECK_EDIT";
export const GET_CHECK_EDIT_CATEGORY = "GET_CHECK_EDIT_CATEGORY";

export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS";
export const GET_DELIVERY_PRICE = "GET_DELIVERY_PRICE";
export const GET_DELIVERY_TIME = "GET_DELIVERY_TIME";
export const GET_ACTIVE_PRODUCT = "GET_ACTIVE_PRODUCT";
export const GET_SETTINGS_TIME = "GET_SETTINGS_TIME";

export const GET_MAIN_TIME = "GET_MAIN_TIME";
export const GET_CHANGE_PAY_TYPE = "GET_CHANGE_PAY_TYPE";
export const GET_CHANGE_WIDGET_ACTION = "GET_CHANGE_WIDGET_ACTION";

export const GET_DIALOG_ADD_GOOD = "GET_DIALOG_ADD_GOOD";

export const GET_RESTARAUNTS_INFO = "GET_RESTARAUNTS_INFO";

export const GET_SETTINGS = "GET_SETTINGS";

export const GET_CHECK_REQUEST = "GET_CHECK_REQUEST";
export const GET_CHECK_SUCCESS = "GET_CHECK_SUCCESS";
export const GET_CHECK_ERROR = "GET_CHECK_ERROR";
export const GET_CHECK_CLEAR = "GET_CHECK_CLEAR";

export const SET_PAY_URL = "SET_PAY_URL";
export const CLEAR_PAY_URL = "CLEAR_PAY_URL";

export const currrentTimeServer = () => async (dispatch) => {
    let response = await request("AppDinnerController", "GetAppSettings", {});
    response.data.settings.currentDateTimeUTC = moment(response.data.settings.currentDateTimeUTC).add(4, 'hours');

    dispatch({
        type: GET_SETTINGS_TIME,
        payload: response.data.settings
    });
};

export const GetAppSettings = () => async (dispatch) => {
    let response = await request("AppDinnerController", "GetAppSettings", {
        city: {
            id: JSON.parse(localStorage.getItem('currentCity')) ?
                JSON.parse(localStorage.getItem('currentCity')).id : "18"
        }
    });
    response.data.settings.currentDateTimeUTC = moment(response.data.settings.currentDateTimeUTC).add(4, 'hours');

    dispatch({
        type: GET_SETTINGS,
        payload: response.data
    });
};

export const getMenu = (restId, mainDate, periodId = '1') => async (dispatch, getState) => {
	var response;
	var response_order;
    let response_restoraunt;
    let restaraunts = {};
	var arrayElementsAll = [];
	var arraySectionAll = [];
	var arraySectionRest = {};
	var arrayModified = {};
	var arrayImages = {};
	var arrayRootSection = [];

	if(!restId){
        dispatch({
            type: GET_NEW_MENU_SUCCESS,
        });

        return false;
    }

    try {
        dispatch({
            type: GET_NEW_MENU_SUCCESS,
        });

        response_restoraunt = await request("AppDinnerController", "GetAccountsForDate", {
            "city": restId,
            "date": {dateString: mainDate},
            "period": {id: periodId},
        });

        // Список ресторанов и их настройки
        dispatch({
            type: GET_ACCOUNTS,
            payload: response_restoraunt.data.accounts
        });

        // Общие настройки для ресторанов
        dispatch({
            type: GET_GENERAL_SETTINGS,
            payload: response_restoraunt.data.settings
        });

        dispatch({
            type: GET_DELIVERY_PRICE,
            payload: response_restoraunt.data.deliveryOrder
        });

        dispatch({
            type: DOWNLOAD_ORDER_REQUEST,
        });

        response_restoraunt.data.accounts.reverse();

        for(let i = 0; response_restoraunt.data.accounts.length > i; i++){
            let account = response_restoraunt.data.accounts[i];

            response_order = await request("AppDinnerController", "GetContentForAccount", {
                "owner": {"id": account.id},
                "date": {dateString: mainDate},
                "period": {id: periodId},
            });

            _.each(response_restoraunt.data.accounts,(rest) => {
                restaraunts = {
                    ...restaraunts,
                    [rest.id]: rest
                };
            });


            if(_.size(arrayRootSection) === 0){
                arrayRootSection.push(...response_order.data.menu.root.sectionsAsArray);
            }

            _.each(response_order.data.menu, (section, key) => {
                if(key === 'sections'){
                    arraySectionRest[account.id] = section;
                }
            });

            _.each(response_order.data.menu.modifiers, (each, key) => {
                arrayModified[key] = [...each];
            });

            _.each(response_order.data.images, (each_img, key_img) => {
                arrayImages[key_img] = {...each_img};
            });

            if (window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru')) {
                _.each(response_order.data.menu.elements, (item) => {
                    if (account.id === '210106' || account.id === '211959') {
                        item['restId'] = account.id;
                        arrayElementsAll.push(item);
                    }
                })
            } else if (window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru')) {
                _.each(response_order.data.menu.elements, (item) => {
                    if (account.id === '221204' || account.id === '222928' || account.id === '223562') {
                        item['restId'] = account.id;
                        arrayElementsAll.push(item);
                    }
                })
            } else {
                _.each(response_order.data.menu.elements, (item) => {
                    // if (account.id !== '223074' && account.id !== '224490' && account.id !== '224725') {
                    //     item['restId'] = account.id;
                    //     arrayElementsAll.push(item);
                    // }
                    item['restId'] = account.id;
                    arrayElementsAll.push(item);
                })
            }

            _.map(response_order.data.menu.sections, (section) => {
                if (_.size(section.elementsAsArray) !== 0) {
                    if(!_.some(arraySectionAll, function(sect_item){ return sect_item.id == section.id; })){
                        arraySectionAll.push(section);
                    } else {
                        _.each(arraySectionAll, (sect, key) => {
                            if(sect.id === section.id){
                                let filterElementsAsArray = _.find(arraySectionAll, function(sect_item){return sect_item.id === section.id;}).elementsAsArray;
                                let str = section.elementsAsArray.join(', ');
                                let str2 = filterElementsAsArray.join(', ');
                                let final_str = str + ', ' + str2;
                                let arrayIdStr = final_str.split(', ');

                                sect.elementsAsArray = _.without(arrayIdStr, "");
                            }
                        });
                    }
                }
            });
        }

        let minSort = arraySectionAll.reduce((acc, cur) => +acc.sort < +cur.sort ? acc : cur);

        dispatch(getAccountsForDate(mainDate, periodId));

        dispatch({
            type: GET_NEW_MENU_REQUEST,
            payload: {
                menu: {
                    elements: arrayElementsAll,
                    sections: arraySectionAll,
                    sectionsRest: arraySectionRest,
                    modifiers: arrayModified,
                    images: arrayImages,
                    rootSection: arrayRootSection
                }
            }
        });



        dispatch({
            type: GET_RESTARAUNTS_INFO,
            payload: restaraunts
        });

        // _.map(arraySectionAll, (section)=>{
        //     if(section.id === response_order.data.menu.root.sectionsAsArray[0]){
        //         dispatch({
        //             type: GET_ACTIVE_PRODUCT,
        //             payload: _.size(section.elementsAsArray) === 0 ? "1" : response_order.data.menu.root.sectionsAsArray[0]
        //         });
        //     }
        // });

        dispatch({
            type: GET_ACTIVE_PRODUCT,
            payload: arraySectionAll.length === 0 ? "1" : minSort.id
        });

        // dispatch({
        //     type: GET_ACTIVE_PRODUCT,
        //     payload: window.dataCity().id == "9" ? "1" : response_order.data.menu.root.sectionsAsArray[0]
        // });

    } catch (err) {
        dispatch({
            type: GET_NEW_MENU_ERROR,
            payload: response
                ? response.message.replace(".", "")
                : err.message
        });
    }
};

export const getTime = time => (dispatch) => {
	dispatch({
		type: EDIT_TIME,
		payload: time
	});
};

export const getTimeOrder = time => async (dispatch) => {
    dispatch({
        type: EDIT_TIME_ORDER,
        payload: time
    });
};

export const getDay = day => async (dispatch) => {
    dispatch({
        type: EDIT_DAY,
        payload: day
    });
};

export const getPeriod = (period = "1") => async (dispatch) => {
    dispatch({
        type: EDIT_PERIOD,
        payload: period
    });
};

export const getSum = count => async (dispatch) => {
    dispatch({
        type: GET_SUM_COUNT,
        payload: count
    });
};

export const getMainTime = time => async (dispatch) => {
    dispatch({
        type: GET_MAIN_TIME,
        payload: time
    });
};

export const getOrders = order => async (dispatch) => {
	try {
        dispatch({
            type: GET_NEW_ORDERS_SUCCESS
        });
		dispatch({
			type: GET_NEW_ORDERS,
			payload: order
		});
	} catch (err) {
		dispatch({
			type: GET_NEW_ORDERS_ERROR,
			payload: err.message
		});
	}
};

export const getCheckEdit = () => async (dispatch) => {
    dispatch({
        type: GET_CHECK_EDIT,
    });

    setTimeout(function(){
        dispatch({
             type: GET_CHECK_EDIT,
         });
    }, 500)
};

export const getDeliveryTime = (time) => async (dispatch) => {
    dispatch({
        type: GET_DELIVERY_TIME,
        payload: time
    });
};

export const getAddDialogGood = (bool, good = []) => async (dispatch) => {
    dispatch({
        type: GET_DIALOG_ADD_GOOD,
        payload: {
            bool,
            good
        }
    });
};

export const getCheckEditCategory = () => async (dispatch) => {
    dispatch({
        type: GET_CHECK_EDIT_CATEGORY,
    });

    setTimeout(function(){
        dispatch({
            type: GET_CHECK_EDIT_CATEGORY,
        });
    }, 350)
};

export const getActiveProduct = (id_category) => dispatch => {
    dispatch({
        type: GET_ACTIVE_PRODUCT,
        payload: id_category,
    });
};

export const payTypeChange = (id) => dispatch => {
    localStorage.setItem('payType', id);

    dispatch({
        type: GET_CHANGE_PAY_TYPE,
        payload: id,
    });
};

export const changeWidgetAction = (action) => dispatch => {
    /*dispatch({
        type: GET_CHANGE_WIDGET_ACTION,
        payload: action,
    });*/
};

export const getAccountsForDate = (day, periodId = "1") => async (dispatch, getState) => {
    let response = await request("AppDinnerController", "GetAccountsForDate", {
        "city": JSON.parse(localStorage.getItem('currentCity')),
        "date": {dateString: day},
        "period": {id: periodId},
    });

    if (_.size(response.data.orders) !== 0 && _.size(response.data.accounts) !== 0 && _.some(response.data.orders, order => { return _.some(response.data.accounts, acc => { return order.account === acc.id }) })){
        dispatch({
            type: DOWNLOAD_ORDER,
            payload: {
                regOrders: _.filter(response.data.orders, order => { return _.some(response.data.accounts, acc => { return acc.id === order.account}) && order}),
                companyOrder: response.data.companyOrder
            }
        });

        /*dispatch({
            type: GET_CHANGE_WIDGET_ACTION,
            payload: {
                basketWidget: false,
                checkWidget: true,
                editWidget: false,
            }
        });*/
    } else {
        dispatch({
            type: DOWNLOAD_ORDER,
            payload: {
                regOrders: [],
                companyOrder: []
            }
        });
        /*dispatch({
            type: GET_CHANGE_WIDGET_ACTION,
            payload: {
                basketWidget: true,
                checkWidget: false,
                editWidget: false,
            }
        });*/
    }
};

export const saveOrder = (day, items, action,  city = false, regOrder = null, periodId) => async (dispatch, getState) => {
    try {
        if(action === 'save'){
            dispatch({
                type: SAVE_ORDER_REQUEST,
            });

            let uniqIdRest = _.uniq(_.pluck(items, 'restId'));
            let objectItems = {};

            _.map(uniqIdRest, (idRest)=>{
                objectItems[idRest] = _.filter(items, item => {return item.restId === idRest});
            });

            let save = [];
            let dayMain = day;

            const asyncCycle = async () => {
                for(let item in objectItems){
                    let save_order = await request("DinnerController", "SaveOrder", {
                        owner: {
                            id: item
                        },
                        date: {dateString: day},
                        paysystem: {
                            id: 1
                        },
                        products: objectItems[item],
                        period: {id: periodId},
                    });

                    save.push(save_order.data);

                    dispatch(deliveryCycleInfoMessage('create_order'));
                }
            }

            await asyncCycle();

            dispatch({
                type: SAVE_ORDER,
                payload: {
                    data: save,
                    day: dayMain,
                }
            });

            dispatch(await getAccountsForDate(day, periodId));
        } else if (action === 'delete') {
            let save_order;

            items.map((item, key) => {

                for (let i = 0; i < regOrder.length; i++) {

                    if (regOrder[i].account === item.id) {

                        save_order = request("DinnerController", "SaveOrder", {
                            owner: {
                                id: item.id
                            },
                            // deliveryData: {
                            //     time: day
                            // },
                            date: {dateString: day},
                            paysystem: {
                                id: regOrder[i].paysystem.id,
                            },
                            products: [],
                            period: {id: periodId},
                            order: {id: regOrder[i].id},
                        })
                    }
                }
            });

            for(let item in items){
                if(item == day){
                    for(let i = 0; i < items[item].length; i++){
                        delete items[item];
                    }
                    dispatch({
                        type: SAVE_ORDER,
                        payload: {
                            data: items,
                            day: day
                        }
                    });
                }
            }

            dispatch(await getAccountsForDate(day, periodId));
            setTimeout(() => {
                dispatch(deliveryCycleInfoMessage('remove_order'));
            }, 1000);
        }
    } catch (err) {
        console.log(err, 'ERROR!!', regOrder)
        dispatch({
            type: SAVE_ORDER_ERROR,
            payload: err.message
        });
    }
};

export const saveOrders = (day, items, action,  city = false, regOrder = null, periodId, metrika = false, phone= '') => async (dispatch, getState) => {
    try {
        if(action === 'save'){
            dispatch({
                type: SAVE_ORDER_REQUEST,
            });

            let uniqIdRest = _.uniq(_.pluck(items, 'restId'));
            let objectItems = {};

            _.map(uniqIdRest, (idRest)=>{
                objectItems[idRest] = _.filter(items, item => {return item.restId === idRest});
            });

            let save = [];
            let dayMain = day;
            let orders = [];

            const asyncCycle = async () => {
                for(let item in objectItems){
                    orders.push({
                        owner: {
                            id: item
                        },
                        // deliveryData: {
                        //     time: day
                        // },
                        date: {dateString: day},
                        paysystem: {
                            id: 1
                        },
                        products: objectItems[item],
                        period: {id: periodId}
                    });
                }

                let history_orders = await request("AppDinnerController", "GetOrders1", {});
                let save_orders = await request("DinnerController", "SaveOrders", {"orders" : orders});

                if (save_orders.data.errors) {
                    console.log(save_orders.data.errors)
                    alert(save_orders.data.errors[0].description);
                } else {
                    dispatch(deliveryCycleInfoMessage('create_order'));

                    if (
                        metrika
                        && !window.location.href.includes('51obed.ru')
                        && !window.location.href.includes('51.menu4me-test.ru')
                        && !window.location.href.includes('stolovka.su')
                        && !window.location.href.includes('straus.menu4me-test.ru')
                    ) {
                        let priceResult = save_orders.data.reduce((prev, cur) => {return prev + +cur.priceResult}, 0)

                        if (history_orders.data.orders.length === 0) {
                            ym('reachGoal','first_order', {phone: phone, orderId: save_orders.data[0].companyOrder.id, priceResult: priceResult});
                            ReactPixel.trackCustom('Первый заказ', {phone: phone, orderId: save_orders.data[0].companyOrder.id, priceResult: priceResult});
                            window.VK.Goal('purchase', {phone: phone, orderId: save_orders.data[0].companyOrder.id, priceResult: priceResult, type: 'first_order'})
                        } else {
                            ym('reachGoal','new_order', {phone: phone, orderId: save_orders.data[0].companyOrder.id, priceResult: priceResult});
                            ReactPixel.trackCustom('Заказ', {phone: phone, orderId: save_orders.data[0].companyOrder.id, priceResult: priceResult});
                        }
                    }
                }

                for(let i = 0; i < save_orders.data.length; i++) {
                    save.push(save_orders.data[i])
                }

            }

            await asyncCycle();

            dispatch({
                type: SAVE_ORDER,
                payload: {
                    data: save,
                    day: dayMain,
                }
            });

            dispatch(await getAccountsForDate(day, periodId));
        } else if (action === 'delete') {
            let save_order;

            items.map((item, key) => {

                for (let i = 0; i < regOrder.length; i++) {

                    if (regOrder[i].account === item.id) {

                        save_order = request("DinnerController", "SaveOrder", {
                            owner: {
                                id: item.id
                            },
                            // deliveryData: {
                            //     time: day
                            // },
                            date: {dateString: day},
                            paysystem: {
                                id: regOrder[i].paysystem.id,
                            },
                            products: [],
                            period: {id: periodId},
                            order: {id: regOrder[i].id},
                        })
                    }
                }
            });

            for(let item in items){
                if(item == day){
                    for(let i = 0; i < items[item].length; i++){
                        delete items[item];
                    }
                    dispatch({
                        type: SAVE_ORDER,
                        payload: {
                            data: items,
                            day: day
                        }
                    });
                }
            }

            dispatch(await getAccountsForDate(day, periodId));
            setTimeout(() => {
                dispatch(deliveryCycleInfoMessage('remove_order'));
            }, 1000);
        }
    } catch (err) {
        console.log(err, 'err')
        dispatch({
            type: SAVE_ORDER_ERROR,
            payload: err.message
        });
    }
};

export const getCheck = (
    date,
    owner,
    company,
    period,
    products,
    promocode = '',
    paysystem = null,
    wallet = null
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CHECK_REQUEST
        })

        let data = {
            "date": {"dateString": date},
            "owner": {"id": owner},
            "company": {"id": company},
            "period": {"id": period},
            products,
        }

        if (promocode) {
            data["promocode"] = {"code": promocode}
        }

        if (paysystem) {
            data["paysystem"] = paysystem
        }

        if (wallet) {
            if (wallet.id) {
                data["wallet"] = wallet
            }
        }

        console.log(data, "DAAAAATAAAAAA")

        let response = await request("AppDinnerController", "GetCheck", data)

        if (response.result === 1) {
            console.log(response, 'Результат getCheck')
            if (response.data.promocode.result !== undefined) {
                if (response.data.promocode.result) {
                    dispatch(deliveryCycleInfoMessage('promo_message', response.data.promocode.message));
                } else {
                    dispatch(deliveryCycleInfoMessage('promo_message_error', response.data.promocode.message));
                }
            }
            dispatch({
                type: GET_CHECK_SUCCESS,
                payload: response.data
            })
        } else if (response.errors && Array.isArray(response.errors) && response.errors.length) {
            console.log(response.errors[0].description)
            dispatch(deliveryCycleInfoMessage('check_error', response.errors[0].description))
            dispatch({
                type: GET_CHECK_ERROR,
                payload: response.errors[0].description
            });
        } else if (response.message) {
            console.log(response.message)
            dispatch(deliveryCycleInfoMessage('check_error', response.message))
            dispatch({
                type: GET_CHECK_ERROR,
                payload: response.message
            });
        } else {
            dispatch(deliveryCycleInfoMessage('check_error', 'Непредвиденная ошибка getCheck'))
            dispatch({
                type: GET_CHECK_ERROR,
                payload: 'Непредвиденная ошибка getCheck'
            });
        }
    } catch (err) {
        console.log(err, 'getCheck error')
        dispatch({
            type: GET_CHECK_ERROR,
            payload: err.message
        });
    }
};

export const clearCheck = () => async (dispatch) => {
    dispatch({
        type: GET_CHECK_CLEAR,
    })
};

export const newSaveOrder = (
    card,
    company,
    date,
    owner,
    paysystem,
    period,
    products,
    shortchange = [],
    wallet,
    phone = '',
    receipt,
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SAVE_ORDER_REQUEST,
        });

        let data = {
            "company": {"id": company},
            "date": {"dateString": date}, //date "2021-08-19"
            "order": {"id": 0},
            "owner": {"id": owner},
            "paysystem": {"id": paysystem},
            "period": {"id": period},
            "products": products,
        }

        if (card) {
            data["card"] = {"id": card}
        }

        if (shortchange) {
            data["shortchange"] = shortchange
        }

        if (wallet) {
            if (wallet.id) {
                data["wallet"] = wallet
            }
        }

        if (receipt) {
            data["Receipt"] = receipt
        }

        console.log(data, 'newSaveOrderDATA')

        let history_orders = await request("AppDinnerController", "GetOrders1", {});
        let response = await request("DinnerController", "SaveOrder", data)

        console.log(response, 'RESPONSE_NEW_SAVE_ORDER')

        if (response.result === 1 && !response.data.errors) {
            console.log(response, 'Результат SaveOrder')

            dispatch({
                type: FILTER_NEW_ORDERS,
                payload: response.data.owner.id,
            })

            dispatch(getAccountsForDate(response.data.date.dateString, response.data.period.id))

            if (response.data.paysystem.id === '2' || response.data.paysystem.id === '16') {

                let response_register = await request("PaySystemService", "RegisterOrder", {
                    orders: [{"id": response.data.order.id}],
                    paysystem: {"id": response.data.paysystem.id},
                    cardId: card || '',
                    domain: window.location.origin,
                })

                console.log(response_register, 'response_register')

                if (response_register.result === 1) {
                    dispatch({
                        type: SET_PAY_URL,
                        payload: response_register.data.formUrl,
                    })
                }
            }

            dispatch({
                type: SAVE_ORDER,
                payload: response.data,
                /*payload: {
                    data: save,
                    day: dayMain,
                }*/
            })

            if (
                !window.location.href.includes('51obed.ru')
                && !window.location.href.includes('51.menu4me-test.ru')
                && !window.location.href.includes('stolovka.su')
                && !window.location.href.includes('straus.menu4me-test.ru')
                && !window.location.href.includes('so.menu4me-test.ru')
            ) {
                let priceResult = response.data.priceResult;

                if (history_orders.data && history_orders.data.orders && history_orders.data.orders.length === 0) {
                    ym('reachGoal','first_order', {phone: phone, orderId: response.data.companyOrder.id, priceResult: priceResult});
                    ReactPixel.trackCustom('Первый заказ', {phone: phone, orderId: response.data.companyOrder.id, priceResult: priceResult});
                    window.VK.Goal('purchase', {phone: phone, orderId: response.data.companyOrder.id, priceResult: priceResult, type: 'first_order'})
                } else {
                    ym('reachGoal','new_order', {phone: phone, orderId: response.data.companyOrder.id, priceResult: priceResult});
                    ReactPixel.trackCustom('Заказ', {phone: phone, orderId: response.data.companyOrder.id, priceResult: priceResult});
                }
            }
        } else if (response.data.errors && Array.isArray(response.data.errors) && response.data.errors.length) {
            console.log(response.data.errors[0].description)
            dispatch(deliveryCycleInfoMessage('error_save_order', response.data.errors[0].description))
            dispatch({
                type: SAVE_ORDER_ERROR,
                payload: response.data.errors[0].description
            });
        } else if (response.data.message) {
            console.log(response.data.message)
            dispatch(deliveryCycleInfoMessage('error_save_order', response.data.message))
            dispatch({
                type: SAVE_ORDER_ERROR,
                payload: response.data.message
            });
        } else {
            dispatch(deliveryCycleInfoMessage('error_save_order', 'Непредвиденная ошибка SaveOrder'))
            dispatch({
                type: SAVE_ORDER_ERROR,
                payload: 'Непредвиденная ошибка SaveOrder'
            });
        }
    } catch (err) {
        console.log(err)
        dispatch({
            type: SAVE_ORDER_ERROR,
            payload: err.message
        });
    }
};

export const clearOrder = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ORDER,
    })
};

export const clearPayUrl = () => async (dispatch) => {
    dispatch({
        type: CLEAR_PAY_URL,
    })
};
