import React, {Fragment, useEffect, useState} from "react";
import {_} from "underscore";
import styled, {keyframes} from "styled-components";
import {CSSTransition} from "react-transition-group";
import AddModalCompany from "../../Modal/AddModalCompany";
import {TXT_COLOR} from "../../../utils/constants";
import coord_icon from "../../../images/marker/coord.svg";

export const SelectAddress = (props) => {
    const[company, changeCompany] = useState([]);
    const[preloader, changePreloader] = useState(true);
    const[openSelectAddress, changeOpenSelectAddress] = useState(false);
    const{
        companies,
        activeCompany,
        saveCompany,
        handleReduxFormEditCompany,
        valueEditCompany,
        cities,
        changeActiveCompany,
        getRemoveInviteToCompany,
        confirmInviteToCompany,
        day,
        period,
        getAccountsForData,
        getMenu
    } = props;

    useEffect(()=>{
        changeCompany(companies);

        setTimeout(()=>{
            changePreloader(false);
        }, 500)
    });

    useEffect(() => {
        if (props.isMobile) {
            if (openSelectAddress) {
                document.body.style.overflow = "hidden"
            } else {
                document.body.style.overflowX = "visible";
                document.body.style.overflowY = "scroll"
            }
        }
    }, [openSelectAddress]);

    const handleChangeSelect = async (e, company) => {
        e.stopPropagation();

        if (company.type !== "0") {
            changeOpenSelectAddress(!openSelectAddress);
            await changeActiveCompany(company, true);
            getAccountsForData(day);
            getMenu(window.dataCity(), day, period);
        }
    };

    const confirmInvite = (event, companyId) => {
        event.stopPropagation();

        confirmInviteToCompany(companyId);
    };

    const removeInvite = (event, companyId) => {
        event.stopPropagation();

        getRemoveInviteToCompany(companyId);
    };

    let countInvite = _.filter(company, inv => {return inv.type === "0"});
    let countMyCompany = _.filter(company, inv => {return inv.type !== "0"});

    return(
        <Fragment>
            <SelectWrapper openSelect={openSelectAddress} onClick={()=>{_.size(company) !== 0 && changeOpenSelectAddress(!openSelectAddress)}}>
                <CSSTransition in={_.size(company) === 0 && !preloader} timeout={100} classNames="selectAddress" unmountOnExit>
                    {()=>
                        <MainCompany>
                            <AddModalCompany
                                companyData={company}
                                saveCompany={saveCompany}
                                onChange={handleReduxFormEditCompany}
                                valueEditCompany={valueEditCompany}
                                cities={cities}
                            />
                        </MainCompany>
                    }
                </CSSTransition>
                <CSSTransition in={_.size(company) !== 0 && !preloader} timeout={100} classNames="selectAddress" unmountOnExit>
                    {()=>
                        <PanelActiveCompany>
                            <CoordIcon src={coord_icon}/>
                            <NameActiveCompany>
                                {props.isMobile && activeCompany ?
                                    activeCompany.address.street + ' ' + activeCompany.address.house + ', ' + activeCompany.name :
                                    activeCompany.name}
                            </NameActiveCompany>
                            {_.size(countInvite) !== 0 && (
                                <PulsingCountInvite title={'У вас есть приглашения!'}>
                                    <PulsingButton>{_.size(countInvite)}</PulsingButton>
                                </PulsingCountInvite>
                            )}
                        </PanelActiveCompany>
                    }
                </CSSTransition>
                <CSSTransition in={openSelectAddress} timeout={100} classNames="selectAddress" unmountOnExit>
                    {()=>
                        <ListAddress>
                            {_.size(countInvite) !== 0 && (
                                <InviteCompanies>
                                    <TitleSubSelect>Вас приглашают:</TitleSubSelect>
                                    {_.some(company, some_company => {return some_company.type === "0"}) ? (
                                        _.map(company, (item, key)=>{
                                            return item.type === "0" && (
                                                <AddressItem key={key} onClick={(e)=>{handleChangeSelect(e, item)}}>
                                                    {item.type === "1" && (
                                                        <OkIcon className="fas fa-check"/>
                                                    )}
                                                    <Name>
                                                        {item.name}
                                                    </Name>
                                                    <Address>
                                                        {`${item.address.street} ${item.address.house}, оф${item.address.flat}`}
                                                    </Address>
                                                    <OkRemove>
                                                        <Ok className="far fa-check-circle" title="Подтвердить приглашение" onClick={(e)=>{confirmInvite(e, item.id)}}/>
                                                        <Remove className="far fa-times-circle" title="Отклонить приглашение" onClick={(e)=>{removeInvite(e, item.id)}}/>
                                                    </OkRemove>
                                                </AddressItem>
                                            )
                                        })
                                    ) : (
                                        <MessageNotCompany>У вас нет актуальных приглашений</MessageNotCompany>
                                    )}
                                </InviteCompanies>
                            )}
                            {_.size(countMyCompany) !== 0 && (
                                <MyCompanies>
                                    {props.isMobile && (
                                        <div style={{
                                            width: 44,
                                            height: 3,
                                            marginTop: 8,
                                            marginBottom: 18,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            borderRadius: 2,
                                            backgroundColor: '#eee',
                                        }}/>
                                    )}
                                    {props.isMobile ? (
                                        <TitleSubSelect>
                                            <MyAddressesText>Мои адреса</MyAddressesText>
                                            <AddModalCompany
                                                companyData={company}
                                                saveCompany={saveCompany}
                                                onChange={handleReduxFormEditCompany}
                                                valueEditCompany={valueEditCompany}
                                                cities={cities}
                                                isMobile={true}
                                            />
                                        </TitleSubSelect>
                                    ) : (
                                        <TitleSubSelect>
                                            Мои адреса:
                                            <AddModalCompany
                                                companyData={company}
                                                saveCompany={saveCompany}
                                                onChange={handleReduxFormEditCompany}
                                                valueEditCompany={valueEditCompany}
                                                cities={cities}
                                            />
                                        </TitleSubSelect>
                                    )}
                                    {props.isMobile ? (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            {_.some(company, some_company => {return some_company.type !== "0"}) ? (
                                                _.map(company, (item, key)=>{
                                                    return item.type !== "0" && (
                                                        <AddressItem key={key} onClick={(e)=>{handleChangeSelect(e, item)}} active={item.type === "1"}>
                                                            {(item.type === "1" && !props.isMobile) && (
                                                                <OkIcon className="fas fa-check"></OkIcon>
                                                            )}
                                                            <Address>
                                                                {`${item.address.street} ${item.address.house}${item.address.structure ? ', корп. ' + item.address.structure : ''}${item.address.flat || item.address.flat === '0' ? ', оф/кв. ' + item.address.flat : ''}${item.address.floor || item.address.floor === '0' ? ', эт. ' + item.address.floor : ''}`}
                                                            </Address>
                                                            <Name>
                                                                {item.name}
                                                            </Name>
                                                        </AddressItem>
                                                    )
                                                })
                                            ) : (
                                                <MessageNotCompany>Вы не состоите ни в одной компании</MessageNotCompany>
                                            )}
                                        </div>
                                    ) : (
                                        <Fragment>
                                            {_.some(company, some_company => {return some_company.type !== "0"}) ? (
                                                _.map(company, (item, key)=>{
                                                    return item.type !== "0" && (
                                                        <AddressItem key={key} onClick={(e)=>{handleChangeSelect(e, item)}} active={item.type === "1"}>
                                                            {(item.type === "1" && !props.isMobile) && (
                                                                <OkIcon className="fas fa-check"></OkIcon>
                                                            )}
                                                            <Name>
                                                                {item.name}
                                                            </Name>
                                                            <Address>
                                                                {`${item.address.street} ${item.address.house}${item.address.structure ? ', корп. ' + item.address.structure : ''}${item.address.flat || item.address.flat === '0' ? ', оф/кв. ' + item.address.flat : ''}`}
                                                            </Address>
                                                        </AddressItem>
                                                    )
                                                })
                                            ) : (
                                                <MessageNotCompany>Вы не состоите ни в одной компании</MessageNotCompany>
                                            )}
                                        </Fragment>
                                    )}
                                </MyCompanies>
                            )}
                        </ListAddress>
                    }
                </CSSTransition>
            </SelectWrapper>
            {openSelectAddress && (
                <TransparentLayer openSelect={openSelectAddress} className="transparentLayer" onClick={()=>{changeOpenSelectAddress(!openSelectAddress)}}>

                </TransparentLayer>
            )}
        </Fragment>
    )
};

const SelectWrapper = styled.div`
    width: auto;
    min-width: 320px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 0 15px;
    position: relative;
    color: ${TXT_COLOR};
    background: transparent;
    z-index: ${({openSelect}) => openSelect ? "5001" : "350"};
    transition: 100ms linear;
    
    @media(max-width: 992px){
      min-width: auto;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      border: 1px solid #E6E9F0;
      border-radius: 66px;
    }
`;

const MainCompany = styled.div`
    position: absolute;
    left: auto;
    right: auto;

    &.selectAddress-enter{
        opacity: 0;
        transition: 100ms linear;
    }
    
    &.selectAddress-enter-active{
        opacity: 1;
        transition: 100ms linear;
    }  
    
    &.selectAddress-exit{
        opacity: 1;
    }  
    
    &.selectAddress-exit-active{
        opacity: 0;
        transition: 100ms linear;
    }  
`;

const ListAddress = styled.div`
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 391px;
    overflow-y: auto;
    background: #223;
    border: 1px solid #223;
    border-top: none;
    
    &::-webkit-scrollbar { 
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #f7efef;
    }

    &::-webkit-scrollbar-thumb {
        background: #a5a2a2;
    }
    
    &.selectAddress-enter{
        opacity: 0;
        transform: translateY(75px) scale(.8, .8);
        transition: 100ms linear;
    }
    
    &.selectAddress-enter-active{
        opacity: 1;
        transform: translateY(0) scale(1, 1);
        transition: 100ms linear;
    }  
    
    &.selectAddress-exit{
        transform: translateY(0) scale(1, 1);
        opacity: 1;
    }  
    
    &.selectAddress-exit-active{
        opacity: 0;
        transform: translateY(75px) scale(.8, .8);
        transition: 100ms linear;
    }
    
    @media(max-width: 992px){
        position: fixed;
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        width: 100vw;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: #fdfdfd;
        border: none;
        max-height: 75vh;
        box-shadow: 0 0 0 10000px rgba(38, 50, 56, 0.35);
    }
`;

const TransparentLayer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    z-index: ${({openSelect}) => openSelect ? "5000" : "auto"};
`;

const TitleSubSelect = styled.div`
    background: #223;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    
    @media(max-width:992px){
        background: #fdfdfd;
        color: #222;
        padding: 0 19px 20px 24px;
    }
`;

const MessageNotCompany = styled.p`
    background: rgba(255,255,255,0.3);
    text-indent: 30px;
    font-size: 14px;
    font-weight: 200;
    padding: 10px 0;
    margin: 0;
`;

const OkIcon = styled.i`
    font-size: 20px;
    color: #fff;
    position: absolute;
    left: 5px;
    top: calc(50% - 10px);
`;

const AddressItem = styled.div`
    width: 100%;
    background: rgba(255,255,255,0.3);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 35px;
    position: relative;
    
    @media (min-width: 992.01px) {
        &:hover{
            color: #223;
            background: #fff;
        }
        
        &:hover ${OkIcon}{
            color: #223;
        }
    }
    
    @media (max-width: 992px) {
        width: calc(100% - 16px);
        background: ${({active}) => active ? '#F1EDEC' : '#FDFDFD'};
        color: #222;
        padding: 15px 16px 13px;
        margin: 0 8px;
        border-radius: 12px;
        align-items: flex-start;
        order: ${({active}) => active ? '-1' : 'initial'};
    }
`;

const Ok = styled.div`
    font-size: 24px;
    cursor: pointer;
`;

const Remove = styled.div`
    font-size: 24px;
    cursor: pointer;
`;

const Name = styled.div`
    font-weight: 700;
    font-size: 18px;
    
    @media (max-width: 992px) {
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        color: #222222;
        text-align: left;
    }
`;

const Address = styled.div`
    font-size: 12px;
    
    @media (max-width: 992px) {
        font-family: Roboto;
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        color: #222222;
        text-align: left;
        margin-bottom: 2px;
    }
`;

const OkRemove = styled.div`
    position: absolute;
    height: 100%;
    width: 70px;
    right: 0;
    top: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const InviteCompanies = styled.div`
    
`;

const MyCompanies = styled.div`
    @media (max-width: 992px) {
        padding-bottom: 60px;
    }
`;

const PanelActiveCompany = styled.div`
    display: flex;
    align-items: center;
    
    @media(max-width:992px){
        max-width: 100%;
        margin: 0 auto;
        padding: 0 10px;
    }
`;

const CoordIcon = styled.img`
    width: 16px;
    margin-right: 10px;
    
    @media(max-width:992px){
        display: none;
    }
`;

const NameActiveCompany = styled.span`
    @media(max-width: 992px){
        font-family: Roboto;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const PulsingCountInvite = styled.div`
    height: 100%;
    position: absolute;
    top: 7px;
    right: -15px;
`;

const rotate = keyframes`
    to{
        box-shadow: 0 0 0 15px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
      }
`;

const PulsingButton = styled.div`
    text-align: center;
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    font-size: 1em;
    font-weight: normal;
    color: white;
    text-shadow: 0 1px 0 #a84c1f;
    border: 1px solid #f98034;
    box-shadow: 0 0 0 0 rgba(255,153,90,0.7), inset 0 1px 0 #e9cbab;
    border-radius: 100%;
    v-align: middle;
    background: #ff995d;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffa66e), color-stop(100%, #ff8c46));
    background-image: -webkit-linear-gradient(#ffa66e, #ff8c46);
    background-image: -moz-linear-gradient(#ffa66e, #ff8c46);
    background-image: -o-linear-gradient(#ffa66e, #ff8c46);
    background-image: linear-gradient(#ffa66e, #ff8c46);
    cursor: pointer;
    -webkit-animation: ${rotate} 1s infinite cubic-bezier(0.6, 0, 0, 1);
    
    &:hover {
        -webkit-animation: none;
    }
`;

const MyAddressesText = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #222222;
    margin: 3px 0 0 0;
`
