import React, {Fragment, useEffect, useState} from "react";
import * as _ from "underscore";
import styled from "styled-components";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {MAIN_COLOR} from "../../../utils/constants";
import ModalUniversal from "../../Modal/ModalUniversalAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";

const groupProps = {
    appear: true,
    enter: true,
    exit: true,
};

const Edit = (props) => {
    const [checkLimitRestaraunts, setLimitRestaraunts] = useState([]);
    const[editData, changeEditData] = useState(false);
    const dispatch = useDispatch();
    let ordersObj = {};
    let regObj = {};

    const onSaveOrder = () => {
        props.infoMessEdit('edit_order');
        props.changeWidget('check');
        //props.saveOrder(props.day, props.restaurants, 'delete', window.dataCity(), props.regOrders);
        //props.saveOrder(props.day, props.orders, 'save');
        props.saveOrders(props.day, props.restaurants, 'delete', window.dataCity(), props.regOrders, props.activePeriod);
        props.getAccountsForDate(props.day, props.activePeriod);
        props.saveOrders(props.day, props.orders, 'save', false, null, props.activePeriod);
    };

    const onCancelOrder = () => {
        props.changeWidget('check');
        props.getOrders([]);
    };

    _.each(props.orders, order => {
        ordersObj[order.id] = order.amount;
    });

    _.each(props.regOrder, reg => {
        regObj[reg.id] = reg.amount;
    });

    useEffect(()=>{
        changeEditData(_.isEqual(ordersObj, regObj));
    }, [ordersObj, regObj]);

    useEffect(() => {
        setLimitRestaraunts(() => {
            return _.map(_.groupBy(props.orders, "restId"), (groupItem, keyItem) => {
                let reducePrice = _.reduce(groupItem, (memo, num) => {
                    return (parseInt(num.price) * parseInt(num.amount)) + memo
                }, 0);
                let minOrderPrice = props.restarauntsInfo[keyItem].minOrderPrice;

                return reducePrice >= minOrderPrice ? true : false;
            })
        });
    }, [props.orders]);

    return (
        <EditWrapper open={props.openBasket} widget={props.edit} id="edit">
            <BasketPanelUser>
                <BasketHeader
                    // onTouchEnd={(e)=>{props.getTouchEnd(e)}}
                    // onTouchMove={(e)=>{props.getTouchMove(e)}}
                    // onTouchStart={(e)=>{props.getTouchStart(e)}}
                >
                    <HeadLeft>
                        <NameBasket>
                            Редактирование
                            <BottomIcon>
                                <SvgImage openBasket={props.openBasket} width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 5.41935C10 6.19353 0 6.19356 0 5.41937C0 4.64518 4.16667 1.09673e-05 5 1.09673e-05C5.83333 1.09673e-05 10 4.64518 10 5.41935Z" />
                                </SvgImage>
                            </BottomIcon>
                        </NameBasket>
                        <ActionIcons>
                            {!editData ? (
                                <ModalUniversal
                                    message="Вы уверены, что хотите отменить изменение заказа?"
                                    messageButton="Да"
                                    messageCancel="Отмена"
                                    action={onCancelOrder}
                                    buttonDate={<CancelEdit icon={faTimes}/>}
                                />
                            ) : (
                                <CancelEdit icon={faTimes} onClick={()=>{onCancelOrder()}}/>
                            )}
                            <ModalUniversal
                                message="Сохранить изменения заказа?"
                                messageButton="Да"
                                messageCancel="Нет"
                                action={onSaveOrder}
                                buttonDate={<SaveEdit icon={faCheck}/>}
                            />

                        </ActionIcons>
                    </HeadLeft>
                </BasketHeader>
                <EditScroll>
                    <BasketBody>
                        <Fragment>
                            <TransitionGroup>
                                {_.map(_.groupBy(props.orders, 'restId'), (filterItem, key) => (
                                    <Fragment key={key}>
                                        <Fragment>
                                            <RestInfo>
                                                <RestImage src={props.restarauntsInfo[key].logo}/>
                                                <RestName>{props.restarauntsInfo[key].name}</RestName>
                                            </RestInfo>
                                            {_.reduce(_.filter(props.orders, (filterItem2) => {
                                                return filterItem2.restId === key
                                            }), (memo, num) => {
                                                return  _.size(num.modifiers) === 0 ? (parseInt(num.price) * parseInt(num.amount)) + memo : (parseInt(num.price) * parseInt(num.amount)) + (parseInt(num.modifiers[0].price) * parseInt(num.amount)) + memo
                                            }, 0) < props.restarauntsInfo[key].minOrderPrice && (
                                                <RestLimit>{`Минимальная сумма заказа: ${props.restarauntsInfo[key].minOrderPrice} р`}</RestLimit>
                                            )}
                                        </Fragment>
                                        <WrapperTransitionGroup key={key}>
                                            {_.map(_.filter(props.orders, (filterItem2) => {
                                                return filterItem2.restId === key
                                            }), (order, key) => {
                                                let countRemainProduct = 0;
                                                let countDeltaProduct = order.amountForOrder - order.amountOrdered;
                                                let price = _.size(order.modifiers) === 0
                                                    ? parseInt(order.price)
                                                    : parseInt(order.modifiers[0].price) + parseInt(order.price);

                                                _.map(props.orders, (item_id) => {
                                                    if (order.id === item_id.id) {
                                                        countRemainProduct += item_id.amount;
                                                    }
                                                });

                                                let heightBlock = document.getElementById(key + 1 + "_order_id") ? document.getElementById(key + 1 + "_order_id").getBoundingClientRect().height : 'false';
                                                return (
                                                    <CSSTransition classNames="option2" timeout={500}
                                                                   key={_.size(order.modifiers) === 0 ? order.id : parseInt(order.modifiers[0].id) + parseInt(order.id) + key}
                                                                   unmountOnExit>
                                                        {() => <Order id={key + 1 + "_order_id"} height={heightBlock}>
                                                            <OrderName>
                                                                <Name>
                                                                    <span>{order.name}</span>
                                                                    {_.size(order.modifiers) !== 0 && (
                                                                        <span> + {order.modifiers[0].name.replace(/[&\/\#,+()$~%.'":*?<>{}]/g, '')}</span>
                                                                    )}
                                                                </Name>
                                                                <PanelControlOrder>
                                                                    <CountOrder>
                                                                        <ItemListBtn onClick={() => {
                                                                            props.removeFromCart(order, order.amount === 1)
                                                                        }}>-</ItemListBtn>
                                                                        <Count>{_.size(order.modifiers) === 0 ? order.amount : order.amount}</Count>
                                                                        {parseInt(countDeltaProduct - countRemainProduct) === 0 ? (
                                                                            <ItemListBtn count={true}>+</ItemListBtn>
                                                                        ) : (
                                                                            <ItemListBtn onClick={() => {
                                                                                props.addToCart(order, false)
                                                                            }}>+</ItemListBtn>
                                                                        )}
                                                                    </CountOrder>
                                                                </PanelControlOrder>
                                                                {parseInt(countDeltaProduct - countRemainProduct) === 0 && (
                                                                    <MessageNullCount>Закончилось</MessageNullCount>
                                                                )}
                                                            </OrderName>
                                                            <OrderPrice>{price} р.</OrderPrice>
                                                            <OrderDelete onClick={() => {
                                                                props.deleteToCart(order)
                                                            }}>
                                                                <CloseFontAwesome
                                                                    className="fas fa-times"></CloseFontAwesome>
                                                            </OrderDelete>
                                                        </Order>}
                                                    </CSSTransition>
                                                )
                                            })}
                                        </WrapperTransitionGroup>
                                    </Fragment>
                                ))}
                            </TransitionGroup>
                        </Fragment>
                    </BasketBody>
                    <BasketFooter openBasket={props.openBasket}>
                        <Result>
                            <ResultName>
                                Доставка:
                            </ResultName>
                            <ResultPrice>
                                {Math.round(props.companyOrder.deliveryPrice || 0)} р.
                            </ResultPrice>
                        </Result>
                        <ResultAll>
                            <ResultNameAll>
                                Сумма заказа:
                            </ResultNameAll>
                            <ResultPriceAll>{props.sum} р.</ResultPriceAll>
                        </ResultAll>
                    </BasketFooter>
                </EditScroll>
            </BasketPanelUser>
            <BasketPanelManagement>
                {!editData ? (
                    <ModalUniversal
                        message="Вы уверены, что хотите отменить изменение заказа?"
                        messageButton="Да"
                        messageCancel="Отмена"
                        action={onCancelOrder}
                        buttonDate={<ButtonAction><LeftI className="fas fa-arrow-left"></LeftI></ButtonAction>}
                    />
                ) : (
                    <ButtonAction onClick={()=>{onCancelOrder()}}><LeftI className="fas fa-arrow-left"></LeftI></ButtonAction>
                )}
                {_.every(checkLimitRestaraunts, bool => {
                    return bool
                }) ? (
                    <ModalUniversal
                        message="Сохранить изменения заказа?"
                        messageButton="Да"
                        messageCancel="Нет"
                        action={onSaveOrder}
                        buttonDate={<ButtonAction>Сохранить</ButtonAction>}
                    />
                ) : (
                    <ModalUniversal
                        message="Сохранить изменения заказа?"
                        messageButton="Да"
                        messageCancel="Нет"
                        action={() => {dispatch(deliveryCycleInfoMessage("error_limit_save_order", null, 5000))}}
                        buttonDate={<ButtonAction>Сохранить</ButtonAction>}
                    />
                )}

            </BasketPanelManagement>
        </EditWrapper>
    )
};

export default Edit;

const SvgImage = styled.svg`
    transition: .3s linear;
    transform: ${({openBasket}) => openBasket ? 'rotateX(180deg)' : 'rotateX(0deg)'};
`;

const BasketPanelUser = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0 13px;
`;
const Name = styled.p`
    margin-bottom: 0.5rem;
    color: #fff;
    font-size: 13px;
`;
const PanelControlOrder = styled.div``;
const CountOrder = styled.div`
    text-align: center;
    margin: 5px 0;
    font-size: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
`;
const Count = styled.div`
    display: flex;
    margin: 0 7px;
    font-weight: normal;
    font-size: 13px;
    color: #fff;
`;
const ItemListBtn = styled.span`
	height: 24px;
	width: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	font-size: 25px;
	transition: all 0.15s ease;
	user-select: none;
    border: ${({count}) => count !== 0 ? '1px solid rgba(224, 213, 210, 0.55)' : '1px solid #958A87'};
    color: ${({count}) => count !== 0 ? '#37474F' : '#958A87'};
    background-color: ${({count}) => count !== 0 ? '#fff' : 'transparent'};

	&:hover {
        border: ${({count}) => count !== 0 ? '1px solid #fff' : '1px solid #958A87'};
        color: ${({count}) => count !== 0 ? '#fff' : '#958A87'};
        background-color: ${({count}) => count !== 0 ? '#37474F' : 'transparent'};
	}
`;
const EditWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: #37474F;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transform: ${({widget}) => widget ? 'rotateY(0deg)' : 'rotateY(180deg)'};
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s linear;
    z-index: ${({widget}) => widget ? '2' : '1'};
    flex-direction: column;
    opacity: ${({widget}) => widget ? '1' : '0'};
    
    @media(max-width: 992px){
        border-radius: ${({open}) => open ? "0" : "6px 6px 0 0"};
    }
    
    &:before{
        width: 104px;
        height: 104px;
        background: linear-gradient(to bottom,rgba(55,71,79,1) 30%,rgba(224,213,210,0) 20%,rgba(237,237,237,0) 100%);
        border-radius: 50%;
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: -20px;
        z-index: 0;
    }
`;
const BasketHeader = styled.div`
    min-height: 58px;
    display: flex;
    justify-content: space-between;
    transform: rotateY(0deg);
    z-index: 2;
`;
const EditScroll = styled.div`
  position: relative;
  overflow-y: auto;
  height: calc(100% - 162px);
  border: 2px dotted rgba(255,255,255,0.3);
  border-right: none;
  border-left: none;
  padding-right: 5px;
  
  &::-webkit-scrollbar { 
    width: 4px; 
  }

  &::-webkit-scrollbar-track {
    background: #f7efef;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a5a2a2;
    border-radius: 5px;
  }
`;
const BasketBody = styled(TransitionGroup)`
  flex-grow: 1;
  padding: 13px 0;
`;
const RestInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 0 0;
`;
const RestLimit = styled.div`
  background: #FF1744;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-family: "SF Display";
  font-weight: 400;
  border-radius: 66px;
  margin: 7px 0;
`;
const RestImage = styled.img`
  width: 32px;
`;
const RestName = styled.span`
  color: #fff;
  font-size: 13px;
  font-family: "SF Display";
  font-weight: bold;
  margin-left: 15px;
`;
const BasketFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #37474F;
  color: #fff;
  padding: 0 13px;
  transition: ${({openBasket}) => !openBasket ? 'opacity .1s cubic-bezier(0.56, 0.01, 0.26, 0.26)' : 'opacity .7s cubic-bezier(0.56, 0.01, 0.26, 0.26)'};
  z-index: ${({openBasket}) => openBasket ? '1' : '-1'};
  opacity: ${({openBasket}) => openBasket ? '1' : '0'};
  border-radius: 0 0 6px 6px;
  
  @media(max-width:992px){
    border-radius: 0;
  }
`;
const HeadCenter = styled.div`
    padding-top: 5px;
    z-index: 1;
    width: 20%;
    display: flex;
    justify-content: center;
    transition: .2s ease-out;
    cursor: pointer;
    
    &:hover {
        stroke: #fff;
        fill: #fff;
    }
`;
const BottomIcon = styled.div`
    padding-left: 10px;
    color: #fff;
    stroke: #fff;
    fill: #fff;
`;
const HeadLeft = styled.div`
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    width: 100%;
    z-index: 1;
    transition: .2s ease-out;
    cursor: pointer;

    &:hover ${BottomIcon} {
        color: #fff;
        stroke: #fff;
        fill: #fff;
    }
`;
const ActionIcons = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    
    @media(min-width:992px){
        display: none;
    }
`;
const SaveEdit = styled(FontAwesomeIcon)`
    color: ${MAIN_COLOR};
    font-size: 22px;
    margin-left: 27px;
`;
const CancelEdit = styled(SaveEdit)``;
const NameBasket = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
`;
const ResultName = styled.div`
  width: 50%;
`;
const ResultNameAll = styled(ResultName)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;
const OrderName = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;
const OrderPrice = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
  color: #fff;
`;
const ResultPrice = styled(OrderPrice)`
  width: 50%;
`;
const ResultPriceAll = styled(ResultPrice)`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`;
const OrderDelete = styled.div`
  position: absolute;
  top: 0px;
  right: -5px;
  color: rgba(51,51,51,0.71);
  opacity: 0;
  transition: all .3s linear;
  cursor: pointer;
`;
const Order = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  position:relative;
  
  &.option2-enter {
    opacity: 0;
    transform: scale(0.9) translate(0, 50px);
  }

  &.option2-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.option2-exit {
    opacity: 1;
  }

  &.option2-exit-active {
    opacity: 0;
    transform: scale(0.9) translate(0, -100%);
    margin-top: ${({height}) => height ? "-" + height + "px" : "0"};
    transition: all 300ms linear;
  }
  
  &:hover ${OrderDelete}{
     opacity: 1;
  }
`;
const Result = styled(Order)`
  margin: 5px 0;
  padding: 10px 0;
  border-bottom: 2px dotted rgba(255,255,255,0.3);
`;
const ResultAll = styled(Result)`
  border-bottom: none;
`;
const CloseFontAwesome = styled.i`
  color: #fff;
`;
const BasketPanelManagement = styled.div`
  width: 100%;
  background: transparent;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 30px;
  
  @media(max-width:992px){
    //margin-top: 0;
    //padding-top: 16px;
    //padding-bottom: 16px;
    //background: #37474F;
    
    display: none;
  }
`;
const ButtonAction = styled.div`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #37474F;
  border-radius: 66px;
  color: #fff;
  padding: 8px 20px;
  transition: .2s ease-out;
  border: 1px solid #37474F;
  margin: 0 20px;
  
  &:hover{
    background: #fff;
    color: #37474F;
  }
  
  @media(max-width:992px){
    border: 1px solid #fff;
    background: #37474F;
    color: #fff;
    
    &:hover{
      background: #fff;
      color: #37474F;
    }
  }
`;
const LeftI = styled.i`
  padding: 3px 24px;
`;
const MessageNullCount = styled.div`
    color: red;
    font-size: 10px;
    opacity: 1;
    position: absolute;
    left: 0;
    bottom: 4px;
`;
const WrapperTransitionGroup = styled(TransitionGroup)`
    width: 100%;
`;