import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {cancelOrder, toggleCancelModal} from "../../../actions/MyOrdersActions";
import {AnimatePresence, motion} from "framer-motion";

const CancelModal = ({ setSelectedOrder, deleteOrderFromOrderList }) => {

    const showCancelModal = useSelector(store => store.myOrders.cancelOrderModalIsOpen)
    const cancelOrderData = useSelector(store => store.myOrders.cancelOrder)
    const startCanceling = useSelector(store => store.myOrders.startCanceling)
    const orderDeleteSuccess = useSelector(store => store.myOrders.orderDeleteSuccess)

    const dispatch = useDispatch()

    useEffect(() => {
        if(orderDeleteSuccess){
            setTimeout(() => {
                deleteOrderFromOrderList(cancelOrderData.id)
            }, 2000)
        }
    }, [orderDeleteSuccess])

    return (
        <>
            <AnimatePresence>
            {
                showCancelModal && (
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                        >
                            <CancelOrderModal>
                                <Message onClick={e => e.stopPropagation()}>
                                    <h3>Отмена заказа</h3>
                                    {
                                        !orderDeleteSuccess ? (
                                            <p>Вы уверены что хотите отменить заказ #{cancelOrderData.companyOrderId}?</p>
                                        ) : (
                                            <p>Заказ отменен.</p>
                                        )
                                    }
                                    {
                                        !orderDeleteSuccess && (
                                            <Controls>
                                                {
                                                    startCanceling ? (
                                                        <>
                                                            <ApplyBtn onClick={() => dispatch(cancelOrder(cancelOrderData))} disabled>
                                                                Да, уверен
                                                            </ApplyBtn>
                                                            <CancelBtn onClick={() => dispatch(toggleCancelModal())} disabled>
                                                                Нет
                                                            </CancelBtn>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ApplyBtn onClick={() => dispatch(cancelOrder(cancelOrderData))}>
                                                                Да, уверен
                                                            </ApplyBtn>
                                                            <CancelBtn onClick={() => dispatch(toggleCancelModal())}>
                                                                Нет
                                                            </CancelBtn>
                                                        </>
                                                    )
                                                }
                                            </Controls>
                                        )
                                    }
                                </Message>
                            </CancelOrderModal>
                        </motion.div>
                )
            }
            </AnimatePresence>
        </>
    )
}

export default CancelModal;

const CancelOrderModal = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 9999;
 
`

const Message = styled.div`
  width: 400px;
  background: white;
  border-radius: 6px;
  padding: 10px;
  @media(max-width: 768px) {
    width: 90%;
  } 
`

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`

const Btn = styled.button`
  cursor: pointer;
  padding: 7px 5px;
  border-radius: 6px;
  border: none;
  transition: all .3s ease;
  @media(max-width: 768px) {
    padding: 12px 12px;
  } 
`
const ApplyBtn = styled(Btn)`
  background-color: white;
  
  :hover {
    ${({disabled}) => disabled ? null : {backgroundColor: 'rgba(0,105,255,0.32)'}};
  }
`
const CancelBtn = styled(Btn)`
  background-color: rgba(255,144,139,0.21);
  
  :hover {
    ${({disabled}) => disabled ? null : {backgroundColor: 'rgba(255,144,139,0.42)'}};
  }
`