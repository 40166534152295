import {_} from "underscore";
import React, {Fragment, useEffect, useState} from "react";
import styled from "styled-components";
import pagination from "./../../../images/drawer-navigation-confidence.svg";
import Modal from "react-modal";
import Fade from 'react-reveal/Fade';
import AuthModal from "../../Modal/AuthModal";
import ModifiedProduct from "../../Modal/ModifierProduct";
import {CSSTransition} from "react-transition-group";
import Tooltip from '@material-ui/core/Tooltip';
import {BOLD_FONT, MAIN_COLOR} from "../../../utils/constants";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {DetailViewProduct} from "../../Modal/DetailViewProduct";
import AddModalCompany from "../../Modal/AddModalCompany";


Modal.setAppElement("#root");

export const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                padding: '0'
            },
            popper: {
                zIndex: '9001'
            }
        }
    }
});

export const Catalog = (props) => {
    const {
        handleReduxFormAuth,
        image,
        profile,
        order,
        rest,
        modified,
        removeFromCart,
        addToCart,
        item_order,
        activeDay,
        isAuth,
        codeSent,
        phoneError,
        sendCodeToPhone,
        createTokenBySession,
        errorMessage,
        valueAuthEmail,
        time,
        regOrders,
        editWidget,
        isToken,
        createTokenByPassword,
        sendActivationMail,
        companies,
        saveCompany,
        handleReduxFormEditCompany,
        valueEditCompany,
        cities,
        isPeriodOver,
        isMobile,
    } = props;

    const [isShowDetail, setShowDetail] = useState(false);
    const [accountsOnOrders, setAccountsOnOrders] = useState([]);

    order['activeDay'] = activeDay;

    useEffect(() => {
        setAccounts()
    }, [])

    useEffect(() => {
        setAccounts()
    }, [regOrders])

    const setAccounts = () => {
        let arr = []
        regOrders.map(order => {
            if (!arr.includes(order.account)) {
                arr.push(order.account)
            }
        })
        setAccountsOnOrders(arr)
    }

    var objectProduct = _.find(item_order, (obj_item)=>{return _.size(obj_item.modifiers) === 0 && parseInt(obj_item.id) === parseInt(order.id)});
    var arrayProduct = _.filter(item_order, (obj_item)=>{return _.size(obj_item.modifiers) !== 0 && parseInt(obj_item.id) === parseInt(order.id)});
    var countRemainProduct = 0;

    _.map(item_order, (item_id)=>{
        if(order.id === item_id.id){
            countRemainProduct += item_id.amount;
        }
    });

    var remainCountProduct = parseInt(order.amountPerOrder) - parseInt(countRemainProduct || 0);

    const countFromCart = () => {
        var initialValue = 0;
        let sum = arrayProduct.reduce((prev, cur) => {return prev + cur.amount}, initialValue);
        return sum
    }

    const showDetail = () => {
        if (isMobile) {
            setShowDetail(true)
        }
    }

    const toHideDetail = () => {
        if (isMobile) {
            setShowDetail(false)
        }
    }

    return (
        <Fragment>
            {/*<Container id={order.id} style={ time === -1 || isPeriodOver ? {filter: 'grayscale(1)'} : {} }>*/}
            <Container id={order.id}>
                <Fade>
                    <Wrapper>
                        <DetailViewProduct
                            image={image}
                            rest={rest}
                            order={order}
                            remainCountProduct={remainCountProduct}
                            time={time}
                            isPeriodOver={isPeriodOver}
                            regOrders={regOrders}
                            modified={modified}
                            objectProduct={objectProduct}
                            removeFromCart={removeFromCart}
                            addToCart={addToCart}
                            isMobile={isMobile}
                            isAuth={isAuth}
                            companies={companies}
                            saveCompany={saveCompany}
                            handleReduxFormEditCompany={handleReduxFormEditCompany}
                            valueEditCompany={valueEditCompany}
                            cities={cities}
                            handleReduxFormAuth={handleReduxFormAuth}
                            profile={profile}
                            codeSent={codeSent}
                            phoneError={phoneError}
                            sendCodeToPhone={sendCodeToPhone}
                            createTokenBySession={createTokenBySession}
                            errorMessage={errorMessage}
                            valueAuthEmail={valueAuthEmail}
                            isToken={isToken}
                            createTokenByPassword={createTokenByPassword}
                            sendActivationMail={sendActivationMail}
                            products={regOrders.products}
                            arrayProduct={arrayProduct}
                            item_order={item_order}
                            isShowDetail={isShowDetail}
                            toHideDetail={() => toHideDetail()}
                        />
                        <WrapperInfo onClick={() => showDetail()}>
                            <NameProd>
                                <Name>{order.name}</Name>
                                {!isMobile && (
                                    <Tooltip
                                        title={
                                            <InfoDetailedProduct>
                                                {order.weight && (<span>{`Вес: ${Math.round(order.weight)}`} г.</span>)}
                                                {order.energy && (<span>{`Энергия: ${Math.round(order.energy)}`} г.</span>)}
                                                {order.fibers && (<span>{`Белки: ${Math.round(order.fibers)}`} г.</span>)}
                                                {order.fats && (<span>{`Жиры: ${Math.round(order.fats)}`} г.</span>)}
                                                {order.carbs && (<span>{`Углеводы: ${Math.round(order.carbs)}`} г.</span>)}
                                            </InfoDetailedProduct>
                                        }
                                    >
                                        <Pagination src={pagination}/>
                                    </Tooltip>
                                )}
                            </NameProd>
                            {isMobile && (
                                <WeightAndPrice>
                                    {
                                        objectProduct ? (
                                                <div style={{
                                                    width: 24,
                                                    height: 24,
                                                    backgroundColor: '#CDDC39',
                                                    borderRadius: 66,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontFamily: 'Roboto',
                                                    fontSize: 13,
                                                    color: '#222',
                                                }}>
                                                    {objectProduct.amount}
                                                </div>
                                        ) :
                                            arrayProduct.length > 0 ? (
                                                    <div style={{
                                                        width: 24,
                                                        height: 24,
                                                        backgroundColor: '#CDDC39',
                                                        borderRadius: 66,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontFamily: 'Roboto',
                                                        fontSize: 13,
                                                        color: '#222',
                                                    }}>
                                                        {countFromCart()}
                                                    </div>
                                                ) :
                                        order.weight && (<WeightText>{Math.round(order.weight)} г</WeightText>)
                                    }
                                    <PriceText>{parseFloat(order.price)} &#8381;</PriceText>
                                </WeightAndPrice>
                            )}
                        </WrapperInfo>
                        {!isMobile && (
                            <WrapperModified>
                                {((time !== -1 && !isPeriodOver && !accountsOnOrders.includes(rest.id)) || editWidget) && (
                                // {((time !== -1 && !isPeriodOver && _.size(regOrders) === 0) || editWidget) && (
                                    _.size(modified) === 0 ? (
                                        <WrapperControl>
                                            <Modified>
                                                <PriceModified style={objectProduct ? {color: MAIN_COLOR} : {color: ''}}>{parseFloat(order.price)} &#8381;</PriceModified>
                                            </Modified>
                                            <ModifiedTransition>
                                                <CSSTransition timeout={500} classNames='addMod' key={order.id + 1} in={objectProduct ? true : false} unmountOnExit>
                                                    {() => <PanelRemoveCountProduct>
                                                        <ItemListCount>
                                                            <Fragment>
                                                                <ItemListBtn onClick={()=>{removeFromCart(order, objectProduct.amount === 1)}}>
                                                                    -
                                                                </ItemListBtn>
                                                                <ItemListCounter>{objectProduct ? objectProduct.amount : 1}</ItemListCounter>
                                                            </Fragment>

                                                            {order.amountForOrder !== null && remainCountProduct <= 5
                                                                ? remainCountProduct !== 0
                                                                    ? <ItemListBtn onClick={()=>{addToCart(order, false)}}>+</ItemListBtn>
                                                                    : <ItemListBtnDisabled>+</ItemListBtnDisabled>
                                                                : <ItemListBtn onClick={()=>{addToCart(order, false)}}>+</ItemListBtn>
                                                            }
                                                        </ItemListCount>
                                                    </PanelRemoveCountProduct>}
                                                </CSSTransition>
                                                <CSSTransition timeout={500} classNames='addMod2' key={order.id + 2} in={!objectProduct ? true : false} unmountOnExit>
                                                    {() => <PanelRemoveCountProduct>
                                                        {
                                                            isAuth && _.size(companies) === 0
                                                                ? <AddModalCompany
                                                                    companyData={companies}
                                                                    saveCompany={saveCompany}
                                                                    onChange={handleReduxFormEditCompany}
                                                                    valueEditCompany={valueEditCompany}
                                                                    cities={cities}
                                                                    button={<ButtonAdd>В корзину</ButtonAdd>}
                                                                />
                                                                : isAuth && _.size(companies) !== 0
                                                                ? order.amountForOrder !== null && remainCountProduct <= 5
                                                                    ? remainCountProduct !== 0
                                                                        ? <ButtonAdd onClick={()=>{addToCart(order)}}>В корзину</ButtonAdd>
                                                                        : <ButtonAddDisabled>В корзину</ButtonAddDisabled>
                                                                    : <ButtonAdd onClick={()=>{addToCart(order)}}>В корзину</ButtonAdd>
                                                                : <AuthModal
                                                                    onChange={handleReduxFormAuth}
                                                                    profile={profile}
                                                                    codeSent={codeSent}
                                                                    phoneError={phoneError}
                                                                    sendCodeToPhone={sendCodeToPhone}
                                                                    createTokenBySession={createTokenBySession}
                                                                    errorMessage={errorMessage}
                                                                    valueAuthEmail={valueAuthEmail}
                                                                    buttonFront={<ButtonAdd>В корзину</ButtonAdd>}
                                                                    isToken={isToken}
                                                                    createTokenByPassword={createTokenByPassword}
                                                                    sendActivationMail={sendActivationMail}
                                                                />
                                                        }
                                                    </PanelRemoveCountProduct>}
                                                </CSSTransition>
                                            </ModifiedTransition>
                                        </WrapperControl>
                                    ) : (
                                        <ModifiedProduct
                                            order={order}
                                            removeFromCart={removeFromCart}
                                            isAuth={isAuth}
                                            remainCountProduct={remainCountProduct}
                                            addToCart={addToCart}
                                            onChange={handleReduxFormAuth}
                                            profile={profile}
                                            codeSent={codeSent}
                                            phoneError={phoneError}
                                            sendCodeToPhone={sendCodeToPhone}
                                            createTokenBySession={createTokenBySession}
                                            errorMessage={errorMessage}
                                            valueAuthEmail={valueAuthEmail}
                                            buttonFront={<ItemListBtn>+</ItemListBtn>}
                                            image={image}
                                            rest={rest}
                                            modified={modified}
                                            arrayProduct={arrayProduct}
                                            item_order={item_order}
                                            time={time}
                                            isPeriodOver={isPeriodOver}
                                            products={regOrders.products}
                                            editWidget={editWidget}
                                            basketPosition={_.size(arrayProduct) !== 0}
                                            isToken={isToken}
                                            createTokenByPassword={createTokenByPassword}
                                            sendActivationMail={sendActivationMail}
                                            companies={companies}
                                            saveCompany={saveCompany}
                                            valueEditCompany={valueEditCompany}
                                            cities={cities}
                                        />
                                    )
                                )}
                            </WrapperModified>
                        )}
                    </Wrapper>
                </Fade>
            </Container>
        </Fragment>
    );
};



const ItemListCount = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	right: 0;
`;

const ItemListCounter = styled.span`
	margin: 0 12px;
	font-size: 18px;
	line-height: 1.2;
	color: ${MAIN_COLOR};
`;

const ItemListBtn = styled.span`
	height: 35px;
	width: 35px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	font-size: 25px;
    border: 1px solid ${MAIN_COLOR};
	color: ${MAIN_COLOR};
	transition: all 0.15s ease;
	user-select: none;

	&:hover {
        color: #fff;
        background: ${MAIN_COLOR};
	}
`;

const ItemListBtnDisabled = styled(ItemListBtn)`
    color: #bebebe;
    border: 1px solid #bebebe;
    opacity: 0.5;
    
    &:hover {
		background-color: #bebebe;
		color: #fff;
		border-color: #bebebe;
	}
`;

const WrapperControl = styled.div`
	display: flex;
	justify-content: space-between;
	height: 35px;
`;

const Modified = styled.div`
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	justify-content: center;
`;

const WrapperInfo = styled.div`
	width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 25px;
    
    @media (max-width: 992px) {
        display: block;
        margin: 0px;
        padding: 0 9px 0 12px;
        max-height: 78px;
	}
`;

const NameProd = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	margin-top: 15px;
	
	@media (max-width: 992px) {
        margin-top: 7px;
	}
`;

const Name = styled.p`
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-align: left;
	margin-right: 10px;
	margin-bottom: 0;
	
	@media (max-width: 992px) {
	    margin-right: 0;
	    font-family: "Roboto", sans-serif;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        color: #222222;
	    
	    display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 30px;
	}
`;

const InfoDetailedProduct = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Pagination = styled.img`
	cursor: pointer;
	margin-top: -4px;
`;

const Container = styled.div`
	bottom: 0;
	left: 0;
	padding: 0 0 12px;
	background-color: transparent;
	width: calc(100% / 3 - 2%);
	min-height: 350px;
	margin: 10px 0;
	border-radius: 3px;
	transition: .3s linear;
	display: flex;
	
	@media (min-width: 992.01px) {
	    & > div:first-child {
            width: 100%;
        }
	
	    &:nth-child(3n+2){
            margin: 10px 2.5%;
        }
	}
	
	@media (max-width: 992px) {
		width: calc((100% - 12px) / 2);
		min-height: auto; 
		height: calc((100vw - 36px) / 2 + 78px);
        margin: 20px 0 0;
        
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
	}
`;

const Wrapper = styled.div`
    height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	
	@media (max-width: 992px) {
	    width: 100%;
	}
`;

const WrapperModified = styled.div`
	width: 100%;
    margin-top: auto;
`;

const ButtonAdd = styled.div`
    padding: 7px 27px;
    border: ${({disable}) => disable ? "1px solid rgb(183,183,183)" : `1px solid ${MAIN_COLOR}`};
    background: ${({disable}) => disable ? "rgb(183,183,183)" : MAIN_COLOR};
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    overflow: hidden;
    cursor: ${({disable}) => disable ? "not-allowed" : "pointer"};
    opacity: ${({disable}) => disable ? ".7" : "1"};
    right: 0;
    white-space: nowrap;
    ${BOLD_FONT};
    
    &:hover{
        background: ${({disable}) => disable ? "rgb(183,183,183)" : "#fff"};
        color: ${({disable}) => disable ? "#fff" : MAIN_COLOR}
    }
    
    @media (max-width: 992px) {
        font-size: 12px;
        padding: 7px 12px;
    }
`;

const ButtonAddDisabled = styled.div`
    padding: 7px 27px;
    border: 1px solid #E0D5D2;
    background: #E0D5D2;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    color: #FFFFFF;
    position: relative;
    overflow: hidden;
    cursor: not-allowed;
    opacity: 0.7;
`;

const PriceModified = styled.div`
    font-family: "SF Display";
	font-weight: 600;
	font-size: 20px;
	line-height: 19px;
	color: #263238;
	white-space: nowrap;
`;

const ModifiedTransition = styled.div`
	margin-left: auto;
	display: flex;
	flex-direction: column;
	transition: .3s ease-in-out;
	position: relative;
`;

const PanelRemoveCountProduct = styled.div`
    position: relative;

    &.addMod-enter {
        opacity: 0;
        transform: translateY(70px);
        transition: all 200ms ease-in-out;
    }
    
    &.addMod-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 200ms, transform 300ms;
    }
    
    &.addMod-exit {
        opacity: 1;
    }
    
    &.addMod-exit-active {
        opacity: 0;
        transform: translateY(-70px);
        transition: all 200ms ease-in-out;
    }
    
    &.addMod2-enter {
        opacity: 0;
        transform: translateY(70px);
        transition: all 200ms ease-in-out;
    }
    
    &.addMod2-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 200ms, transform 300ms;
    }
    
    &.addMod2-exit {
        opacity: 1;
    }
    
    &.addMod2-exit-active {
        opacity: 0;
        transform: translateY(-70px);
        transition: all 200ms ease-in-out;
    }
`;

const DisableButtonAddItem = styled.div`
    background: ${MAIN_COLOR};
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 5px 8px;
`;

const WeightAndPrice = styled.div`
    position: relative
    margin-top: 10px;
    height: 24px;
`

const WeightText = styled.p`
    font-family: "SF Display", sans-serif;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #999999;
    
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
`

const PriceText = styled.p`
    font-family: "SF Display", sans-serif;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #222222;
    
    margin: 0;
    position: absolute;
    right: 0;
    bottom: 0;
`
