import React, {useEffect, useState} from "react";
import styled from "styled-components";
import OrderHeader from "./OrderHeader";
import OrderContent from "./OrderContent";
import {AnimatePresence, motion} from "framer-motion";

const Order = ({
                   id,
                   orderId,
                   owner,
                   ownerId,
                   showMore,
                   products,
                   orderIsSelect,
                   selectOrder,
                   company,
                   companyOrderId,
                   period,
                   prices,
                   priceResult,
                   status,
                   orderExpDateValue,
                   date,
                   paysystem,
}) => {

    const selectOrderHandler = () => {
        if ( !orderIsSelect ) {
            selectOrder(id)
        }
    }

    const shortInfo = {
        address: `${company.address.city.name}, ${company.address.street}, ${company.address.house}, эт. ${company.address.floor}, кв. ${company.address.flat}`,
        companyName: company.name,
        date: date.dateString,
        period: `${period.from}-${period.to}`,
        deliveryPrice: prices.delivery,
        serviceFeePrice: prices.serviceFee || 0,
        ownerName: owner.name,
        ownerLogo: owner.logo,
        paymentByCash: prices.pay,
        paymentByPoints: prices.wallet,
        paysystem: paysystem.id,
        products,
    }

    let moreInfo = {}

    if(showMore) {
        moreInfo = {
            ownerName: owner.name,
            ownerLogo: owner.logo,
            products,
            paymentByCash: prices.pay,
            paymentByPoints: prices.wallet,
            priceResult
        }
    }

    //animation
    const orderAnimation = {
        init: {
            opacity: 0,
            y: -20,
        },
        visible: id => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: id * 0.05,
            },
        }),
        hidden: { opacity: 0 },
    }

    return (
        <OrderWrapper >
            {
                showMore
                    ?
                    (
                        <AnimatePresence>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    scale: 1.5,
                                }}
                                animate={{
                                    opacity: 1,
                                    scale: 1,
                                }}
                            >
                                <OrderHeader {...{status, companyOrderId, priceResult, selectOrderHandler}}/>
                                <OrderContent {...{orderId, companyOrderId, ownerId, showMore, shortInfo, moreInfo, id, status, orderExpDateValue, priceResult}}/>
                            </motion.div>
                        </AnimatePresence>
                    )
                    :
                    (
                        <motion.div
                            custom={id}
                            variants={orderAnimation}
                            initial="init"
                            animate="visible"
                        >
                            <OrderHeader {...{companyOrderId, priceResult, selectOrderHandler, status}}/>
                            <OrderContent {...{orderId, companyOrderId, ownerId, showMore, shortInfo, moreInfo, id, status, orderExpDateValue, priceResult}}/>
                        </motion.div>
                    )
            }
        </OrderWrapper>
    )
}

export default Order;

const OrderWrapper = styled.div`
  font-family: Roboto, sans-serif;
  width: 100%;
`
